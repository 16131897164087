import { RefObject } from 'react';
import { CanvasDataTypes } from '../utils';
import { CanvasJson } from './canvas';
import { EditableArea } from './editable-area';
import { PhotoZone } from './photo-zone';
import { TextZone } from './text-zone';

export enum CardFaceTypes {
  FACEFRONT = 'front',
  FACEBACK = 'back',
  FACEINSIDE = 'inside',
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  AREAFRONT = 'outside-front',
  AREABACK = 'outside-back',
  AREAINSIDE = 'inside',
}

type CardFace = {
  FaceId: number;
  Type: string;
  BackgroundUrl: string;
  ReplaceBackgroundUrl: string;
  IsEditable: boolean;
  OverlayBackgroundUrl: string;
  FrameUrl: string;
  CanvasJson: CanvasJson | null;
  PhotoZones: PhotoZone[];
  UserTextZones: unknown[];
  Texts: TextZone[];
  Dimensions: {
    Width: number;
    Height: number;
  };
  DisplayDefaultTextArea: boolean;
  UserImages: string[] | null;
  EditableAreas: EditableArea[];
  CanAddPhoto: boolean;
  CanAddText: boolean;
  DisplayIndicator: boolean;
  PreviewDisplayIndicator: boolean;
  canvas?: RefObject<fabric.Canvas>;
};

export type CardDimensions = {
  width: number;
  height: number;
};

export type CardText = {
  CanDeleteTextArea?: boolean;
  CanEditFontColor?: boolean;
  CanEditFontSize?: boolean;
  CanResizeTextArea?: boolean;
  DisplayFontToolbox?: boolean;
  FontAutoSize?: boolean;
  MinimumFontSize?: number;
  MustEditTextArea?: boolean;
  FontFamily: string;
  FontId: number;
  FontSize: number;
  Height: number;
  ID?: string;
  IsFixed?: boolean;
  IsHybrid?: boolean;
  IsMultiline?: boolean;
  LeftPosition: number;
  Rotation: number;
  Text: string;
  TextAlign: string;
  TextColor: string;
  TopPosition: number;
  Width: number;
};

export type CardEditableArea = {
  Width: number;
  Height: number;
  LeftPosition: number;
  TopPosition: number;
  Rotation: number;
  name?: string;
};

export type CardFacePreviewImage = {
  photoZoneTemplate: string;
  type: string;
  dimensions: CardDimensions;
  faceNumber?: number;
  url?: string;
  dataURL?: string;
};

export type CardFaceData = {
  texts: CardText[];
  type: string;
  faceId: number;
  faceNumber: number;
  canvas: RefObject<fabric.Canvas>;
  photoZoneTemplate: string | null;
  zones: PhotoZone[];
  userZones: unknown[] | null;
  editableAreas: CardEditableArea[] | null;
  dimensions: CardDimensions;
  displayDefaultTextArea: boolean;
  backgroundImage: string;
  woodenBackground: string | null;
  chocolateBackground: string | null;
  postcardBackground: string | null;
  postcardOverlay: string | null;
  active: boolean;
  // Canvas Json to be passed to loadFromJson
  originalCanvasJson: CanvasJson | null;
  photosAddedOnFace: string[] | null;
  isEditable: boolean;
  // Indicates if a photo zone can be added to that panel
  canAddPhoto: boolean;
  // Indicates if a text zone can be added to that panel
  canAddText: boolean;
  // Indicates if this panel needs to be displayed in the editor view
  editorDisplayIndicator: boolean;
  // Indicates if this panel needs to be displayed in the preview view
  previewDisplayIndicator: boolean;
  // used for faces that don't get rendered on canvas (editorDisplayIndicator = true)
  printJson?: any;
  clipPaths?: fabric.Rect[] | null;
};

export type EditableTextSettings = {
  name: string;
  backgroundColor: string;
  editableText: boolean;
  isModified: boolean;
  lockMovementX: boolean;
  lockMovementY: boolean;
  hoverCursor: string;
  padding: number;
  selectable: boolean;
  selectionColor: string;
  hasRotatingPoint: boolean;
  lockRotatingPoint: boolean;
  width: number;
  height: number;
  left: number;
  originY: string;
  top: number;
  angle: number;
  fontFamily: string;
  fontSize: number;
  text: string;
  textAlign: string;
  fill: string;
  lockRotation: boolean;
  hasRotationControls: boolean;
  data: {
    type: CanvasDataTypes;
    hasContent: boolean;
  };
};

export type EditableZoneSettings = {
  LeftPosition: number;
  TopPosition: number;
  Width: number;
  Height: number;
  Rotation: number;
};

export type { CardFace };
