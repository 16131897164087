import { useCallback, useEffect, useMemo } from 'react';
import { useAddressSelectionContext } from '../context/address-selection-indicator/address-selection';
import {
  decreaseAddressSelectionCount,
  increaseAddressSelectionCount,
  resetAddressSelectionCount,
  setAddressSelectionIsMaxCountReached,
  setAddressSelectionMaxCount,
} from '../context/address-selection-indicator/address-selection-actions';

type HandleSelectionChange = () => void;
type HandleSetMaxCount = (maxCount: number) => void;

// placeholder function to handle a void action
const voidHandleSelectionChange: HandleSelectionChange = () => {
  return;
};

const useAddressSelection = () => {
  const { addressSelectionState, addressSelectionDispatch } = useAddressSelectionContext();
  const { count, maxCount, isMaxCountReached } = addressSelectionState;
  const selection = useMemo(() => (maxCount > 0 ? count + '/' + maxCount : ''), [count, maxCount]);

  // handleIncrease increases the conter by 1
  const handleIncrease = useCallback<HandleSelectionChange>(
    () =>
      maxCount > 0 && maxCount !== count
        ? increaseAddressSelectionCount(addressSelectionDispatch)
        : voidHandleSelectionChange,
    [maxCount, count],
  );

  // handleDecrease decreases the counter by 1
  const handleDecrease = useCallback<HandleSelectionChange>(
    () =>
      maxCount > 0 && count > 0 ? decreaseAddressSelectionCount(addressSelectionDispatch) : voidHandleSelectionChange,
    [maxCount, count],
  );

  // handleReset resets the count to 0 in cases where the user would not confirm the address selection
  // or any other scenario where the user would want to reset the count
  const handleReset = useCallback<HandleSelectionChange>(
    () => resetAddressSelectionCount(addressSelectionDispatch),
    [],
  );

  // handleSetMaxCount sets the max count/items to be selected
  const handleSetMaxCount = useCallback<HandleSetMaxCount>((maxCount) => {
    setAddressSelectionMaxCount(addressSelectionDispatch, maxCount);
  }, []);

  // handleSetMaxCountReached determines if the max count has been reached to disable the checkbox card
  const handleSetMaxCountReached = useCallback<HandleSelectionChange>(() => {
    if (maxCount === 0) return;
    if (count !== maxCount && !isMaxCountReached) return;
    setAddressSelectionIsMaxCountReached(addressSelectionDispatch, count === maxCount);
  }, [count, maxCount]);

  useEffect(() => {
    handleSetMaxCountReached();
  }, [handleSetMaxCountReached]);

  return {
    count,
    maxCount,
    isMaxCountReached,
    selection,
    handleIncrease,
    handleDecrease,
    handleReset,
    handleSetMaxCount,
    handleSetMaxCountReached,
  };
};

export default useAddressSelection;
