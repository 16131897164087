import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { useAddressSelectionContext } from '../../../context/address-selection-indicator/address-selection';
import { EnvelopeType } from '../../../global-types/envelope';
import { useFeatureFlags } from '../../../hooks';
import { useIsOneToMany } from '../../../hooks/use-is-one-to-many';
import { useEnvelopeSelection } from '../../../views/select-envelope/hooks/use-envelope-selection';
import { AddressFormActions } from '../address-form-types';
import styles from '../address-form.module.scss';

export const NavigationButtons = ({
  isValid,
  isTouched,
  isAddressLoading,
  submitLabel,
  goBack,
  goNext,
}: AddressFormActions) => {
  const { t } = useTranslation();
  const { IS_MULT_ADDRESS_ENABLED } = useFeatureFlags();

  const isOneToMany = useIsOneToMany();
  const {
    addressSelectionState: { isMaxCountReached },
  } = useAddressSelectionContext();

  const { getEnvelopeSelection } = useEnvelopeSelection();
  const isReturnRecipientEnvelope = getEnvelopeSelection() === EnvelopeType.ReturnRecipient;

  const isTouchedText = isTouched ? t('addressForm.nextButton.addAddress') : t('addressForm.nextButton.leaveBlanks');
  const nextButtonText = isMaxCountReached ? t('addressForm.nextButton.preview') : isTouchedText;

  return IS_MULT_ADDRESS_ENABLED ? (
    <div className={styles['address-form-navigation']}>
      {/* back button */}
      <Button
        startIcon={{ name: IconNames.ArrowsTailLeftBold }}
        mode={ButtonModes.TextLink}
        click={goBack}
        testId={'address-form-back-button'}
      >
        {t('addressForm.back')}
      </Button>

      {/* next button */}
      {isOneToMany && isReturnRecipientEnvelope ? (
        <Button
          click={goNext}
          type={ButtonTypes.Submit}
          mode={ButtonModes.Primary}
          ariaLabel={String(t('addressForm.nextButton'))}
          disabled={isTouched && !isValid}
          tabIndex={isValid ? 0 : -1}
          testId={'address-form-multi-step-submit'}
        >
          {nextButtonText}
        </Button>
      ) : (
        <Button
          click={goNext}
          type={ButtonTypes.Submit}
          mode={ButtonModes.Primary}
          disabled={!isValid || isAddressLoading}
          tabIndex={isValid ? 0 : -1}
          testId={'address-form-continue-submit'}
        >
          {submitLabel}
        </Button>
      )}
    </div>
  ) : (
    <></>
  );
};
