import { fabric } from 'fabric';

export const handleOrderChange = (order: number, canvas: fabric.Canvas, object?: fabric.Object) =>
  new Promise((resolve, reject) => {
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      return reject(new Error('No object found to move'));
    }
    // get the object to move if one is provided, else use the active object
    const objectToMove = object || activeObject;

    objectToMove.moveTo(order);

    // if there is an icon related to the object and it isn't above the object, move it to the next index
    const objects = canvas?.getObjects();
    const childIcon = objects.find((obj) => obj.data?.parentName === activeObject?.name);
    if (childIcon && objects.indexOf(childIcon) !== order + 1) {
      childIcon?.moveTo(order + 1);
    }

    resolve(void 0);
  });
