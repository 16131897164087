import { getCookie, getAccountIdFromCookie } from '../../../utils';
import { ModalEventType, ModalEventUserType, PODProductTag, UserProfile } from '../analytics-context-types';

enum productTypes {
  D = 'eCards',
  S = 'Finished Goods',
  P = 'Photo Card',
}

export const getPODProductTag = (
  productName: string,
  productID: string,
  productType: string,
  productQuantity: string,
) => {
  // assign the appropriate tag value for each card type, 'photo card' will be used as fallback
  const cardProductType = productTypes[`${productType}`] || productTypes.P;

  const tag: PODProductTag = {
    product: {
      productInfo: [
        {
          productInfoShown: {
            productID: productID,
            productName: productName,
            productType: cardProductType,
            productQuantity: productQuantity,
          },
        },
      ],
    },
  };

  if (productQuantity) {
    tag.product.productInfo[0].productInfoShown.productQuantity = productQuantity;
  }

  return tag;
};

export const getPODModalTag = (
  level2: string,
  modalEvent: ModalEventType[],
  projectID: string,
  profile?: ModalEventUserType,
) => {
  return {
    modal: {
      event: modalEvent,
      level1: 'Print on Demand',
      level2,
      pageType: 'Print on Demand',
      projectID,
    },
    user: {
      profile,
    },
  };
};

export const getDGModalTag = (
  level2: string,
  modalEvent: ModalEventType[],
  projectID: string,
  profile?: ModalEventUserType,
) => {
  return {
    modal: {
      event: modalEvent,
      level1: 'Digital Greetings',
      level2,
      pageType: 'Digital Greetings',
      projectID,
    },
    user: {
      profile,
    },
  };
};

export const getModalTag = (productType: string, level2: string, modalEvent: ModalEventType[], projectID: string) => {
  const { profile } = getUserTag();

  if (productTypes[`${productType}`] === productTypes['D']) {
    return getDGModalTag(level2, modalEvent, projectID, profile);
  } else {
    return getPODModalTag(level2, modalEvent, projectID, profile);
  }
};

/**
 * Get the level2 attribute based on current location. Useful for tracking events that may happen in more than one view/level
 * @returns level2 attribute to use for modal tag
 */
export const getModalLevel2 = (productType?: string, formTitle?: string): string => {
  const location = window.location;
  const queryParams = new URLSearchParams(location.href);
  const currentLevel2 = (window.dataLayer as Record<string, any>)?.modal?.level2;

  if (location.pathname.includes('/edit/')) {
    return currentLevel2 ?? 'Preview Mode';
  }
  if (location.pathname.includes('/address')) {
    if (productType && productTypes[`${productType}`] === productTypes['D']) {
      return 'Email Delivery - Recipient';
    }

    if (
      productType &&
      (productTypes[`${productType}`] === productTypes['P'] || productTypes[`${productType}`] === productTypes['S'])
    ) {
      return formTitle?.includes('Recipient') ? 'Address the Envelope - Recipient' : 'Address the Envelope - Sender';
    }

    const envelope = queryParams.get('envelope');
    return envelope === 'return' ? 'Address the Envelope - Sender' : 'Address the Envelope - Recipient';
  }
  if (location.pathname.includes('/envelope')) {
    return 'Address the Envelope - Sender';
  }
  if (location.pathname.includes('/preview')) {
    return 'Preview';
  }
  return '';
};

export const getUserTag = (): UserProfile => {
  const authStatus = getCookie('accountType') === 'R' ? 'credentials' : 'not logged in';
  const clientId = getCookie('sfcno');
  const guestProfileID = getAccountIdFromCookie();

  const userProfile = {
    profile: {
      CID: clientId || '',
      authStatus,
      guestProfileID: authStatus !== 'credentials' && guestProfileID ? guestProfileID : '',
    },
  };

  return userProfile;
};
