/**
 * Remove editable user zone buttons from user zone based on their names
 *
 * @param canvas - canvas from which to remove editable user zone buttons
 * @returns void
 */
export const removeEditableAreaButtons = (canvas: fabric.Canvas) => {
  const objects = canvas.getObjects();
  for (const object of objects) {
    if (object.name?.includes('user-zone-add')) {
      canvas.remove(object);
    }
  }
};
