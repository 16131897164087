import { getModalTag, getPODProductTag } from './utils';

export const changePreviewFaceTag = (options: any) => {
  const changePreviewFaceEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: `view-${options.elementPosition}`,
        eventAction: options.button.toLowerCase(),
      },
    },
  ];
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );
  const { modal, user } = getModalTag(options.productType, 'Edit Mode', changePreviewFaceEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
