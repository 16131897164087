import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { FooterContentProps } from '../scale-drawer-types';
import styles from '../scale-drawer.module.scss';

export const FooterContent = ({ onCancel, onApply }: FooterContentProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-wrapper']}>
      <Button addClass={classNames(styles['footer-buttons'])} click={onCancel} mode={ButtonModes.Secondary}>
        {t('footerContent.cancel')}
      </Button>
      <Button addClass={classNames(styles['footer-buttons'])} click={onApply}>
        {t('footerContent.apply')}
      </Button>
    </div>
  );
};
