import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { cleanAddresses, useInitializationDataContext } from '../../../context/data-context';
import { AddressForm as AddressFormData, FormValidation, AddressResponseData } from '../../../global-types';
import { deleteAddress } from '../../../services/address';
import { mapAddressesByType } from '../../../utils';
import { useAddressConfirmationDialog } from './useAddressConfirmationDialog';

export const useAddressSkipValidation = ({ submittedAddresses, setSubmittedAddresses, setShouldSave, envelope }) => {
  const { closeAddressConfirmationDialog } = useAddressConfirmationDialog();

  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();

  // Routing
  const navigate = useNavigate();
  const { search } = useLocation();

  // Function to be triggered when all the addresses where confirmed in address confirmation dialog
  const onSkipValidation = useCallback(
    (addressesToValidate: FormValidation[]) => {
      const skippedValidationAddresses = addressesToValidate
        .map((address) => {
          const { addressType } = address;
          const skippedAddress = submittedAddresses[`${addressType}`];
          return skippedAddress ? { ...skippedAddress, skip_usps_validation: true } : null;
        })
        .filter((address) => address !== null) as AddressFormData[];

      setSubmittedAddresses((addresses) => ({
        ...addresses,
        ...mapAddressesByType<AddressFormData>(skippedValidationAddresses),
      }));
      closeAddressConfirmationDialog();
      setShouldSave(true);
    },
    [closeAddressConfirmationDialog, submittedAddresses],
  );

  /**
   * Loops through saved addresses and called delete address endpoint for each address,  There's no
   * use case for this now, but will be if we decide to select a blank envelope on 1:1 cards
   */
  const removeSavedAddresses = (projectId: string, addresses: AddressResponseData[]) => {
    const addressIds = addresses.map((address) => address.address_id);
    for (let i = 0; i < addressIds.length; i++) {
      deleteAddress(projectId, addressIds[`${i}`]);
    }
  };
  const skipFormValidation = () => {
    const addresses = [submittedAddresses.recipient, submittedAddresses.sender].filter(
      (element) => element !== null,
    ) as unknown as AddressResponseData[];

    if (envelope === 'blank') {
      cleanAddresses(initializationDataDispatch);
    }

    if (addresses && addresses.length <= 0) {
      // Delete address from backend
      removeSavedAddresses(initializedData?.project_id as string, addresses);
      // Remove address from state
      cleanAddresses(initializationDataDispatch);
    }
    const searchParams = new URLSearchParams(search);
    if (envelope) {
      searchParams.set('envelope', envelope);
    }
    navigate({ pathname: '/card/customization/preview', search: searchParams.toString() });
    return;
  };

  return {
    onSkipValidation,
    skipFormValidation,
  };
};
