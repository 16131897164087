import { CardText, PhotoTextZoneSettings } from '../../../global-types';
import { CanvasDataTypes, deepCopy, helperSettingsConfig } from '../../../utils';
import { photoTextZoneConfig } from '../../../utils/configs/phototextzone-settings.configs';

const { scalingFactor } = helperSettingsConfig;
const { photoTextZoneDefaultSettings } = photoTextZoneConfig;

/**
 * Get default settings for photo-text zones
 * @param zone a phototextzone object from template
 * @param id the index from the template zones' array
 * @returns photoTextZoneSettings
 */
export const getPhotoTextZoneSettings = (zone: CardText, id: number): PhotoTextZoneSettings => {
  const scaledPosition = {
    left: zone.LeftPosition / scalingFactor,
    top: zone.TopPosition / scalingFactor,
  };

  const scaledTextDimensions = {
    width: zone.Width / (scalingFactor * 2),
    height: zone.Height / (scalingFactor * 2),
  };

  return Object.assign(deepCopy(photoTextZoneDefaultSettings), {
    name: `${CanvasDataTypes.PhotoTextZone}-${id}`,
    width: zone.Width / scalingFactor,
    height: zone.Height / scalingFactor,
    left: scaledPosition.left + scaledTextDimensions.width,
    originY: 'center',
    originX: 'center',
    top: scaledPosition.top + scaledTextDimensions.height,
    angle: zone.Rotation,
    hasRotatingPoint: false,
    lockRotatingPoint: true,
    objectCaching: false,
    data: {
      hasContent: false,
      ID: zone.ID,
      type: CanvasDataTypes.PhotoTextZone,
    },
    CanResizeTextArea: zone.CanResizeTextArea,
    CanEditFontSize: zone.CanEditFontSize,
    CanEditFontColor: zone.CanEditFontColor,
    FontAutoSize: zone.FontAutoSize,
  });
};
