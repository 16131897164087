/**
 * Check if all objects fit in the parent
 * @param parent - Parent object
 * @param objects - Array of objects to check if they fit in the parent
 * @returns object with horizontalFit and verticalFit properties {horizontalFit: boolean, verticalFit: boolean}
 */
export const objectsFitParent = (parent: fabric.Object, objects: fabric.Object[]) => {
  const parentWidth = parent.width as number;
  const parentHeight = parent.height as number;
  // Validate if all buttons fit in the zone both horizontally and vertically

  // Sum the width of all objects
  const objectsWidth = objects.reduce((acc, object) => {
    return acc + (object.width as number);
  }, 0);
  // Sum the height of all objects
  const objectsHeight = objects.reduce((acc, object) => {
    return acc + (object.height as number);
  }, 0);

  return { horizontalFit: parentWidth > objectsWidth, verticalFit: parentHeight > objectsHeight };
};

/**
 * Center an object in the parent object
 * @param object - Object to center
 * @param parent - Parent object
 */
export const centerObjectInParent = (object: fabric.Object, parent: fabric.Object) => {
  object.left = parent.getCenterPoint().x;
  object.top = parent.getCenterPoint().y;
};

/**
 * Fit an object to the parent object
 * @param object - Object to fit
 * @param parent - Parent object
 */
export const fitObjectToParent = (object: fabric.Object, parent: fabric.Object) => {
  // Check if the object fits in the parent, if it doesn't, try scaling to parent's width or height
  if ((object.width as number) > (parent.width as number)) {
    object.scaleToWidth((parent.width as number) * 0.85);
  }

  if ((object.height as number) > (parent.height as number)) {
    object.scaleToHeight((parent.height as number) * 0.85);
  }
};
