import { fabric } from 'fabric';
import { OpenTypeFont } from '../../global-types';

type InitializeCanvasExtensions = {
  fonts: OpenTypeFont[];
  /**
   * This function is called when the text is transformed to an svg path.
   * In order to see it on the canvas you need to add the path to the fabric instance.
   *
   * @example  onTextTransformedToPath(path) => canvas.current.add(path);
   */
  onTextTransformedToPath: (path: fabric.Path) => void;
};
export const initializeCanvasExtensions = ({ fonts, onTextTransformedToPath }: InitializeCanvasExtensions) => {
  const _fabric = fabric as any;
  // custom var we add to the fabric object object when we finish transforming the methods
  if (_fabric.hasLoadedOpenType) {
    return;
  }
  // This is necessary to avoid blurriness on the text
  _fabric.devicePixelRatio = 2;

  /**  Returns the current font family from the canvas font
   *
   * @param font this a string that looks like this '32px fontid-107'
   */
  const getFontFamily = (font: string) => {
    const fontFamily = font.match(/fontid-(\d*)/);
    return fontFamily ? fontFamily[0] : null;
  };

  const getFont = (fontFamily: string | null): opentype.Font | undefined => {
    if (fontFamily === null) {
      return;
    }
    const fontObject = fonts.find((font) => font[`${fontFamily}`]);
    if (!fontObject) {
      return;
    }
    return fontObject[`${fontFamily}`].font;
  };

  const createFontPath = (canvasObject) => {
    const t = canvasObject.transform;
    const fPath = new fabric.Path(canvasObject.path);
    if (!fPath.path) {
      return;
    }
    for (let p = 0; p < fPath.path.length; p += 1) {
      const step = fPath.path[`${p}`] as any;
      for (let i = 1; i < step.length; i += 2) {
        const x = t[0] * step[`${i}`] + t[2] * step[i + 1] + t[4];
        const y = t[1] * step[`${i}`] + t[3] * step[i + 1] + t[5];
        step[`${i}`] = +x.toFixed(2);
        step[i + 1] = +y.toFixed(2);
      }
    }
    const gPath = new fabric.Path(fPath.path);
    gPath.set('fill', canvasObject.fillStyle);

    return gPath;
  };
  /**
   * Returns font size multiplied by 1.
   *
   */
  CanvasRenderingContext2D.prototype.getFontSize = function getFontSize() {
    const font = this.font;
    const fontMatch = font.match(/\d+/);
    if (fontMatch !== null) {
      return 1 * Number(fontMatch[0]);
    }

    return null;
  };

  CanvasRenderingContext2D.prototype.fillText = function fillText(text, x, y) {
    if (!text) {
      return;
    }

    const width = this.measureText(text).width;
    const isRtl = this.direction === 'rtl';
    const offsetFactor = {
      left: 0,
      center: 0.5,
      right: 1,
      start: isRtl ? 1 : 0,
      end: isRtl ? 0 : 1,
    };
    const fontFamily = getFontFamily(this.font);
    const currentFont = fonts.find((fonts) => Object.keys(fonts)[0] === fontFamily);

    if (!currentFont) {
      return;
    }

    const openTypeFont = currentFont[`${fontFamily}`].font;
    const fontSize = this.getFontSize();
    if (!openTypeFont || fontSize === null) {
      return;
    }

    const offsetX = x - width * offsetFactor[this.textAlign];
    const path = openTypeFont.getPath(text, offsetX, y, fontSize);
    path.fill = this.fillStyle as string;
    path.draw(this);

    const m = this.getTransform();

    const r = _fabric.devicePixelRatio || window.devicePixelRatio;
    const fontPath = createFontPath({
      transform: [m.a / r, m.b / r, m.c / r, m.d / r, m.e, m.f],
      path: path.toPathData(2),
      fillStyle: this.fillStyle,
    });

    if (fontPath) {
      onTextTransformedToPath(fontPath);
    }
  };

  CanvasRenderingContext2D.prototype.measureText = function measureText(text) {
    let width = 0;

    const fontFamily = getFontFamily(this.font);
    const font = getFont(fontFamily);
    const fontSize = this.getFontSize();

    if (font && font !== null && fontSize) {
      font.forEachGlyph(`${text} `, 0, 0, fontSize, {}, (_, x) => {
        width = x;
      });
    }

    return {
      width,
    };
  };

  _fabric.hasLoadedOpenType = true;
};
