/**
 * Retrieves all objects from the canvas and finds the index of the active object.
 *
 * @param {Object} canvas - The canvas instance from which to retrieve objects.
 * @returns {number} return.currentIndex - The index of the active object in the objects array.
 * @returns {Array} return.objects - The array of all objects on the canvas.
 */
export const getOrderIndices = (canvas) => {
  const activeObject = canvas.getActiveObject();
  const objects = canvas.getObjects();
  const currentIndex = objects.findIndex((el) => el.name === activeObject?.name);
  return { currentIndex, objects };
};
