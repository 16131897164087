import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@hallmark/web.core.feedback.dialog';
import { DialogIconNames, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { BrandColors } from '@hallmark/web.styles.colors';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { ActionButton } from './fragments/action-button';
import { CancelButton } from './fragments/cancel-button';
import { SystemFailureDialogProps } from './system-failure-dialog-types';

export const SystemFailureDialog = ({ actionButton, cancelButton, onClose, isOpen }: SystemFailureDialogProps) => {
  const { t } = useTranslation();
  const region = getCurrentRegion();

  return (
    <Dialog
      id={'system-failure-dialog'}
      isOpen={isOpen}
      onClose={onClose}
      closeButtonId={'close-button'}
      accentIcon={DialogIconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={true}
      type={DialogContentType.Modal}
      testId="system-failure-dialog"
      title="System Error"
      footerType={DialogFooterType.Cancel}
      disableDialogScroll={true}
      actionButton={<ActionButton click={actionButton} />}
      cancelButton={<CancelButton click={cancelButton} />}
      locale={region}
    >
      <p>{t('systemFailureDialog.message')} </p>
    </Dialog>
  );
};
