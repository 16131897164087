import React, { createContext, Reducer, useContext, useReducer } from 'react';
import { initialAddressSelectionState } from './address-selection-state';
import {
  AddressSelectionAction,
  AddressSelectionContextState,
  AddressSelectionProviderProps,
  AddressSelectionType,
  CreateContextProps,
} from './address-selection-types';

export const AddressSelectionContext = createContext<CreateContextProps>(undefined);

const addressSelectionReducer: Reducer<AddressSelectionContextState, AddressSelectionAction> = (state, action) => {
  switch (action.type) {
    case AddressSelectionType.SET_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case AddressSelectionType.INCREASE_COUNT: {
      return {
        ...state,
        count: state.count + 1,
      };
    }

    case AddressSelectionType.DECREASE_COUNT: {
      return {
        ...state,
        count: state.count - 1,
      };
    }

    case AddressSelectionType.SET_MAX_COUNT: {
      return {
        ...state,
        maxCount: action.payload,
      };
    }

    case AddressSelectionType.SET_IS_MAX_COUNT_REACHED: {
      return {
        ...state,
        isMaxCountReached: action.payload,
      };
    }

    case AddressSelectionType.RESET_COUNT: {
      return {
        ...state,
        count: 0,
      };
    }
    default: {
      throw new Error('Unhandled action type in address-selection-context');
    }
  }
};

export const AddressSelectionProvider = ({ children }: AddressSelectionProviderProps) => {
  const [addressSelectionState, addressSelectionDispatch] = useReducer<
    Reducer<AddressSelectionContextState, AddressSelectionAction>
  >(addressSelectionReducer, initialAddressSelectionState);

  return (
    <AddressSelectionContext.Provider value={{ addressSelectionState, addressSelectionDispatch }}>
      {children}
    </AddressSelectionContext.Provider>
  );
};

export const useAddressSelectionContext = () => {
  const context = useContext(AddressSelectionContext);

  if (!context) {
    throw new Error('useAddressSelectionContext must be used within AddressSelectionProvider');
  }

  return context;
};
