import { GetDeleteQuickAddress } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const getQuickAddressDeleteTag = (options: GetDeleteQuickAddress) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const modalEvent = [
    {
      eventInfo: {
        eventName: 'Quick Addresses',
        eventAction: 'Address Deleted',
      },
    },
  ];

  const level2 = getModalLevel2(options.productType, options.formTitle);
  const { modal, user } = getModalTag(options.productType, level2, modalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
