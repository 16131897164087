import React, { Reducer, createContext, useReducer, useContext } from 'react';
import { initState as initBaseState, toasterInitialState } from './app-context-state';
import {
  actionTypes,
  AppContextState,
  AppAction,
  AppProviderProps,
  CreateContextProps,
  DrawersState,
} from './app-context-types';

export const AppContext = createContext<CreateContextProps>(undefined);

const closedDrawers: DrawersState = {
  isTextDrawerOpen: false,
  isAlignmentDrawerOpen: false,
  isFontDrawerOpen: false,
  isSizeDrawerOpen: false,
  isImageUploadDrawerOpen: false,
  isWamDrawerOpen: false,
  isImageEditDrawerOpen: false,
  isRotationDrawerOpen: false,
  isColorDrawerOpen: false,
  isColorPalleteDrawerOpen: false,
  isOrderDrawerOpen: false,
  isScaleDrawerOpen: false,
  isImageUploadToPhotoZoneOpen: false,
  isSpellcheckDialogOpen: false,
  isBulkAddressUploadOpen: false,
};

const appReducer: Reducer<AppContextState, AppAction> = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_TEXT_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isTextDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_SIZE_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isSizeDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_PROJECT_SAVED: {
      return {
        ...state,
        isProjectSaved: action.payload,
      };
    }
    case actionTypes.SET_IS_ORDER_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isOrderDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_IMAGE_UPLOAD_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isImageUploadDrawerOpen: true,
      };
    }
    case actionTypes.SET_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessages: action.payload,
      };
    }
    case actionTypes.SET_IS_IMAGE_UPLOAD_TO_PHOTOZONE_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isImageUploadToPhotoZoneOpen: true,
      };
    }
    case actionTypes.SET_IS_IMAGE_EDIT_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isImageEditDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_WAM_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isWamDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_ROTATION_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isRotationDrawerOpen: true,
      };
    }
    case actionTypes.SET_IS_SCALE_DRAWER_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isScaleDrawerOpen: true,
      };
    }
    case actionTypes.SET_ALL_DRAWERS_CLOSED: {
      return {
        ...state,
        ...closedDrawers,
      };
    }
    case actionTypes.SET_IS_TOASTER_OPEN: {
      return {
        ...state,
        toaster: {
          ...action.payload,
          isOpen: true,
        },
      };
    }
    case actionTypes.SET_IS_TOASTER_CLOSED: {
      return {
        ...state,
        toaster: toasterInitialState,
      };
    }
    case actionTypes.SHOW_LOADING_SCREEN: {
      return {
        ...state,
        loader: { isLoading: true, title: action.payload },
      };
    }
    case actionTypes.HIDE_LOADING_SCREEN: {
      return {
        ...state,
        loader: { isLoading: false, title: '' },
      };
    }
    case actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_OPEN: {
      return {
        ...state,
        isDeleteConfirmationDialogOpen: true,
      };
    }
    case actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_CLOSED: {
      return {
        ...state,
        isDeleteConfirmationDialogOpen: false,
      };
    }
    case actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_OPEN: {
      return {
        ...state,
        isClearConfirmationDialogOpen: true,
      };
    }
    case actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_CLOSED: {
      return {
        ...state,
        isClearConfirmationDialogOpen: false,
      };
    }
    case actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_OPEN: {
      return {
        ...state,
        isResetConfirmationDialogOpen: true,
      };
    }
    case actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_CLOSED: {
      return {
        ...state,
        isResetConfirmationDialogOpen: false,
      };
    }
    case actionTypes.SET_IS_SYSTEM_ERROR_OPEN: {
      return {
        ...state,
        isSystemErrorOpen: action.payload,
      };
    }
    case actionTypes.SET_IS_REDIRECT_DIALOG_OPEN: {
      return {
        ...state,
        isRedirectDialogOpen: action.payload.isDialogOpen,
        redirectUrl: action.payload.redirectUrl,
      };
    }
    case actionTypes.SET_CART_UTILITY: {
      return {
        ...state,
        cartUtility: action.payload,
      };
    }
    case actionTypes.SET_PERSONALIZATION_DATA: {
      return {
        ...state,
        isProjectSaved: true,
        personalizationData: action.payload,
      };
    }
    case actionTypes.ADD_COLOR_TO_MORE_COLORS_ADDED: {
      return {
        ...state,
        moreColorsAdded: [...state.moreColorsAdded, action.payload],
      };
    }
    case actionTypes.ADD_PHOTO_TRAY_IMAGES: {
      return {
        ...state,
        photoTrayImages: [...state.photoTrayImages, ...action.payload],
      };
    }

    case actionTypes.INCREASE_PAGE_PHOTO_TRAY_IMAGES: {
      return {
        ...state,
        photoTrayImagesPage: state.photoTrayImagesPage + 1,
      };
    }

    case actionTypes.DELETE_PHOTO_TRAY_IMAGE: {
      return {
        ...state,
        photoTrayImages: state.photoTrayImages.filter((image) => {
          const imageId = image.image_reference_id || image.photo_tray_image_id;
          return imageId !== action.payload;
        }),
      };
    }
    case actionTypes.SET_EXIT_URL: {
      return {
        ...state,
        exitUrl: action.payload,
      };
    }
    case actionTypes.SET_SEND_TO_ME: {
      return {
        ...state,
        isSendToMe: action.payload,
      };
    }

    case actionTypes.SET_TRIGGERING_ELEMENT_REF: {
      return {
        ...state,
        triggeringElementRef: action.payload,
      };
    }

    case actionTypes.SET_CSRF_TOKEN: {
      return {
        ...state,
        csrfToken: action.payload,
      };
    }

    case actionTypes.SET_PRODUCT_QUANTITY: {
      return {
        ...state,
        productQuantity: action.payload,
      };
    }

    case actionTypes.SET_CARD_FACES_LOADED: {
      return {
        ...state,
        cardFacesLoaded: action.payload,
      };
    }

    case actionTypes.SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case actionTypes.SET_IS_FIRST_SAVE: {
      return {
        ...state,
        isFirstSave: action.payload,
      };
    }

    case actionTypes.SET_SIGN_IN_DIALOG_OPEN: {
      return {
        ...state,
        signInDialogOpen: action.payload,
      };
    }

    case actionTypes.STORE_PREVIEW_IMAGES: {
      return {
        ...state,
        previewImages: action.payload,
      };
    }

    case actionTypes.SET_IS_SPELLCHECK_DIALOG_OPEN: {
      return {
        ...state,
        isSpellcheckDialogOpen: action.payload,
      };
    }

    case actionTypes.SET_SKIP_SPELLCHECK_VALIDATION: {
      return {
        ...state,
        skipSpellcheckValidation: action.payload,
      };
    }

    case actionTypes.SET_CHECK_SPELLING: {
      return {
        ...state,
        checkSpelling: action.payload,
      };
    }

    case actionTypes.SET_IS_BULK_ADDRESS_UPLOAD_OPEN: {
      return {
        ...state,
        ...closedDrawers,
        isBulkAddressUploadOpen: action.payload,
      };
    }

    // case actionTypes.SET_JWT: {
    //   return {
    //     ...state,
    //     jwt: action.payload,
    //   };
    // }

    default: {
      throw new Error(`Unhandled action type in app-context`);
    }
  }
};

// Context Specific Methods go here
export const AppContextProvider = ({ children }: AppProviderProps) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const productQuantity = queryParameters.get('qty') ?? '1';
  const initState = { ...initBaseState, productQuantity };
  const [appState, appDispatch] = useReducer(appReducer, initState);
  const value = { appState, appDispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Hooks
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useCard must be used within AppProvider');
  }

  return context;
};
