import { getCookie } from '../../../utils';

export const getTrackingConsent = () => {
  const optanonCookie = getCookie('OptanonConsent');
  if (!optanonCookie) {
    return 'not-granted';
  }

  return optanonCookie.includes('C0002%3A1%2') ? 'granted' : 'not-granted';
};
