import { useEffect } from 'react';
import API from '@dotcom/api-middleware';
import { useAppContext } from '../context/app-context';
import { setCsrfToken } from '../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../context/data-context';
import { getMonolithUrl } from '../utils';

type CsrfToken = {
  csrf_token?: string;
  error?: string;
  data?: {
    action?: string;
    csrf_token?: string;
    status?: number;
  };
};

const service = new API(getMonolithUrl(), {
  Accept: 'application/json',
});

service.updateDefaultConfig('withCredentials', true);

export const getCsrfToken = (): Promise<string> =>
  service.get('/csrf-token').then((res: CsrfToken) => {
    const token = res.data?.csrf_token || res.csrf_token;

    if (!token) {
      throw new Error('CSRF token not found');
    }

    return token;
  });

export const useCsrfToken = () => {
  const {
    appState: { csrfToken },
    appDispatch,
  } = useAppContext();

  const {
    initializedDataState: { isUS },
  } = useInitializationDataContext();

  useEffect(() => {
    if (!csrfToken && isUS) {
      getCsrfToken().then((token) => {
        setCsrfToken(appDispatch, token);
      });
    }
  }, []);

  return csrfToken;
};
