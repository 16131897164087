/**
 * Set spellcheck suggestions for a text object, they are stored in data property
 * @param textObject the text object to add suggestions to
 * @param suggestions array with spellcheck sugggestions
 */
export const setSuggestions = (textObject: fabric.Object, suggestions) => {
  textObject.set({
    data: {
      ...textObject.data,
      suggestions: suggestions,
    },
  });
};
