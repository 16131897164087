import { fabric } from 'fabric';
import { CustomControlOptions } from '../canvas-custom-config-types';

const drawMiddleControl = (ctx: CanvasRenderingContext2D, width: number, length: number) => {
  // draws a vertical rectangle, rotation is handled as needed for each control
  ctx.rect(-width / 2, -length / 2, width, length);
  ctx.stroke();
  ctx.fill();
};

export const renderMiddleControl = (
  controlName: string,
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
) => {
  const { left, top, size, angle } = options;
  const rectWidth = 3;
  switch (controlName) {
    case 'ml':
    case 'mr':
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(angle));
      drawMiddleControl(ctx, rectWidth, size);
      ctx.restore();
      break;
    case 'mt':
    case 'mb':
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(90 + angle));
      drawMiddleControl(ctx, rectWidth, size);
      ctx.restore();
      break;
    default:
      break;
  }
};
