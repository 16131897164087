// Views will go here
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { SEO } from '@hallmark/web.page-components.seo';
import { DrawerContainer } from '../../components/card-controls/drawer-container';
import { CardEditor } from '../../components/card-editor';
import { CardSelectBar } from '../../components/card-select-bar';
import { ECardLimitModal } from '../../components/ecard-limit-modal/ecard-limit-modal';
import { Layout } from '../../components/layout';
import { SpellcheckDialog } from '../../components/spellcheck-dialog';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext } from '../../context/app-context';
import { hideLoadingScreen, setIsSaving } from '../../context/app-context/app-context-actions';
import { resetCardDataAction, useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList, SpellcheckActions, SpellcheckErrors } from '../../global-types';
import {
  useActiveCanvas,
  useExitUrl,
  useFeatureFlags,
  useIsDigitalGreeting,
  useIsSignAndSend,
  useLineItemUUID,
  useSanitizeSearchUrl,
  useSaveProject,
  useSystemErrorHandling,
} from '../../hooks';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { config } from '../../regional-config';
import { getFrontendErrorBody, isLoggedIn, isToolbarVisible } from '../../utils';
import { validateCardFacesAreNotEmpty } from '../../utils/canvas-utils';
import { isAnyOfRegions } from '../../utils/utility';
import styles from './editor.module.scss';
import { useSpellCheckDialog, useStartProject } from './hooks';
import { useEditorNavigation } from './hooks/useEditorNavigation';
import { getClassEditorOverlapStyle } from './utils/get-class-editor-overlap-style';

export const EditorView = (): ReactElement => {
  const { pathname } = useLocation();
  const {
    cardState: { slideCardIndex, activeCardIndex, cardFacesList },
    cardDispatch,
  } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const canvasParentRef = useRef<HTMLDivElement>(null);
  const isOneToMany = useIsOneToMany();

  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [triggerPageLoadTracking, setTriggerPageLoadTracking] = useState(false);
  const [triggerPageRefreshTracking, setTriggerPageRefreshTracking] = useState(false);
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [eCardLimitModal, setECardLimitModal] = useState(false);
  const isDataLoaded = useRef<boolean>(false);
  const { t } = useTranslation();
  const activeCanvas = useActiveCanvas();
  const isSignAndSend = useIsSignAndSend();
  const [isEditorToolbarVisible, setIsEditorToolbarVisible] = useState(false);
  const { onSystemError } = useSystemErrorHandling();
  const textEditorDrawerType = config?.cardEditor?.textEditorDrawerType;
  const footerContainerClass = config?.cardSelectBar?.containerClass;

  const {
    appState: {
      isSystemErrorOpen,
      cardFacesLoaded,
      isTextDrawerOpen,
      isImageUploadDrawerOpen,
      isImageUploadToPhotoZoneOpen,
      isSpellcheckDialogOpen,
    },
    appDispatch,
  } = useAppContext();
  const {
    trackClickPreviewMode,
    trackStartFlow,
    firstElementSelected,
    trackPageNavigation,
    trackRefresh,
    trackSpellcheckDialog,
  } = useAnalyticsContext();

  const isCreateView = useMemo(() => pathname.indexOf('create') >= 0, [pathname]);
  const pageTitle = useMemo(
    () => (isCreateView ? t('editorView.pageTitle') : t('editorView.editPageTitle')),
    [isCreateView],
  );
  const isDigitalGreeting = useIsDigitalGreeting();
  const {
    SAS_TOOLBAR_TOGGLE,
    SAS_DYNAMIC_BUTTONS,
    IS_LOYALTY_ENABLED,
    IS_SPELLCHECK_ENABLED,
    IS_AUTOSAVE_PROJECTS_ENABLED,
  } = useFeatureFlags();
  const {
    saveProjects: { isSavedProjectsEnabled },
  } = config;
  const [editorClass, setEditorClass] = useState(styles['editor']);
  const { saveProject } = useSaveProject();
  useLineItemUUID();
  useExitUrl();
  const { isSearchUrlSanitized } = useSanitizeSearchUrl();

  const currentCardFace = cardFacesList[`${activeCardIndex}`];
  const isFrontFace = currentCardFace?.type === 'front';

  const editableFaces = useMemo(() => cardFacesList.filter((face) => face.editorDisplayIndicator), [cardFacesList]);
  const { buttonProps, handleIndexChange, handleProjectDone } = useEditorNavigation();

  const { ignoreSpellcheck, handleCheckSpelling } = useSpellCheckDialog();

  // TODO: Move this to a custom hook to clean the component
  /**
   * First function to be called when user open the page, which
   * kicks off loading of project assets
   */
  const startProject = useStartProject(isDataLoaded, [pathname, isSearchUrlSanitized]);

  const onChangeIndex = (newIndex: number) => {
    if (newIndex === activeCardIndex) {
      return;
    }

    if (newIndex === editableFaces.length - 1) {
      const newActiveCardIndex = editableFaces[slideCardIndex + 1].faceNumber - 1;
      handleIndexChange(newIndex, newActiveCardIndex);
    } else {
      handleIndexChange(newIndex);
    }
  };

  const handleClickOutsideCanvas = (ev: MouseEvent | TouchEvent) => {
    const target = ev.target as HTMLElement;
    if (target?.nodeName !== 'CANVAS') {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }
  };

  const autoSaveProject = async () => {
    if (isSavedProjectsEnabled && IS_AUTOSAVE_PROJECTS_ENABLED) {
      setIsSaving(appDispatch, true);
      // Generate previews if we don't have any
      if (initializedData?.project_type_code !== 'S' && !previewGenerated) {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
          generateFEPreviews: true,
        });
        setPreviewGenerated(true);
      } else {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
          generateFEPreviews: false,
        });
      }
      setIsSaving(appDispatch, false);
    }
  };

  /**
   * Callback function from Spellcheck Dialog when "Check spelling" is clicked.
   * This function will navigate to the first card-face that has a spelling error.
   * @param firstErrorFaceIndex {number} index of the first card-face that has a spelling error
   * @param textsWithErrors {SpellcheckErrors} object containing arrays of text objects with spelling errors mapped to their card-face by faceID
   */
  const handleOnSpellcheck = async (firstErrorFaceIndex: number, textsWithErrors: SpellcheckErrors) => {
    if (isSpellcheckDialogOpen) {
      trackSpellcheckDialog(SpellcheckActions.Fix);
    }
    handleCheckSpelling(firstErrorFaceIndex, textsWithErrors);
  };

  const handleOnIgnoreSpellcheck = (dialogAction) => {
    if (isSpellcheckDialogOpen) {
      trackSpellcheckDialog(dialogAction);
    }
    ignoreSpellcheck(handleProjectDone);
  };

  useEffect(() => {
    if (isDataLoaded.current) {
      return;
    }
    // reset card state re-load canvas if moving back to edit
    resetCardDataAction(cardDispatch);
    // initialize or load project and save the response in corresponding context
    startProject();
  }, [startProject, isDataLoaded.current]);

  useEffect(() => {
    // We save this so we can load the project from the address view if the page is refreshed
    sessionStorage.setItem('lastProjectLoaded', initializedData?.project_id || '');
  }, [initializedData?.project_id]);

  useEffect(() => {
    // Additional saved info in case of address view refresh
    sessionStorage.setItem('lastProjectTypeCode', initializedData?.project_type_code || '');
  }, [initializedData?.project_type_code]);

  useEffect(() => {
    const backgroundImagesAreLoaded = activeCanvas?.current?.backgroundImage !== null;
    if (cardFacesLoaded && isLoggedIn() && !initializedData?.assets?.[0] && backgroundImagesAreLoaded) {
      autoSaveProject();
    }
  }, [cardFacesLoaded, initializedData?.assets]);

  useEffect(() => {
    if (cardFacesLoaded) {
      hideLoadingScreen(appDispatch);
    }
  }, [cardFacesLoaded]);

  useEffect(() => {
    setTriggerPageLoadTracking(true);
  }, []);

  useEffect(() => {
    if (initializedData?.product_id && isInitialPageLoad && triggerPageLoadTracking) {
      if (isCreateView) {
        trackStartFlow(isDigitalGreeting ? 'Digital Greetings' : 'Print on Demand');
      }
      setIsInitialPageLoad(false);
    }
    if (!initializedData) {
      setTriggerPageRefreshTracking(true);
    }
  }, [initializedData, isInitialPageLoad, triggerPageLoadTracking, isCreateView]);

  useEffect(() => {
    // this is set to track the slide changes before any elements are edited or selected
    if (!firstElementSelected.current) {
      trackClickPreviewMode();
    } else {
      trackPageNavigation();
    }
  }, [activeCardIndex]);

  useEffect(() => {
    const clickOrTouch = 'ontouchstart' in window ? 'touchstart' : 'click';
    canvasParentRef?.current?.addEventListener(clickOrTouch, handleClickOutsideCanvas);
    if (activeCanvas) {
      setIsEditorToolbarVisible(isToolbarVisible(activeCanvas, isSignAndSend, SAS_DYNAMIC_BUTTONS));
      if (triggerPageRefreshTracking && isDataLoaded.current) {
        (window.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[]).forEach((entry) => {
          if (entry.type === 'reload') {
            trackRefresh();
          }
        });
        setTriggerPageRefreshTracking(false);
      }
    }
    return () => canvasParentRef?.current?.removeEventListener(clickOrTouch, handleClickOutsideCanvas);
  }, [activeCanvas, isDataLoaded.current, cardFacesLoaded]);

  const seoMetaProperties = [
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:site_name',
      content: pageTitle,
    },
  ];

  useEffect(() => {
    const updateEditorClass = () => {
      const newEditorClass = getClassEditorOverlapStyle(
        styles,
        isTextDrawerOpen,
        activeCanvas,
        isMobile,
        isFrontFace,
        isImageUploadDrawerOpen,
        isImageUploadToPhotoZoneOpen,
        textEditorDrawerType,
        isSignAndSend,
      );
      setEditorClass(newEditorClass);
    };

    const timeout = setTimeout(updateEditorClass, 50);

    return () => clearTimeout(timeout);
  }, [isTextDrawerOpen, isImageUploadDrawerOpen, isImageUploadToPhotoZoneOpen]);

  useEffect(() => {
    if (
      cardFacesLoaded &&
      isDataLoaded.current &&
      initializedData?.variables.template_data &&
      cardFacesList.length > 0
    ) {
      try {
        validateCardFacesAreNotEmpty(cardFacesList, initializedData?.variables.template_data);
      } catch (error) {
        onSystemError(getFrontendErrorBody(error as string));
      }
    }
  }, [cardFacesLoaded, isDataLoaded.current]);

  return (
    <Layout hideToolbar={!isEditorToolbarVisible && !SAS_TOOLBAR_TOGGLE}>
      <SEO title={pageTitle} description={`${t('editorView.seo')}`} metaProperties={seoMetaProperties} />
      <DrawerContainer />
      <div className={editorClass}>
        <div
          className={
            !isOneToMany ? styles['workspace-panel'] : `${styles['workspace-panel']} ${styles['is-one-to-many']}`
          }
          data-testid="Workspace"
          id="Workspace"
          ref={canvasParentRef}
        >
          <CardEditor cardFaceIndex={slideCardIndex} />
        </div>
        <div className={classNames(styles[footerContainerClass ?? 'footer'])}>
          {cardFacesList && (
            <div className={styles['card-select-bar']}>
              <CardSelectBar
                faces={editableFaces}
                slideIndex={slideCardIndex}
                handleIndexChange={onChangeIndex}
                mode="edit"
              />
            </div>
          )}
          {!isSystemErrorOpen && isAnyOfRegions([RegionalCodesList.uk, RegionalCodesList.us]) && (
            <Button
              {...buttonProps}
              endIcon={{ name: IconNames.ArrowsTailRightBold, color: 'white' }}
              mode={ButtonModes.Primary}
              testId="address-button"
              addClass={styles['address-button']}
            />
          )}
        </div>
        {IS_LOYALTY_ENABLED && <ECardLimitModal onClose={() => setECardLimitModal(false)} isOpen={eCardLimitModal} />}
        {IS_SPELLCHECK_ENABLED && isSpellcheckDialogOpen && (
          <SpellcheckDialog
            isOpen={isSpellcheckDialogOpen}
            title="A quick spell check"
            onSpellcheck={handleOnSpellcheck}
            onIgnoreSpellcheck={handleOnIgnoreSpellcheck}
            cardFaces={cardFacesList}
          />
        )}
      </div>
    </Layout>
  );
};
