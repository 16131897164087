import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../hooks';
import { migrateProjects } from '../../../services/customization';

export const useMigrateProject = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();

  const migrateProject = async (migrateFromId: string, projectId: string) => {
    await migrateProjects(migrateFromId, projectId);
    queryParams.delete('migrateFrom');
    navigate({ pathname, search: queryParams.toString() }, { replace: true });
  };

  return migrateProject;
};
