import React, { Fragment } from 'react';
import { Checkbox } from '@hallmark/web.core.forms.checkbox';
import { Dropdown } from '@hallmark/web.core.forms.dropdown';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { isLoggedIn } from '../../utils';
import { FieldType, FormFieldsGeneratorProps } from './form-fields-generator-types';
import { CollapseSection } from './fragments/collapsed-section';

export const FormFieldsGenerator = ({ selectedFormFields, register }: FormFieldsGeneratorProps) => {
  return (
    <Fragment>
      {selectedFormFields.map(
        (field, index) =>
          field !== undefined &&
          (field.typeOfField === FieldType.COLLAPSE && field.nestedFields !== undefined ? (
            <CollapseSection key={`collapse_${index}`}>
              {field.nestedFields.map((nestedField) => (
                <Fragment key={`${nestedField.name}_${index}`}>
                  <TextField
                    register={register(nestedField.name, nestedField.registerOptions)}
                    domId={nestedField.domId}
                    label={nestedField.label}
                    isError={nestedField.isError}
                    helperText={nestedField.helperText}
                    addClass={nestedField.addClass}
                    testId={nestedField.testId}
                    required={nestedField.required}
                    onChange={nestedField.onChange}
                    key={`textfield_${nestedField.name}_${index}`}
                  />
                </Fragment>
              ))}
            </CollapseSection>
          ) : (
            <Fragment key={`${field.name}_${index}`}>
              {field.typeOfField === FieldType.DROPDOWN && field.showDropdown && (
                <Dropdown
                  options={field.dropdownOptions}
                  label={field.label}
                  register={register(field.name, field.registerOptions)}
                  helperText={field.helperText}
                  isError={field.isError}
                  addClass={field.addClass}
                  testId={field.testId}
                  required={field.required}
                  key={`dropdown_${field.name}_${index}`}
                />
              )}
              {field.typeOfField === FieldType.TEXTFIELD && (
                <TextField
                  register={register(field.name, field.registerOptions)}
                  domId={field.domId}
                  label={field.label}
                  isError={field.isError}
                  helperText={field.helperText}
                  addClass={field.addClass}
                  testId={field.testId}
                  required={field.required}
                  onChange={field.onChange}
                  key={`textfield_${field.name}_${index}`}
                />
              )}
              {field.typeOfField === FieldType.CHECKBOX && isLoggedIn() && (
                <Checkbox
                  register={register(field.name, field.registerOptions)}
                  domId={field.domId}
                  helperText={field.helperText}
                  addClass={field.addClass}
                  testId={field.testId}
                  required={field.required}
                  key={`checkbox_${field.name}_${index}`}
                >
                  {field.label}
                </Checkbox>
              )}
            </Fragment>
          )),
      )}
    </Fragment>
  );
};
