import { fitObjectToParent, centerObjectInParent, objectsFitParent } from '../../../utils';
import { getPhotoTextZoneResetButton } from './get-phototextzone-reset-button';
import { getPhotoTextZoneTextButton } from './get-phototextzone-text-button';
import { getPhotoTextZoneWAMButton } from './get-phototextzone-wam-button';

/**
 * Add buttons to phototextzones depending on zone dimensions
 * @param canvas - fabric canvas
 * @param zone - fabric object
 * @returns promise
 * @async
 */
export const addPhototextzoneButtons = async (
  canvas: fabric.Canvas,
  zone: fabric.Object,
  isZoneHybrid?: boolean,
): Promise<void> => {
  if (canvas && zone) {
    // First try adding the buttons on a regular horizontal layout
    const zoneWidth = zone.width as number;
    const zoneHeight = zone.height as number;
    const textButton = await getPhotoTextZoneTextButton(zone, false, false);
    const wamButton = await getPhotoTextZoneWAMButton(zone, false, false);
    const textButtonHeight = textButton.height as number;
    const wamButtonHeight = wamButton.height as number;
    const { horizontalFit, verticalFit } = objectsFitParent(zone, [textButton, wamButton]);

    // Get the reset button to add it to the canvas
    const resetButton = await getPhotoTextZoneResetButton(zone);
    canvas.add(resetButton);

    // If buttons fit in the zone on a horizontal layout, add them to canvas and return
    if (horizontalFit && verticalFit && isZoneHybrid) {
      canvas.add(textButton, wamButton);
      return;
    }

    // Validate if the buttons would fit vertically on the zone
    if (verticalFit && textButtonHeight < zoneHeight && wamButtonHeight < zoneHeight && isZoneHybrid) {
      // If buttons fit in the zone on a vertical layout, add them to canvas and return
      const textButtonVertical = await getPhotoTextZoneTextButton(zone, true, false);
      const wamButtonVertical = await getPhotoTextZoneWAMButton(zone, true, false);
      canvas.add(textButtonVertical, wamButtonVertical);
      return;
    }

    // If buttons don't fit vertically, try adding the buttons with min icons
    const textButtonMin = await getPhotoTextZoneTextButton(zone, false, true);
    const wamButtonMin = await getPhotoTextZoneWAMButton(zone, false, true);
    // Calculate distance between text button left and wam button right
    const textButtonLeft = textButtonMin.left as number;
    const wamButtonRight = (wamButtonMin.left as number) + (wamButtonMin.width as number);
    const buttonsWidth = wamButtonRight - textButtonLeft;
    // Validate if the min buttons would fit horizontally on the zone
    if (zoneWidth > buttonsWidth) {
      // If min buttons fit in the zone on a horizontal layout, make sure they fit and add them to canvas
      fitObjectToParent(textButtonMin, zone);
      fitObjectToParent(wamButtonMin, zone);
      canvas.add(textButtonMin, wamButtonMin);
      return;
    }

    // If both min buttons don't fit on the zone, center, fit and add only the text button
    centerObjectInParent(textButtonMin, zone);
    fitObjectToParent(textButtonMin, zone);
    canvas.add(textButtonMin);
  }
};
