import { useEffect, useRef } from 'react';
import { useActiveCanvas } from './useActiveCanvas';

export const useHeaderClick = () => {
  const activeCanvas = useActiveCanvas();
  const headerParentRef = useRef<HTMLDivElement>(null);

  const handleClickInsideHeader = () => {
    if (activeCanvas?.current?.getActiveObject() !== null) {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }
  };

  useEffect(() => {
    const clickOrTouch = 'ontouchstart' in window ? 'touchstart' : 'click';
    headerParentRef?.current?.addEventListener(clickOrTouch, handleClickInsideHeader);
    return () => {
      document.removeEventListener(clickOrTouch, handleClickInsideHeader);
    };
  }, [activeCanvas]);

  return headerParentRef;
};
