import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../../hooks';
import { getSelectableObjectsIndices } from '../../../../utils';
import { HeaderContentProps } from '../order-drawer-types';
import styles from '../order-drawer.module.scss';

export const HeaderContent = ({ currentOrder, handleOrderChange }: HeaderContentProps) => {
  const canvas = useActiveCanvas();
  const { min, max, tickCount, currentIndex } = useMemo(() => {
    if (canvas?.current) {
      const allObjects = canvas.current.getObjects();
      const selectableObjects = getSelectableObjectsIndices(allObjects);
      const selectableObjectsCount = selectableObjects.length;

      return {
        min: 0,
        max: selectableObjectsCount - 1,
        tickCount: selectableObjectsCount > 3 ? selectableObjectsCount : 3,
        currentIndex: selectableObjects.findIndex((index) => index === currentOrder),
      };
    }
    return { min: 0, max: 2, tickCount: 3, currentIndex: 0 };
  }, [canvas]);

  const { t } = useTranslation();

  const { register, setValue } = useForm();

  useEffect(() => {
    setValue('order-range', currentIndex);
  }, []);

  const onChange = (order: number) => {
    handleOrderChange(order);
  };

  return (
    <div className={styles['header-wrapper']}>
      <div className={styles['range-label']}>
        <Typography variant={TypographyVariants.FieldLabel}>{t('orderDrawer.background')}</Typography>
        <Typography variant={TypographyVariants.FieldLabel}>{t('orderDrawer.foreground')}</Typography>
      </div>
      <Range
        addClass={styles['order-range']}
        domId="order-range-input"
        label="Order range"
        register={register('order-range', { valueAsNumber: true })}
        onChange={onChange}
        tickCount={tickCount}
        step={1}
        min={min}
        max={max}
        testId="order-drawer-range-input"
      />
    </div>
  );
};
