import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import CrownRewards from '../../../assets/crown-rewards.svg';

export const useLoyaltyContents = () => {
  const { t } = useTranslation();

  const containerText = (
    <Typography variant={TypographyVariants.Body}>{t('noCrNoHDialog.firstContainerText')}</Typography>
  );

  const joinCrownRewardsButton = (
    <Button
      mode={ButtonModes.Primary}
      click={() => window.location.replace('/crown-rewards/')}
      testId="loyalty-dialog-button"
    >
      {t('noCrNoHDialog.firstContainerButtonText')}
    </Button>
  );

  const loyaltyContainerContents = {
    image: CrownRewards,
    body: containerText,
    btnAction: joinCrownRewardsButton,
  };

  return { loyaltyContainerContents };
};
