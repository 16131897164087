import { storePreviews } from '../../../context/app-context/app-context-actions';
import { Dispatch } from '../../../context/app-context/app-context-types';
import { setCoverPreviewUrl } from '../../../context/card-context/card-context-actions';
import { InitializationData } from '../../../global-types';
import { AssetTypes } from '../../../global-types/initialization-data';

/**
 * Uses assets from project data to populate previews if address page is reloaded
 *
 * @param initializationData - Data containing our assets
 * @param appDispatch - Required to update state
 * @returns void
 */
export const populatePreviewImages = (initializationData: InitializationData, appDispatch: Dispatch, cardDispatch) => {
  if (initializationData.assets && initializationData.assets.length > 0) {
    // BE may be able to do this sort in the future
    const sortedAssetsByDate = initializationData.assets.sort((a, b) => {
      return Date.parse(b.created_at) - Date.parse(a.created_at);
    });
    const frontPreview = sortedAssetsByDate.find((asset) => asset.asset_type_code === AssetTypes.FRONT_PANEL);
    const innerPreview = sortedAssetsByDate.find((asset) => asset.asset_type_code === AssetTypes.INSIDE_PANEL);
    const backPreview = sortedAssetsByDate.find((asset) => asset.asset_type_code === AssetTypes.BACK_PANEL);
    const smallPreview = sortedAssetsByDate.find((asset) => asset.asset_type_code === AssetTypes.FRONT_PANEL_PREVIEW);
    if (smallPreview) {
      setCoverPreviewUrl(cardDispatch, smallPreview.url);
    } else if (frontPreview) {
      // Try to fallback to "F" asset if "P" asset isn't found
      setCoverPreviewUrl(cardDispatch, frontPreview.url);
    } else {
      // S&S card has only "I" asset ... in this case, use template image from front preview
      setCoverPreviewUrl(cardDispatch, initializationData.variables.template_data.Faces[0].BackgroundUrl);
    }
    if (frontPreview && innerPreview) {
      // If we have front and inner previews, this is a regular POD card
      storePreviews(appDispatch, [
        { dataURL: frontPreview.url, url: frontPreview.url, type: 'Front' },
        { dataURL: innerPreview.url, url: innerPreview.url, type: 'Inside' },
      ]);
    } else if (!frontPreview && innerPreview) {
      // If we have only an inner preview, this is a S&S card
      const { Faces } = initializationData.variables.template_data;
      storePreviews(appDispatch, [
        { dataURL: Faces[0].BackgroundUrl, url: Faces[0].BackgroundUrl, type: 'Front' },
        { dataURL: Faces[1].BackgroundUrl, url: Faces[1].BackgroundUrl, type: 'Inside Left' },
        { dataURL: innerPreview.url, url: innerPreview.url, type: 'Inside Right' },
      ]);
    } else if (frontPreview && backPreview) {
      // If we have a backPreview, this is a 1-to-many POD card
      storePreviews(appDispatch, [
        { dataURL: frontPreview.url, url: frontPreview.url, type: 'Front' },
        { dataURL: backPreview.url, url: frontPreview.url, type: 'Back' },
      ]);
    }
  } else {
    throw new Error('Error populating preview due to missing assets');
  }
};
