import blankEnvelope from '../../assets/Blank.webp';
import returnEnvelope from '../../assets/ReturnAddressonly.webp';
import returnRecipientEnvelope from '../../assets/Return_wRecipient.webp';
import { SelectedEnvelope } from '../../global-types';
import { EnvelopeType } from '../../global-types/envelope';

export const ENVELOPES: SelectedEnvelope[] = [
  {
    id: EnvelopeType.Blank,
    image: blankEnvelope,
  },
  {
    id: EnvelopeType.Return,
    image: returnEnvelope,
  },
  {
    id: EnvelopeType.ReturnRecipient,
    image: returnRecipientEnvelope,
  },
];
