import { getGroupedItemByName } from '../canvas-utils';
import { CanvasDataTypes } from '../canvas-utils/canvas-data-types';

/**
 * Function that returns the grouped text object from a zone or the active canvas.
 * @param activeCanvas
 * @param zone
 * @returns The grouped text object from the zone or the active canvas
 */
export const getGroupedTextObject = (
  activeCanvas?: fabric.Canvas | null,
  zone: fabric.Group | null = null,
): fabric.Object | null | undefined => {
  if (zone) {
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, zone);
    return groupedTextbox as fabric.Textbox;
  } else if (!zone && activeCanvas?.getActiveObject()?.type === 'group') {
    const objectGroup = activeCanvas?.getActiveObject() as fabric.Group;
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, objectGroup);
    return groupedTextbox as fabric.Textbox;
  }

  return activeCanvas?.getActiveObject();
};
