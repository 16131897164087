import { fabric } from 'fabric';
import { CustomControlOptions } from '../canvas-custom-config-types';

export const drawRotateControl = (
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
  scalingFactor: number,
) => {
  const { left, top, size, angle } = options;
  const sideSize = size / scalingFactor;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));
  ctx.rect(-sideSize / scalingFactor, -sideSize / scalingFactor, sideSize, sideSize);
  ctx.stroke();
  ctx.fill();
  ctx.restore();
};
