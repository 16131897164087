import { isTextbox } from '../../utils';

/**
 * Function that returns an array of text objects with spelling errors for a given canvas
 * @param canvas - Fabric canvas object belonging to a card face
 * @param faceId - Face index. Used to set faceId on each text object for later mapping
 * @returns {fabric.Textbox[]} - Array of text objects with spelling errors
 * @example getCanvasTextsWithErrors(canvas)
 *
 */
export const getCanvasTextsWithErrors = (canvas: fabric.Canvas, faceId: number): fabric.Textbox[] | null => {
  if (!canvas) {
    return null;
  }
  return canvas
    .getObjects()
    .filter((object) => isTextbox(object) && object.data?.suggestions)
    .map((textObject) => {
      // set faceId reference on each text object
      textObject.set({ data: { ...textObject.data, cardFaceId: faceId as number } });
      return textObject as fabric.Textbox;
    });
};
