import { PhotoTextZoneConfig } from '../../global-types';
import colorsList from '../../styles/util.scss';

export const photoTextZoneConfig: PhotoTextZoneConfig = {
  photoTextZoneDefaultSettings: {
    fill: 'transparent',
    stroke: colorsList['medium-gray'],
    strokeDashArray: [5],
    strokeWidth: 1,
    hasBorders: true,
    hasControls: false,
    hoverCursor: 'pointer',
    isModified: false,
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingFlip: true,
    lockSkewingX: true,
    padding: -2,
    selectable: true,
    paintFirst: 'stroke',
    evented: true,
    hasRotatingPoint: false,
  },
  // arbitrary value used to space the buttons in phototextzones
  buttonOffsetX: 125,
  buttonOffsetY: 60,
  resetButtonOffset: 50,
};

export const phototextZoneButtonOffset = 150;
