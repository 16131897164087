/**
 * Get the Y position of the provided object on the card
 * @param object fabric object to get the Y position on the card
 * @returns "top" if the provided object is on the top half of the card and "bottom" if it's on the bottom half
 */
export const getObjectPositionY = (object: fabric.Object): 'top' | 'bottom' | undefined => {
  const canvas = object.canvas;
  if (!canvas) {
    return;
  }
  const canvasHalfHeight = canvas.getHeight() / 2;
  const { top = 0 } = object;
  return top < canvasHalfHeight ? 'top' : 'bottom';
};
