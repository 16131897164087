import { fabric } from 'fabric';
import editTextIconUK from '../../../assets/edit-icon-uk.svg';
import editTextIcon from '../../../assets/edit-icon.svg';
import { EditableTextSettings } from '../../../global-types';
import { CanvasDataTypes } from '../../../utils';

const iconWidth = 48;

/** This calculates the position of the edit icon
 * relative to the axis of rotation of the text box
 * */
export const getAdjustedIconPosition = (settings: EditableTextSettings, scale: number) => {
  // convert angle from degrees to radians
  const angleInRadians = settings.angle * (Math.PI / 180);
  // half the width of the text box (the middle position from left or originX)
  const distance = (settings.width - scale / 2) / 2;

  // calculate new x and y coordinates for the edit icon
  // which is correct position relative to axis of rotation
  // subtract third of the icon width from left position, accounts for the rotation of icon
  const iconLeft = settings.left - iconWidth / 3 + distance * Math.cos(angleInRadians);
  const iconTop = settings.top + distance * Math.sin(angleInRadians);
  return { iconLeft, iconTop };
};

export function getEditableTextButton(
  settings: EditableTextSettings,
  scale: number,
  isUK: boolean | undefined,
): Promise<fabric.Image> {
  return new Promise((resolve) => {
    const { iconLeft, iconTop } = getAdjustedIconPosition(settings, scale);

    // edit button
    fabric.Image.fromURL(
      isUK ? editTextIconUK : editTextIcon,
      (image) => {
        image.scaleToWidth(scale);

        image.setOptions({
          name: 'edit-icon',
          hoverCursor: 'pointer',
          originY: settings.originY,
          left: iconLeft,
          top: iconTop,
          data: {
            type: CanvasDataTypes.EditableTextButton,
          },
        });

        resolve(image);
      },
      { width: iconWidth, height: iconWidth },
    );
  });
}
