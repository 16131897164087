import { validateImagePpi } from '../../../utils/canvas-utils/validate-image-ppi';
import { handleImageWithIconMoves } from './handle-image-with-icon-moves';

/**
 * Update the position of an icon when the parent object is resized.
 * if isLowResIcon is true, the icon will be visible only if the image is low resolution
 * @param icon - fabric object
 * @param parentObject - fabric object
 * @param offset - number
 * @param isStaticIcon - boolean
 * @param isLowResIcon - boolean
 */
export const handleImageWithIconResizes = (
  icon: fabric.Object,
  parentObject: fabric.Object,
  offset?: number,
  isStaticIcon?: boolean,
  isLowResIcon?: boolean,
): void => {
  if (isLowResIcon) {
    icon.visible = !validateImagePpi(parentObject as any);
  }
  if (!isStaticIcon) {
    handleImageWithIconMoves(icon, parentObject, offset || 0);
  }
};
