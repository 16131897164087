/**
 * This is added to custom objects in the data property
 * to make us easier identify these objects
 * @example object.data = { type: CanvasDataTypes.PhotoZone }
 */
export enum CanvasDataTypes {
  EditableText = 'editable-text',
  EditableTextButton = 'editable-text-button',
  EditableArea = 'editable-area',
  Placeholder = 'placeholder',
  UserText = 'user-text',
  PhotoZone = 'photo-zone',
  PhotoTextZone = 'photo-text-zone',
  PhotoTextZoneLabel = 'photo-text-zone-label',
  PhotoZoneImage = 'photo-zone-image',
  PhotoTextZoneButton = 'photo-text-zone-button',
  PhotoTextZoneTextButton = 'photo-text-zone-text-button',
  PhotoTextZoneResetButton = 'photo-text-zone-reset-button',
  PhotoTextZoneWAMButton = 'photo-text-zone-wam-button',
  PhotoZoneButton = 'photo-zone-button',
  PhotoZoneTextbox = 'photo-zone-textbox',
  UserImage = 'user-image',
  TemporaryTAMInput = 'temporary-tam-input',
  FoldLine = 'fold-line',
  RecipientBox = 'recipient-box',
  RecipientText = 'recipient-text',
  SenderBox = 'sender-box',
  SenderText = 'sender-text',
  UserZoneAddTextButton = 'user-zone-add-text-button',
  UserZoneAddWamButton = 'user-zone-add-wam-button',
  UserZoneInstructions = 'user-zone-add-instructions',
  PhotoIcon = 'photo-icon',
  RemovableOnSave = 'removable-on-save',
  PhotoFrameName = 'photo-frame',
  WamImage = 'wam-image',
}
