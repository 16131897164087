import { useCallback } from 'react';
import {
  setIsSpellcheckDialogOpen,
  setSkipSpellcheckValidation,
  setCheckSpelling,
  useAppContext,
} from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { SpellcheckErrors } from '../../../global-types';
import { resetAllTextsHighlight } from '../../../utils';
import { cleanSuggestionsFromCardFaces, handleHighlightErrors } from '../../../utils/spellcheck';
import { useEditorNavigation } from './useEditorNavigation';

export const useSpellCheckDialog = () => {
  const { appDispatch } = useAppContext();
  const {
    cardState: { cardFacesList },
  } = useCardContext();
  const { handleIndexChange } = useEditorNavigation();

  const closeSpellcheckDialog = () => {
    setIsSpellcheckDialogOpen(appDispatch, false);
  };

  const handleCheckSpelling = useCallback(
    async (firstErrorFaceIndex: number, textsWithErrors: SpellcheckErrors) => {
      setCheckSpelling(appDispatch, true);
      await handleIndexChange(firstErrorFaceIndex, firstErrorFaceIndex);
      handleHighlightErrors(textsWithErrors, cardFacesList);
      closeSpellcheckDialog();
    },
    [cardFacesList],
  );

  const ignoreSpellcheck = useCallback(
    async (handleProjectDoneCallback) => {
      await setSkipSpellcheckValidation(appDispatch, true);
      await closeSpellcheckDialog();

      setSkipSpellcheckValidation(appDispatch, true);
      closeSpellcheckDialog();
      setCheckSpelling(appDispatch, false);
      resetAllTextsHighlight(cardFacesList);
      cleanSuggestionsFromCardFaces(cardFacesList);
      handleProjectDoneCallback();
    },
    [cardFacesList],
  );

  return {
    closeSpellcheckDialog,
    ignoreSpellcheck,
    handleCheckSpelling,
  };
};
