import { InitializationData, ProjectTypeCode } from '../../global-types';

export const getProjectTypeCode = (initializedData: InitializationData): ProjectTypeCode => {
  const projectTypeCode = initializedData.project_type_code;

  if (!projectTypeCode) {
    throw Error('Project type code is invalid or undefined');
  }

  return projectTypeCode;
};
