import React, { createContext, ReactNode, Reducer, useContext, useReducer } from 'react';
import {
  actionTypes,
  DataActions,
  CreateContextProps,
  OnGoingEventsContextState,
} from './on-going-events-context-types';

export const InitializationOnGoingEventsContext = createContext<CreateContextProps>(undefined);

const reducer: Reducer<OnGoingEventsContextState, DataActions> = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_INIT_ON_GOING_EVENTS:
      return {
        ...state,
        onGoingEvents: action.payload,
      };
    case actionTypes.ADD_ON_GOING_EVENT:
      return {
        ...state,
        onGoingEvents: [...state.onGoingEvents, action.payload],
      };
    case actionTypes.RESET_ON_GOING_EVENTS:
      return {
        ...state,
        onGoingEvents: [],
      };
    default:
      throw new Error(`Unhandled action type in init-on-going-events-context`);
  }
};

export const InitializationOnGoingEventsContextProvider = ({ children }: { children: ReactNode }) => {
  const [onGoingEventsState, onGoingEventsDispatch] = useReducer(reducer, { onGoingEvents: [] });

  return (
    <InitializationOnGoingEventsContext.Provider value={{ onGoingEventsState, onGoingEventsDispatch }}>
      {children}
    </InitializationOnGoingEventsContext.Provider>
  );
};

// hooks
export const useInitializationOnGoingEventsContext = () => {
  const context = useContext(InitializationOnGoingEventsContext);

  if (context === undefined) {
    throw new Error(
      'useInitializationOnGoingEventsContext must be used within a InitializationOnGoingEventsContextProvider',
    );
  }

  return context;
};
