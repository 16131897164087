import { GetTamEditResetTag } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when user resets hybrid TAM/WAM box when in TAM mode
 * via toolbar or photozone button
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getTamEditResetTag = (options: GetTamEditResetTag) => {
  const photoUploadEvent = [
    {
      eventInfo: {
        eventName: 'reset',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getModalTag(options.productType, 'Edit Mode', photoUploadEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
