import { Dispatch, actionTypes } from './on-going-events-context-types';

export const setInitializationOnGoingEvents = (dispatch, initData = []) => {
  dispatch({
    type: actionTypes.SET_INIT_ON_GOING_EVENTS,
    payload: initData,
  });
};

export const addOnGoingEvent = (dispatch: Dispatch, event: string) => {
  dispatch({
    type: actionTypes.ADD_ON_GOING_EVENT,
    payload: event,
  });
};

export const resetOnGoingEvents = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.RESET_ON_GOING_EVENTS,
  });
};
