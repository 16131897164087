import { ErrorResponse } from '../../global-types';

/**
 * Function to get the error body for the frontend errors
 * @param error
 * @returns ErrorResponse
 */

export const getFrontendErrorBody = (error: string): ErrorResponse => ({
  status: 0,
  data: {
    errors: [
      {
        code: '0',
        description: error,
      },
    ],
  },
});
