import { DefaultProductOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const updatedProjectNameSuccessTag = (options: DefaultProductOptions) => {
  const updateProjectNameEvent = [
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: 'project name updated',
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2(options.productType);

  const { modal, user } = getModalTag(options.productType, level2, updateProjectNameEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
