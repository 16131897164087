import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { FontDrawerFooterProps } from '../font-drawer-types';
import styles from '../font-drawer.module.scss';

export const FontDrawerFooter = ({ onCancel, onSubmit }: FontDrawerFooterProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        testId="font-drawer-footer-cancel"
        mode={ButtonModes.TextLink}
        click={onCancel}
        addClass={styles['secondary-button']}
      >
        {t('fontDrawer.cancel')}
      </Button>
      <Button
        testId="font-drawer-footer-apply"
        mode={ButtonModes.Primary}
        click={onSubmit}
        addClass={styles['primary-button']}
      >
        {t('fontDrawer.apply')}
      </Button>
    </>
  );
};
