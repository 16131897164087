import { fabric } from 'fabric';
// TO DO Change this to the correct icon once we have it
import warningIcon from '../../../assets/warning-icon.svg';
import { CanvasDataTypes } from '../../../utils';
import { addIconToObject, IconsTypes } from './add-icon-to-object';

/**
 * Add icons to low resolution images on the canvas.
 *
 * @param face - card face to add user zone to
 * @param onClickIcon - function to call when icon is added.
 * @returns void
 */
export const addIconsToLowResImages = (canvas: fabric.Canvas, onIconAdded: (icon: fabric.Image) => void): void => {
  const canvasObjects = canvas.getObjects() ?? [];
  canvasObjects.forEach((currentObject) => {
    if (currentObject.data && currentObject.data.iconType === IconsTypes.LowRes) {
      if (currentObject.data.type === CanvasDataTypes.PhotoZoneImage) {
        const photoZone = canvasObjects.find((object) => object.name === currentObject.data.photoZoneId);
        addIconToObject(canvas, currentObject, warningIcon, IconsTypes.LowRes, {
          iconOffset: 35,
          isLowResIcon: true,
          onIconAdded,
          photoZone,
          isStaticIcon: true,
        });
      } else {
        addIconToObject(canvas, currentObject, warningIcon, IconsTypes.LowRes, {
          iconOffset: 35,
          isLowResIcon: true,
          onIconAdded,
        });
      }
    }
  });
};
