import { ApiResponse, CleanUpImageResponse, ImageResponse, LinkPhotoResponse } from '../../global-types';
import { linkPhoto, cleanUpImage, uploadPhotoS3 } from '../../services/image';
import { createSingleAssetUrl } from './image-create-single-asset-url';
import { validateUploadedImageFormat } from './validate-uploaded-image-format';

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

export class ImageUploadError extends Error {
  constructor() {
    super();
    this.name = 'ImageUploadError';
  }
}

export class ImageCleanUpError extends Error {
  constructor() {
    super();
    this.name = 'ImageCleanUpError';
  }
}

export class ImageLinkError extends Error {
  constructor() {
    super();
    this.name = 'ImageLinkError';
  }
}

const uploadImage = async (
  imageToUpload: File,
  projectId: string,
  args?: {
    cleanUp?: boolean;
    uploadMetadata?: Record<string, string>;
    events?: {
      onImageUpload?: (image: ImageResponse) => void;
      onCleanUpStart?: () => void;
    };
  },
) => {
  const { cleanUp = false, uploadMetadata, events } = args || {};
  const formData = new FormData();
  formData.append('file', imageToUpload);

  if (uploadMetadata) {
    Object.entries(uploadMetadata).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }

  const updatedImage = await validateUploadedImageFormat(imageToUpload);
  updatedImage && formData.set('file', updatedImage);

  // Generate S3 asset URL
  const s3Url = await createSingleAssetUrl(imageToUpload);

  const uploadResp = (await uploadPhotoS3(formData, false, s3Url)) as ApiResponse<ImageResponse>;
  if (!uploadResp.data) throw new ImageUploadError();

  const uploadedImage = uploadResp.data;

  // If onImageUpload is provided, let's emit that event
  events?.onImageUpload?.(uploadedImage);

  const linkPhotoResp: ApiResponse<LinkPhotoResponse> = await linkPhoto(
    { image_reference_id: uploadedImage[0].photo_tray_image_id },
    projectId,
  );

  if (linkPhotoResp && linkPhotoResp.meta.code !== 201) throw new ImageLinkError();

  const imageId = linkPhotoResp.data?.image_id || '';

  if (!cleanUp) {
    return { imageId: imageId, imageUrl: (linkPhotoResp.data as LinkPhotoResponse).image_url, cleanUpVersionId: '' };
  }

  // If onCleanUpStart is provided, let's emit that before cleaning up the image
  events?.onCleanUpStart?.();

  const cleanUpResp: ApiResponse<CleanUpImageResponse> = await cleanUpImage(projectId, imageId, {});

  if (cleanUpResp && cleanUpResp.meta.code !== 201) {
    throw new ImageCleanUpError();
  }

  return {
    imageId: imageId,
    imageUrl: (cleanUpResp.data as CleanUpImageResponse).image_url,
    cleanUpVersionId: (cleanUpResp.data as CleanUpImageResponse).version_id,
  };
};

export default uploadImage;
