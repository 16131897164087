import { fabric } from 'fabric';
import resetIcon from '../../../assets/reset-icon.svg';
import { CanvasDataTypes, helperSettingsConfig } from '../../../utils';
import { photoTextZoneConfig } from '../../../utils/configs/phototextzone-settings.configs';

const { scalingFactor } = helperSettingsConfig;
const { resetButtonOffset } = photoTextZoneConfig;

/**
 * Generate an Add Text button for photo text zones
 * @param zone - phototext zone into which photo button will be added
 * @param verticalLayout - boolean to determine if the layout is vertical
 * @param useMinIcon - boolean to determine if the min icon should be used
 * @returns promise
 */
export const getPhotoTextZoneResetButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(resetIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-text-button-icon`,
        hoverCursor: 'pointer',
        angle: zone.angle,
        // The (0.75) multiplier is an arbitrary value added to adjust the button size
        scaleX: scalingFactor * 0.75,
        scaleY: scalingFactor * 0.75,
      });
      const btnLeft = zone.getCenterPoint().x;
      const btnTop = zone.getCenterPoint().y - (zone.height as number) / 2 - resetButtonOffset;

      const addResetButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-reset-button`,
        hoverCursor: 'pointer',
        left: btnLeft,
        top: btnTop,
        angle: zone.angle,
        data: {
          linkedZoneName: zone.name,
          type: CanvasDataTypes.PhotoTextZoneResetButton,
        },
        visible: false,
      });
      document.fonts.ready.then(() => {
        resolve(addResetButton);
      });
    });
  });
};
