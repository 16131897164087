import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AddressForm } from '../../global-types/addresses';

export type EditAddressDialogProps = {
  addClass?: string;
  formHandlers: UseFormReturn<AddressForm>;
  isEditAddressVisible: boolean;
  setIsEditAddressVisible: (isEditAddressVisible: boolean) => void;
  updateAddress: () => void;
};

export type EditAddressFormProps = {
  addClass?: string;
  formHandlers: UseFormReturn<AddressForm>;
};

export type FieldName =
  | 'first_name'
  | 'last_name'
  | 'company_name'
  | 'address_line_1'
  | 'address_line_2'
  | 'city'
  | 'state_code'
  | 'zip'
  | 'country_code'
  | 'address_type_code'
  | 'skip_usps_validation'
  | 'send_to'
  | 'envelope'
  | 'isQuickAddress';

export interface FormField {
  typeOfField: string;
  name: FieldName;
  label: string;
  registerOptions: any;
  domId: string;
  isError: boolean;
  helperText?: string;
  addClass: string;
  testId: string;
  required: boolean;
  showDropdown?: boolean | null;
  dropdownOptions?: { value: string; label: string }[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type FormFields = {
  typeOfField: string;
  nestedFields?: FormField[];
  name: FieldName;
  label: string;
  registerOptions: any;
  domId: string;
  isError: boolean;
  helperText?: string;
  addClass: string;
  testId: string;
  required: boolean;
  showDropdown?: boolean | null;
  dropdownOptions: { value: string; label: string }[];
  showCheckbox?: boolean | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export type FormFieldsGeneratorProps = {
  selectedFormFields: FormFields[];
  register: any;
};

export type FormFieldSelected = {
  name: string;
  typeOfField: string;
};

export type FormFieldSelectedList = {
  name?: string;
  typeOfField: string;
  collapse?: boolean;
  nestedFields?: FormFieldSelected[];
};

export enum FieldType {
  TEXTFIELD = 'textfield',
  DROPDOWN = 'dropdown',
  COLLAPSE = 'collapse',
  CHECKBOX = 'checkbox',
}
