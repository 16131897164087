import { fabric } from 'fabric';
import { CanvasDataTypes, getGroupedItemByName, getObjectByName, isPhotoTextZone } from '../utils';
import { getFontName } from './fonts';

/**
 * Returns true if passed object is a photoTextZone button (text or WAM button)
 * @param object a canvas object
 * @returns boolean
 */
export const isPhotoTextZoneButton = (object: fabric.Object): boolean => {
  return (
    object?.data?.type === CanvasDataTypes.PhotoTextZoneTextButton ||
    object?.data?.type === CanvasDataTypes.PhotoTextZoneWAMButton ||
    object?.data?.type === CanvasDataTypes.PhotoTextZoneButton ||
    object?.data?.type === CanvasDataTypes.PhotoTextZoneResetButton
  );
};

/**
 * Toggle the visibility of text and WAM buttons for photo text zones
 *
 * @param displayButtons true | false
 * @param currentCanvas current canvas
 * @param photoTextZone active or selected photoTextZone
 */
export const togglePhotoTextZoneButtons = (
  displayButtons: boolean,
  currentCanvas: fabric.Canvas,
  photoTextZone: fabric.Object | fabric.Group,
  useDynamicButtons = false,
) => {
  if (useDynamicButtons) {
    const photoTextZoneTextButton = getObjectByName(`${photoTextZone.name}-text-button`, currentCanvas);
    const photoTextZoneWAMButton = getObjectByName(`${photoTextZone.name}-wam-button`, currentCanvas);
    photoTextZoneTextButton && photoTextZoneTextButton.set({ visible: displayButtons });
    photoTextZoneWAMButton && photoTextZoneWAMButton.set({ visible: displayButtons });
  } else {
    const zoneButton = getGroupedItemByName(CanvasDataTypes.PhotoTextZoneButton, photoTextZone as fabric.Group);
    zoneButton?.setOptions({ visible: displayButtons });
  }
};

/**
 * Toggle the visibility of Reset button for Type a Message
 * Reset button should be visible when user has typed/is typing a message
 * should be hidden when zone is empty or has an handwritten image
 * @param displayButtons true | false
 * @param currentCanvas current canvas
 * @param photoTextZone active or selected photoTextZone
 */
export const togglePhotoTextZoneResetButton = (
  displayButton: boolean,
  currentCanvas: fabric.Canvas,
  photoTextZone: fabric.Object | fabric.Group,
) => {
  const photoTextZoneResetButton = getObjectByName(`${photoTextZone.name}-reset-button`, currentCanvas);
  photoTextZoneResetButton && photoTextZoneResetButton.set({ visible: displayButton });
};

/**
 * Remove phototext zone buttons based on object's name to clean canvas when saving personalization
 *
 * @param canvas - canvas from which to remove photo text zone buttons
 * @returns void
 */
export const removePhotoTextZoneButtons = (canvas: fabric.Canvas) => {
  const objects = canvas.getObjects();
  for (const object of objects) {
    if (isPhotoTextZoneButton(object)) {
      canvas.remove(object);
    }
  }
};

/**
 * Get phototextzone objects from S&S cards
 * @param canvas fabric canvas
 * @returns array of photoTextZone objects contained in passed canvas
 */
export const getPhotoTextZoneObjects = (canvas: fabric.Canvas, copyObjects = false) => {
  let photoTextZones: fabric.Object[] = [];
  if (canvas) {
    photoTextZones = canvas.getObjects().filter((obj) => isPhotoTextZone(obj));
    if (copyObjects) {
      const zonesCopy: fabric.Object[] = [];
      photoTextZones.forEach((zone) => {
        zonesCopy.push(Object.assign({}, zone));
      });
      return zonesCopy;
    }
  }
  return photoTextZones;
};

/**
 * Get ungrouped text objects that were created when cleaning canvas for /save call
 * @param canvas fabric canvas
 * @returns array of photoTextZone objects contained in passed canvas
 */
export const getUngroupedPhotoTextZoneObjects = (canvas: fabric.Canvas) => {
  let ungroupedText: fabric.Object[] = [];
  if (canvas) {
    ungroupedText = canvas.getObjects().filter((obj) => obj.data?.ungroupedText);
  }
  return ungroupedText;
};

/**
 * Get WAM images added to a phototextzone in S&S cards
 * @param canvas fabric canvas
 * @returns array of WAM image objects from S&S cards
 */
export const getPhotoTextZoneImages = (canvas: fabric.Canvas) => {
  let photoTextZoneImages: fabric.Object[] = [];
  if (canvas) {
    photoTextZoneImages = canvas.getObjects().filter((obj) => obj.data?.isPhotoTextZoneImg);
  }
  return photoTextZoneImages;
};

/**
 * Get phototextzone buttons (WAM and typed-a-message) in S&S cards
 * @param canvas fabric canvas
 * @returns array of WAM and type-a-message button objects from S&S cards
 */
export const getPhotoTextZoneButtons = (canvas: fabric.Canvas) => {
  let photoTextZoneButtons: fabric.Object[] = [];
  if (canvas) {
    photoTextZoneButtons = canvas.getObjects().filter((obj) => isPhotoTextZoneButton(obj));
  }
  return photoTextZoneButtons;
};

/**
 * Finds a photoTextZone by name and selects it as active object in current canvas
 *
 * @param linkedZoneName - name of linkedZone, usually stored in data.linkedZone
 * @param currentCanvas - current active canvas
 *
 * @example
 *
 *   selectLinkedZone(temporaryText.data.linkedZoneName, currentCanvas);
 */
export const selectLinkedZone = (linkedZoneName: string, currentCanvas: fabric.Canvas) => {
  const linkedZone = getObjectByName(linkedZoneName, currentCanvas);
  if (linkedZone) {
    currentCanvas.setActiveObject(linkedZone);
  }
};

/**
 * Clears photo text zones to original state.
 *
 * @param currentCanvas - current canvas from useCurrentCanvas hook
 * @example
 *
 *   clearPhotoTextZone(canvas.current, true);
 */
export const clearPhotoTextZone = (
  currentCanvas: fabric.Canvas | null,
  sasDynamicButtons = false,
  zoneName?: string,
) => {
  if (zoneName && currentCanvas) {
    selectLinkedZone(zoneName, currentCanvas);
  } else if (currentCanvas && currentCanvas.getActiveObject()?.name === CanvasDataTypes.TemporaryTAMInput) {
    // If active object is a temporary text field, select the parent before clearing.
    const activeObject = currentCanvas.getActiveObject();
    if (activeObject?.data.linkedZoneName) {
      selectLinkedZone(activeObject.data.linkedZoneName, currentCanvas);
    }
  }

  if (currentCanvas && currentCanvas.getActiveObject()?.type === 'group') {
    const activeObject = currentCanvas.getActiveObject();
    const photoTextZoneGroup = activeObject as fabric.Group;
    // If Temporary Input is active, remove it from the canvas
    const temporaryInput = getObjectByName('temporary-input', currentCanvas);
    if (temporaryInput) {
      currentCanvas.remove(temporaryInput);
    }
    // Reset textbox, reset text, and turn invisible
    const textbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZoneGroup) as fabric.Textbox;
    if (textbox) {
      textbox.set({
        ...textbox,
        text: '',
        visible: false,
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: 32,
        fontFamily: getFontName(107),
      });
    }
    // restore phototextzone buttons visibility
    togglePhotoTextZoneButtons(true, currentCanvas, photoTextZoneGroup, sasDynamicButtons);

    photoTextZoneGroup.data.hasContent = false;
    photoTextZoneGroup.data.warned = false;
    // Make photoTextZone non-selectable again if new dynamic buttons feature is enabled
    // (zone should only be selectable when it has typed text)
    if (sasDynamicButtons) {
      photoTextZoneGroup.setOptions({ selectable: false });
      togglePhotoTextZoneResetButton(false, currentCanvas, photoTextZoneGroup);
    }
    currentCanvas.discardActiveObject();
    currentCanvas.renderAll();
  }
};
