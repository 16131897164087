import { CardType } from '../../global-types/card';

/**
 * Checks if the current project is a digital greeting.
 * @returns {boolean} Returns true if the project is a digital greeting, otherwise false.
 */
export const getIsDigitalGreeting = (): boolean => {
  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  return projectTypeCode === CardType.DG;
};
