import { DefaultProductOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the user updates the project's name via the pencil icon
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getEditProjectSummaryNameTag = (options: DefaultProductOptions) => {
  const editProjectNameEvent = [
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: 'edit project name',
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2(options.productType);

  const { modal, user } = getModalTag(options.productType, level2, editProjectNameEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
