import API from '@dotcom/api-middleware';
import { ApiResponse } from '../global-types/services';
import { SpellcheckSuggestion } from '../global-types/spellcheck';
import { processResponse } from './middleware';

const PODService = new API();

export const getSpellcheck = async (
  textToCheck: string,
  mockResponseData?: ApiResponse<SpellcheckSuggestion[]>,
): Promise<ApiResponse<SpellcheckSuggestion[]>> => {
  return new Promise((resolve, reject) => {
    if (mockResponseData) {
      const response = mockResponseData;
      resolve(response);
    } else {
      const response = PODService.get('/customization', `/spell-check?text=${textToCheck}`);
      processResponse(response, resolve, reject);
    }
  });
};
