import { DigitalAddress, DigitalOrderRequestBody } from '../../../global-types';
import { getCookie, getDateString } from '../../../utils';
import { GetOrderRequestBodyProps } from '../preview-types';

/**
 * Builds the request body for the digital-orders API
 * @param initializeDataStatate
 * @param cardPreviewImages array
 * @param btlyUrl string
 */
export const getOrderRequestBody = ({ initializedDataState, cardPreviewImages, btlyUrl }: GetOrderRequestBodyProps) => {
  const addressData: DigitalAddress = initializedDataState.addressData.digital as DigitalAddress;
  const productData = initializedDataState.data?.product;
  const previewImage = cardPreviewImages[0].url || '';
  const projectId = initializedDataState.data?.project_id || '';
  const productId = initializedDataState.data?.product_id || '';
  const customer_number = getCookie('sfcno') || '';
  const purchaseDate = getDateString();
  const orderRequestBody: DigitalOrderRequestBody = {
    customer_no: customer_number,
    customer_crown_card_number: '', // Keep blank for now
    enterprise_consumer_id: '', // Keep blank for now
    project_id: projectId,
    project_source_code: 'W',
    purchase_date: purchaseDate,
    sender_first_name: addressData.sender_first_name,
    sender_last_name: addressData.sender_last_name,
    product_name: productData?.product_name || '',
    product_id: productId,
    preview_image_url: previewImage,
    contact_info: addressData.contact_info, // Recipient email address
    recipient_type_code: 'E',
    tiny_url: btlyUrl,
  };

  return { orderRequestBody, purchaseDate, projectId };
};
