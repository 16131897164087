import { fabric } from 'fabric';
import { useAppContext } from '../../context/app-context';
import { storePreviews } from '../../context/app-context/app-context-actions';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { CardType } from '../../global-types';
import { PreviewImage } from '../../global-types/images';
import { useFeatureFlags, useIsSignAndSend } from '../../hooks';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { config } from '../../regional-config';
import { getAssetUrls } from '../../services/image';
import {
  cleanFoldLines,
  cleanPlaceholders,
  cleanUneditedTexts,
  removeEditIconsFromCanvas,
  removePhotoTextZonesFromCanvas,
  TransformedPersonalizationData,
} from '../helper-settings';
import { getPreviewLabel } from './get-preview-label';
import { saveLinkedAssets } from './save-linked-assets';
import { uploadPreviewImage } from './upload-preview-image';

export const useGeneratePreviews = () => {
  const { appDispatch } = useAppContext();
  const {
    cardState: { cardFacesList },
    cardDispatch,
  } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isOneToMany = useIsOneToMany();
  const { CANVAS_JSON_PREVIEW } = useFeatureFlags();
  const isSignAndSend = useIsSignAndSend();
  const { skipAddressView } = config;
  const isFlatCard = cardFacesList.length === 2;

  /**
   * Begin preview generating process by firing one of two functions based on if we want to use printJson
   *
   * @param saveData A list of card faces with printJson, which we use for printJson previews only
   * @returns void
   */
  const generatePreviewImages = async (saveData: TransformedPersonalizationData[]) => {
    CANVAS_JSON_PREVIEW ? generateCanvasJsonPreview() : generatePrintJsonPreview(saveData);
  };

  /**
   * Generates preview images using printJson
   *
   * @param saveData A list of card faces with printJson, which we use for printJson previews only
   * @returns void
   */
  const generatePrintJsonPreview = async (saveData) => {
    if (isSignAndSend) {
      // We generate a preview only for index 2, inside right, for S&S
      saveData = [saveData[2]];
    }
    if (!initializedData) {
      throw new Error('Error generating preview due to missing initialization data');
    }
    let uploadedCount = 0;
    // previewCount: 1 for S&S, 2 for 1-to-many and POD
    const previewCount = isSignAndSend ? 1 : 2;
    const previewImages: PreviewImage[] = [];
    // Generate asset-storage-urls for each face requiring new preview
    const assetStorageUrls = await getAssetUrls(previewCount, 'png');
    saveData.forEach(async (cardFace, index) => {
      const lastFace = index === saveData.length - 1;
      const podOneToOne = !isSignAndSend && !isOneToMany;
      // Don't generate preview for last card face (envelope on 1-to-1 POD or when skipping address view)
      if (lastFace && (skipAddressView || podOneToOne)) return;
      const newCanvas = new fabric.Canvas(null, {
        width: cardFace?.PrintJson?.backgroundImage?.width,
        height: cardFace?.PrintJson?.backgroundImage?.height,
      });

      newCanvas.loadFromJSON(cardFace.PrintJson, async () => {
        const image = newCanvas?.toDataURL({
          format: 'png',
        });
        previewImages[`${index}`] = {
          dataURL: image,
          type: getPreviewLabel(initializedData?.project_type_code as CardType, index, isFlatCard),
        };
        uploadPreviewImage(assetStorageUrls, image, index).then(async () => {
          uploadedCount++;
          if (uploadedCount === previewCount) {
            // Once all previews are uploaded, call linkAssets
            await linkPreviewAssets(previewImages, assetStorageUrls);
            storePreviews(appDispatch, previewImages);
          }
        });
      });
    });
  };

  /**
   * Generates preview images using canvasJson
   *
   * @returns void
   */
  const generateCanvasJsonPreview = async () => {
    // Check if this is canvasJson or printJson and handle both in different functions
    let uploadedCount = 0;
    // previewCount: 1 for S&S, 2 for 1-to-many and POD
    const previewCount = isSignAndSend ? 1 : 2;
    const previewImages: PreviewImage[] = [];
    // faces can support creating previews using either canvases in state or printJson from saveData
    let faces = cardFacesList;
    if (isSignAndSend) {
      // TODO: Bug here, S&S previews aren't showing objects
      faces = [cardFacesList[2]];
    }
    if (!initializedData) {
      throw new Error('Error generating preview due to missing initialization data');
    }
    // Generate asset-storage-urls for each face requiring new preview
    const assetStorageUrls = await getAssetUrls(previewCount, 'png');
    faces.forEach(async (cardFace, index) => {
      let previewImage;
      // Since we're using canvasJson, we can get the canvas from the state
      const myCanvas = cardFace.canvas.current;
      if (myCanvas) {
        previewImage = canvasToDataUrl(myCanvas);
      }
      if (!isSignAndSend && !isOneToMany && index == faces.length - 1) {
        // Don't generate preview for last card face (1-to-1 POD)
        return;
      }
      if (previewImage) {
        previewImages[`${index}`] = {
          dataURL: previewImage,
          type: getPreviewLabel(initializedData?.project_type_code as CardType, index, isFlatCard),
        };
      } else {
        throw new Error('Error: No preview image to store');
      }
      // Use generated asset storage url to upload preview image to S3
      await uploadPreviewImage(assetStorageUrls, previewImage, index);
      uploadedCount++;
      if (uploadedCount === previewCount) {
        await linkPreviewAssets(previewImages, assetStorageUrls);
        storePreviews(appDispatch, previewImages);
      }
    });
  };

  const linkPreviewAssets = async (previewImages, assetStorageUrls) => {
    // All faces are uploaded, so we can save linked assets
    if (isSignAndSend && initializedData) {
      // We need to populate S&S previewImages faces 0, 1 using initializationData since they can't be changed
      // Index 2 will be our generated S&S preview
      const { Faces } = initializedData.variables.template_data;
      previewImages.unshift({
        dataURL: Faces[1].BackgroundUrl,
        type: 'Inside Left',
      });
      previewImages.unshift({
        dataURL: Faces[0].BackgroundUrl,
        type: 'Front',
      });
    }
    if (initializedData) {
      await saveLinkedAssets(assetStorageUrls, initializedData, cardDispatch, appDispatch, isOneToMany, previewImages);
    } else {
      throw new Error('Error: Missing initializedData, cannot save assets');
    }
  };

  /**
   * Genereates dataUrl from canvas, part of canvasJson preview flow
   *
   * @param saveData A list of card faces with printJson, which we use for printJson previews only
   * @returns void
   */
  const canvasToDataUrl = (canvas: fabric.Canvas) => {
    if (canvas) {
      cleanPreviewCanvasJson(canvas);
      const image = canvas.toDataURL({
        format: 'png',
        multiplier: 2,
      });
      return image;
    }
    return '';
  };

  /**
   * Cleans canvasJson prior to generating preview images
   *
   * @param saveData A list of card faces with printJson, which we use for printJson previews only
   * @returns void
   */
  const cleanPreviewCanvasJson = (canvas: fabric.Canvas) => {
    if (canvas) {
      cleanPlaceholders(canvas);
      cleanFoldLines(canvas);
      cleanUneditedTexts(canvas);
      removePhotoTextZonesFromCanvas(canvas);
      removeEditIconsFromCanvas(canvas);
    }
  };

  return {
    generatePreviewImages,
  };
};
