import { fabric } from 'fabric';
import { CardContextState } from '../../../context/card-context';
import { CardEditableArea, CardFaceData } from '../../../global-types';
import { getRegionalConfig } from '../../../regional-config/get-regional-config';
import { getEditableAreaSettings } from '../card-editor-utils';
import {
  getUserZonePhotoButton,
  getUserZoneTextButton,
  getUserZoneWamButton,
  getUserZoneInstructions,
} from './get-editable-area-buttons';
import { scaleEditableAreaButton } from './scale-editable-area-buttons';

/**
 * Adds editable user zone area to card face
 *
 * @param face - card face to add user zone to
 * @param addButtonsOnly - used to re-populate buttons in user zone after final object is deleted
 * @returns void
 */
export const addEditableAreas = (
  face: CardFaceData,
  cardState: CardContextState,
  addButtonsOnly?: boolean,
  isOneToMany?: boolean,
) => {
  const { userZones, features } = getRegionalConfig();
  const shouldShowPodWam = (face.type !== 'front' && features?.isPodWamEnabledOnlyInside) || features?.isPodWamEnabled;
  const canvas = face?.canvas?.current;
  if (!canvas) {
    return;
  }
  const { editableAreas } = face;
  if (!editableAreas || editableAreas.length === 0) {
    return;
  }
  let hasAreaObjects = false;

  face.originalCanvasJson?.objects.forEach((obj: fabric.Object) => {
    hasAreaObjects = !!obj.clipPath;
  });

  if (canvas) {
    const objects = canvas.getObjects();
    editableAreas.forEach((area: CardEditableArea, index: number) => {
      const canvasArea = new fabric.Rect(getEditableAreaSettings(area, index, cardState));
      const editableAreaObject = objects?.find((el) => el.data.type === 'editable-area');
      let zone: fabric.Object;
      if (!editableAreaObject) {
        canvas.add(canvasArea);
        canvasArea.moveTo(0);
        zone = canvasArea;
      } else {
        // Ensure editableArea is at bottom of object stack
        editableAreaObject.moveTo(0);
        zone = editableAreaObject;
      }
      if (!hasAreaObjects || addButtonsOnly) {
        getUserZonePhotoButton(zone, isOneToMany || false).then((photoButton) => {
          const scaledButton = scaleEditableAreaButton(photoButton, zone);
          canvas.add(scaledButton);
        });
        getUserZoneTextButton(zone, isOneToMany || false).then((textButton) => {
          const scaledButton = scaleEditableAreaButton(textButton, zone);
          canvas.add(scaledButton);
        });
        // Display user zone POD button based on config
        // Do not display POD WAM button in user zone if card is 1:many
        shouldShowPodWam &&
          !isOneToMany &&
          getUserZoneWamButton(zone).then((wamButton) => {
            const scaledButton = scaleEditableAreaButton(wamButton, zone);
            canvas.add(scaledButton);
          });
        // Display user zone instructions based on config
        userZones.areInstructionsEnabled &&
          getUserZoneInstructions(zone).then((instructions) => {
            canvas.add(instructions);
          });
      }
    });
  }
};
