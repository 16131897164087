import { GetProjectAsset } from '../../global-types';
import { AssetTypes } from '../../global-types/initialization-data';
import { getProjectDetails } from '../../services';

export const loadProjectDetails = (projectId): Promise<any> => {
  return new Promise((resolve, reject) => {
    getProjectDetails(projectId)
      .then((res) => {
        const projectAssets = res?.data?.assets;

        if (projectAssets !== undefined) {
          const animationPanels = projectAssets.reduce<GetProjectAsset>((animationPreviewAssets, asset) => {
            if (asset.asset_type_code === AssetTypes.FRONT_PANEL || asset.asset_type_code === AssetTypes.INSIDE_PANEL) {
              //If we don't have one yet, or if we have multiple and the current one is newer
              if (
                animationPreviewAssets[asset.asset_type_code] === undefined ||
                Date.parse(asset.created_at) > Date.parse(animationPreviewAssets[asset.asset_type_code].created_at)
              ) {
                animationPreviewAssets[asset.asset_type_code] = asset;
              }
            }

            return animationPreviewAssets;
          }, {});
          resolve(animationPanels);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
