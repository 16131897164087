import React from 'react';
import { useFeatureFlags } from '../../../hooks';
import { CardProps } from '../select-envelope-types';
import styles from '../select-envelope.module.scss';
import { EnvelopeOptionsForm } from './select-envelope-card/envelope-options-form';
import { Header } from './select-envelope-card/header';
import { NavigationButtons } from './select-envelope-card/navigation-buttons';

export const SelectEnvelopeCard = ({ register, handleBack, handleContinue }: CardProps) => {
  const { IS_ENVELOPE_PRINTING_OPTIONS_ENABLED } = useFeatureFlags();

  return IS_ENVELOPE_PRINTING_OPTIONS_ENABLED ? (
    <section className={styles['envelope-card']}>
      <Header />

      <div className={styles['envelope-card-content']}>
        <EnvelopeOptionsForm register={register} />
      </div>

      <footer className={styles['envelope-card-footer']}>
        <NavigationButtons onClickBack={handleBack} onClickContinue={handleContinue} />
      </footer>
    </section>
  ) : (
    <></>
  );
};
