import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useAppContext } from '../../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../../context/card-context';
import { setSlideCardIndex } from '../../../context/card-context/card-context-actions';
import { useCropContext } from '../../../context/crop-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useActiveCanvas } from '../../../hooks';
import { useIsOneToMany } from '../../../hooks/use-is-one-to-many';
import { getCardFaceLabel, isLoggedIn } from '../../../utils';
import { useHandleProjectDone } from './use-handle-project-done';
import { useOnCardFaceIndexChange } from './useOnCardFaceIndexChange';
import { useUserZonesValidation } from './useUserZonesValidation';

export const useEditorNavigation = () => {
  const [beforeEditingFaceJson, setBeforeEditingFaceJson] = useState<string | null>(null);
  const { cardState, cardDispatch } = useCardContext();
  const {
    appState: { cardFacesLoaded, skipSpellcheckValidation },
  } = useAppContext();
  const { t } = useTranslation();
  const isOneToMany = useIsOneToMany();
  const activeCanvas = useActiveCanvas();
  const { isSavingCroppedImage } = useCropContext();
  const { onCardFaceIndexChange } = useOnCardFaceIndexChange();
  const { pageNavigationButtonClicked } = useAnalyticsContext();
  const { skipValidation, validateUserZones, uneditedZones } = useUserZonesValidation();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const ignoreSpellcheckValidation = useRef(false);

  const slideIndex = cardState.slideCardIndex;

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );

  useEffect(() => {
    ignoreSpellcheckValidation.current = skipSpellcheckValidation;
  }, [skipSpellcheckValidation]);

  const handleIndexChange = useCallback(
    (newIndex: number, newActiveCardIndex?: number) => {
      const indexChangeOptions = {
        activeCanvas,
        editableFaces,
        slideIndex,
        beforeEditingFaceJson,
        isLoggedIn: isLoggedIn(),
      };
      onCardFaceIndexChange(indexChangeOptions);
      setActiveCardIndex(cardDispatch, newActiveCardIndex || newIndex);
      setSlideCardIndex(cardDispatch, newIndex);
    },
    [cardDispatch, editableFaces, slideIndex, beforeEditingFaceJson],
  );

  const handleProjectDone = useHandleProjectDone(
    skipValidation,
    validateUserZones,
    uneditedZones,
    isOneToMany,
    handleIndexChange,
    ignoreSpellcheckValidation,
  );

  const isOnLastFace = slideIndex === editableFaces.length - 1;

  const handleIncrementSlideIndex = () => {
    pageNavigationButtonClicked.current = 'Edit Button';
    const newActiveCardIndex = editableFaces[slideIndex + 1].faceNumber - 1;
    handleIndexChange(slideIndex + 1, newActiveCardIndex);
  };

  const getNextButtonText = () => {
    const nextFace = editableFaces[slideIndex + 1];

    if (nextFace) {
      return t('editorView.nextFace', { face: getCardFaceLabel(nextFace.type) });
    }

    return t('editorView.lastFace');
  };

  const buttonProps = {
    click: isOnLastFace ? handleProjectDone : handleIncrementSlideIndex,
    children: getNextButtonText(),
    disabled:
      isOnLastFace && initializedData?.project_type_code === 'S' ? !validateUserZones() || isSavingCroppedImage : false,
  };

  useEffect(() => {
    // If cardFaces haven't loaded yet, exit the code
    if (editableFaces.length <= 0) {
      return;
    }

    // Check if frontFace canvas has loaded
    const isFrontFaceLoaded = editableFaces[0]?.canvas !== undefined;

    if (!beforeEditingFaceJson && isFrontFaceLoaded && cardFacesLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[0].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
    if (beforeEditingFaceJson && isFrontFaceLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[`${slideIndex}`].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
  }, [slideIndex, editableFaces, cardFacesLoaded]);

  return { handleIndexChange, buttonProps, handleProjectDone };
};
