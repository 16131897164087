import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { FooterContentProps } from '../rotate-drawer-types';
import styles from '../rotate-drawer.module.scss';

export const FooterContent = ({ onDrawerCancel, onDrawerApply }: FooterContentProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-wrapper']}>
      <Button
        testId="rotate-drawer--cancel"
        addClass={classNames(styles['footer-buttons'])}
        click={onDrawerCancel}
        mode={ButtonModes.Secondary}
      >
        {t('footerContent.cancel')}
      </Button>
      <Button testId="rotate-drawer--apply" addClass={classNames(styles['footer-buttons'])} click={onDrawerApply}>
        {t('footerContent.apply')}
      </Button>
    </div>
  );
};
