import { Dispatch } from 'react';
import { AppAction } from '../../context/app-context';
import { getSpellcheck } from '../../services';
import { highlightTextMisspellings } from './highlight-text-mispellings';
import { removeSuggestions } from './remove-suggestions';
import { setSuggestions } from './set-suggestions';

type HandleSpellcheckSuggestionsParams = {
  textObject: fabric.Textbox;
  shouldHighlightErrors: boolean;
  setSkipSpellcheckValidation: (dispatch: Dispatch<AppAction>, value: boolean) => void;
  appDispatch: React.Dispatch<any>;
};

/**
 * Function to set spellcheck suggestions in text object and highlight if user has already selected to check spelling
 * on the Spellcheck Dialog
 * @param textObject fabric.Textbox - text object to spellcheck
 * @param shouldHighlightErrors boolean - flag to highlight errors
 * @param setSkipSpellcheckValidation (dispatch: Dispatch<AppAction>, value: boolean) => void - callback function to set skip spellcheck validation
 * @param appDispatch React.Dispatch<any> - app dispatch function
 * @returns void
 */
export const handleSpellcheckSuggestions = async ({
  textObject,
  shouldHighlightErrors,
  setSkipSpellcheckValidation,
  appDispatch,
}: HandleSpellcheckSuggestionsParams): Promise<void> => {
  // clean text's previous suggestions and highlights
  removeSuggestions(textObject);
  await textObject.removeStyle('textBackgroundColor');

  if (textObject?.text) {
    try {
      const spellcheckResponse = await getSpellcheck(textObject.text);
      if (spellcheckResponse.data) {
        setSuggestions(textObject, spellcheckResponse.data);
        setSkipSpellcheckValidation(appDispatch, false);

        if (shouldHighlightErrors) {
          highlightTextMisspellings(textObject);

          const canvas = textObject.canvas;
          canvas?.requestRenderAll();
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Error getting spellcheck', spellcheckResponse);
        setSuggestions(textObject, null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting spellcheck', error);
      setSuggestions(textObject, null);
    }
  }
};
