import React from 'react';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import styles from '../delete-images-confirmation-dialog.module.scss';
import { DeleteImagesDialogMessageProps } from '../delete-images-dialog-types';

export const Message = ({
  contentTitle = '',
  contentText = '',
}: DeleteImagesDialogMessageProps): React.ReactElement => {
  return (
    <div className={styles.messageContainer}>
      <Icon name={IconNames.TrashBold} size={24} color={BrandColors.DarkGray} />
      <Typography variant={TypographyVariants.Headline4} color={BrandColors.DarkGray}>
        {contentTitle}
      </Typography>
      <Typography variant={TypographyVariants.Body}>{contentText}</Typography>
    </div>
  );
};
