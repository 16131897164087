import { CustomFabricImage } from '../../global-types';
import { isBase64Image } from './is-base64-image';

/**
 * Formats an fabric image src by removing the query string and replacing base64 image with croppedURL
 * @param canvasImageObject
 */
export const formatFabricImageSource = (canvasImageObject: CustomFabricImage): CustomFabricImage | null => {
  // If the image has been cropped, substitute the base64 url with the croppedURL
  const imageSrc = canvasImageObject?.data?.croppedUrl ? canvasImageObject.data.croppedUrl : canvasImageObject.src;
  if (isBase64Image(imageSrc)) {
    throw new Error('Unsupported base64 image found while scaling json.');
  }
  canvasImageObject.src = imageSrc?.split('?w=')[0];

  return canvasImageObject;
};
