import { CardContextState } from './card-context-types';

export const initState: CardContextState = {
  bPost: false,
  activeCardIndex: 0,
  slideCardIndex: 0,
  cardTemplateId: null,
  cardTemplateName: '',
  cardFacesList: [],
  cardFormat: '',
  cardCanvas: null,
  cardType: 'CARD',
  changesSinceLastSave: 0,
  companyId: 3,
  cultureId: 10,
  currentZone: {},
  currentArea: null,
  currentAreaNotificationTriggered: false,
  defaultOptions: {},
  editableAreas: [],
  fontPaths: [],
  imageIsLoading: false,
  isChocolate: false,
  isExporting: false,
  isPhotozoneActive: false,
  isPostcard: false,
  isPostcardFromPhoto: false,
  isWooden: false,
  loginUrl: '',
  lockedFront: false,
  lockedBack: false,
  // photoZones: [],
  placeholderSettingsA: {},
  placeholderSettingsB: {},
  projectId: '',
  totalZones: 0,
  tempText: '', // used for the dimmer
  textSettings: {},
  textPadding: 20,
  textDefault: [],

  // swipe data
  startPoint: {
    x: null,
    y: null,
  },

  endPoint: {
    x: null,
    y: null,
  },

  leftCameraEdge: null,
  rightCameraEdge: null,
  currentState: '',
  states: {
    create: {},
    edit: {},
    address: {},
  },
  coverPreviewUrl: '',
  selectedPhotoZone: {
    id: null,
    uploadedImage: null,
  },
  thumbnailUrl: '',
};
