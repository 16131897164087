import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import styles from '../delete-images-confirmation-dialog.module.scss';
import { DeleteImagesDialogButtonsProps } from '../delete-images-dialog-types';

export const Buttons = ({
  onConfirm,
  onClose,
  actionButtonText = 'Delete',
  cancelButtonText = 'Back',
}: DeleteImagesDialogButtonsProps): React.ReactElement => {
  const deleteButton = (
    <Button click={() => onConfirm()} mode={ButtonModes.Primary} testId={'delete-confirmation-action-button'}>
      {actionButtonText}
    </Button>
  );

  const backButton = (
    <Button mode={ButtonModes.Secondary} click={() => onClose()} testId={'delete-confirmation-cancel-button'}>
      {cancelButtonText}
    </Button>
  );

  return (
    <div className={styles.buttonsContainer}>
      {deleteButton}
      {backButton}
    </div>
  );
};
