export type SpellcheckSuggestion = {
  word: string;
  suggestion: string[];
};

export type SpellcheckErrors = {
  [key: string]: fabric.Textbox[];
};

export type MappedSpellcheckSuggestion = {
  suggestion: SpellcheckSuggestion;
  textObject: fabric.Textbox;
  cardFaceId: string;
};

/**
 * Enum to track user actions in spellcheck dialog for analytics
 * and to prevent duplicate calls when Dialog onClose event is triggered.
 */
export enum SpellcheckActions {
  /** Default state for spellcheck dialog. */
  None = 'none',
  /** Used to signal that spellcheck dialog has already been closed. Used to prevent duplicated calls. */
  Closed = 'closed',
  /** User clicked 'Check spelling' option in spellcheck dialog */
  Fix = 'fix',
  /** User clicked 'Ignore All' option in spellcheck dialog */
  Ignore = 'ignore',
  /** User dismissed spellcheck dialog by clicking outside of dialog or pressing Esc key*/
  Cancel = 'cancel',
  /** User dismissed spellcheck dialog by clicking on 'X' button */
  CancelX = 'cancel-x',
}
