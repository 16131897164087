import { useInitializationDataContext } from '../../../../context/data-context';
import { FabricTextBox } from '../../../../global-types';
import { useActiveCanvas, useIsSignAndSend } from '../../../../hooks';
import { CanvasDataTypes } from '../../../../utils';

export const useFontDrawerFlags = () => {
  const canvas = useActiveCanvas();
  const {
    initializedDataState: { isUK },
  } = useInitializationDataContext();

  const activeObject = canvas?.current?.getActiveObject() as FabricTextBox;

  const shouldShowColorEditor =
    activeObject.CanEditFontColor ||
    activeObject.data.type === CanvasDataTypes.UserText ||
    (isUK && activeObject.data.type === CanvasDataTypes.Placeholder);

  const shouldShowPositionTabEditor = !useIsSignAndSend();

  return { shouldShowColorEditor, shouldShowPositionTabEditor };
};
