import React from 'react';
import { Pill } from '@hallmark/web.core.buttons.pill';
import { AddressBoxStepperProps } from '../address-box-types';
import styles from '../address-box.module.scss';

export const AddressBoxStepper = ({ activeIndex, addressesToValidate }: AddressBoxStepperProps) => {
  const steps = `${activeIndex + 1}/${addressesToValidate}`;

  return <Pill title={steps} addClass={styles['stepper-label']} />;
};
