import React from 'react';
import { DialogIconNames } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { DialogContentType, DialogNoCrNoH } from '@hallmark/web.page-components.no-cr-no-h-dialog';
import { useHplusContents, useLoyaltyContents } from './hooks';
import { NoCrNoHDialogProps } from './no-cr-no-h-dialog-types';
import styles from './no-cr-no-h-dialog.module.scss';

export const NoCrNoH = ({ isOpen, setIsOpen, isLoyaltyCtaVisible }: NoCrNoHDialogProps) => {
  const { hPlusContainerContent } = useHplusContents();
  const { loyaltyContainerContents } = useLoyaltyContents();

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <DialogNoCrNoH
        isOpen={isOpen}
        onClose={handleCloseDialog}
        id="no-cr-no-h-dialog"
        accentIcon={DialogIconNames.PersonPerson}
        accentIconColor={DialogBrandColors.White}
        hasGrayBackground={true}
        closeButtonId="no-cr-no-h-dialog-close-button"
        hideOverlay={false}
        type={DialogContentType.FeatureModal}
        disableDialogScroll={true}
        testId="no-cr-no-h-dialog"
        firstContainer={isLoyaltyCtaVisible ? loyaltyContainerContents : null}
        secondContainer={hPlusContainerContent}
        addDialogClass={styles['dialog-no-cr-no-h']}
      />
    </>
  );
};
