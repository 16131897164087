import { fabric } from 'fabric';
import { CanvasDataTypes } from '../../../utils';

/** Original zoom status and whether the original zoom has been applied. */
let originalZoom: number | undefined;
let isZoomApplied = false;

export const registerZoomEvent = (canvas: fabric.Canvas, isMobile: boolean, DISPLAY_ZOOM_DEV: boolean) => {
  if (isMobile && DISPLAY_ZOOM_DEV) {
    const handleZoomDownWrapper = () => onZoomText(canvas);
    canvas.on('mouse:down', handleZoomDownWrapper);
  }
};

export const onZoomText = (canvas: fabric.Canvas) => {
  const objActiveCanvas = canvas.getActiveObject();

  /** Handles the case of objects of type 'textbox' or 'userText'. */
  if (
    objActiveCanvas &&
    (objActiveCanvas.data?.type === CanvasDataTypes.EditableText ||
      objActiveCanvas.data?.type === CanvasDataTypes.UserText)
  ) {
    handleZoomIn(canvas, objActiveCanvas, false);
  } else {
    resetZoom(canvas);
  }
};

/** * Handles zooming in on the active object. */
export const handleZoomIn = (
  canvas: fabric.Canvas,
  objActiveCanvas: fabric.Object,
  isZoomEnabled: boolean,
  isSignAndSend = false,
) => {
  if (!isZoomEnabled) return;

  const { left, top, aCoords } = objActiveCanvas;
  const { width: viewPortWidth, height: viewPortHeight } = canvas;

  if (!left || !top || !viewPortWidth || !viewPortHeight || !aCoords) return;

  const { tl, tr } = aCoords;
  const isLandscape = viewPortWidth > viewPortHeight;
  const textBoxWidth = tr.x - tl.x;

  saveOriginalZoom(canvas);

  const zoomCenterPoint = calculateZoomCenterPoint(tl, isLandscape, textBoxWidth, viewPortWidth);
  const zoomFactor = calculateZoomFactor(isLandscape, textBoxWidth);

  applyNewZoomLevel(canvas, zoomCenterPoint, zoomFactor, isSignAndSend);
};

/** * Calculates the zoom center point based on the orientation and box width. */
const calculateZoomCenterPoint = (
  tl: fabric.Point,
  isLandscape: boolean,
  textBoxWidth: number,
  viewPortWidth: number,
): fabric.Point => {
  if (isLandscape) {
    return textBoxWidth > 700 ? new fabric.Point(tl.x + 70, tl.y) : new fabric.Point(tl.x - 250, tl.y - 300);
  } else {
    return viewPortWidth <= 250 ? new fabric.Point(tl.x, tl.y) : new fabric.Point(tl.x + 80, tl.y);
  }
};

/** * Calculates the zoom factor based on the orientation and box width. */
const calculateZoomFactor = (isLandscape: boolean, textBoxWidth: number): number => {
  if (isLandscape) {
    return textBoxWidth > 700 ? 1.1 : 1.5;
  } else {
    return 1.1;
  }
};

/** Save the original zoom state if not already done */
export const saveOriginalZoom = (canvas: fabric.Canvas): void => {
  if (typeof originalZoom === 'undefined') {
    originalZoom = canvas.getZoom();
  }
};

/** Apply the new zoom level if the original zoom has not been applied */
export const applyNewZoomLevel = (
  canvas: fabric.Canvas,
  zoomCenterPoint: fabric.Point,
  newZoomFactor: number,
  isSignAndSend = false,
): void => {
  if (!isZoomApplied) {
    const currentZoom = canvas.getZoom();

    const newZoom = currentZoom * (isSignAndSend ? newZoomFactor * 0.9 : newZoomFactor);

    canvas.zoomToPoint(zoomCenterPoint, newZoom);
    canvas.requestRenderAll();
    isZoomApplied = true;
  }
};

/** This function resets the zoom each time you click outside the text and leaves it as default for mobiles.  */
export const resetZoom = (canvas: fabric.Canvas) => {
  /** If the original zoom was already applied, resets the zoom state. */
  if (isZoomApplied) {
    /** Restore the canvas view */
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    typeof originalZoom === 'number' ? canvas.setZoom(originalZoom) : undefined;

    /** Resets the state variables */
    originalZoom = undefined;
    isZoomApplied = false;
  }
};
