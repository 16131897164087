import { isMobile } from 'react-device-detect';
import { fabric } from 'fabric';
import { handleZoomIn, resetZoom } from '../../components/card-face/utils/zoom-mobile-text';
import { FabricTextBox } from '../../global-types';
import { CanvasDataTypes, getGroupedItemByName } from '../canvas-utils';
import { enforceMaxLength } from '../enforce-max-length';
import { togglePhotoTextZoneButtons, togglePhotoTextZoneResetButton } from '../photo-text-zone-utils';

// import { getSpellcheckSuggestions, highlightMisspelledWords, setSuggestions } from '../spellcheck';

/**
 * Creates a temporary input and then adds text to active photoTextZone on deselect.
 *
 * @param currentCanvas - Current fabric canvas.
 * @param zone - Optional preselected zone
 * @param sasDynamicButtons - Boolean for dynamic S&S buttons feature flag
 * @example
 *
 *   handleTemporaryTextEditor(canvas.current, appState, cardState, cardDispatch);
 */
export const handleTemporaryTextEditor = (
  currentCanvas: fabric.Canvas,
  isMobileZoomEnabled: boolean,
  zone?: fabric.Group | null,
  sasDynamicButtons = false,
) => {
  // Get Current Zone, text item
  const photoTextZone = (zone || currentCanvas.getActiveObject()) as fabric.Group & FabricTextBox;

  const originalText = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone) as fabric.Textbox;
  if (currentCanvas && photoTextZone && originalText) {
    const { top, left, width, height } = photoTextZone;

    // Initialize temporary input over TAM photoZone.  Populate with original text.
    const temporaryInput = new fabric.Textbox(
      originalText.text as string,
      {
        textAlign: originalText.textAlign,
        fontSize: originalText.fontSize,
        fill: originalText.fill,
        fontWeight: 'normal',
        top: Math.ceil((top as number) - (height as number) / 2),
        isWrapping: true,
        left: Math.ceil((left as number) - (width as number) / 2),
        fontFamily: originalText.fontFamily,
        width,
        dirty: true,
        deltaY: 0,
        height,
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        clipPath: originalText.clipPath,
        data: {
          testId: 'temporary-text',
          linkedZoneName: photoTextZone.name,
          ID: photoTextZone.ID,
        },
        name: CanvasDataTypes.TemporaryTAMInput, //name can be used to prevent opening text drawer
        CanResizeTextArea: photoTextZone.CanResizeTextArea,
        CanEditFontSize: photoTextZone.CanEditFontSize,
        FontAutoSize: photoTextZone.FontAutoSize,
        CanEditFontColor: photoTextZone.CanEditFontColor,
      } as FabricTextBox,
    );

    // Hide original textbox and add Temporary text box to canvas.  Then, select the input and enter edit mode.
    originalText.set({ visible: false });
    currentCanvas.add(temporaryInput);

    currentCanvas.setActiveObject(temporaryInput);
    //enter editing on temporary input and set text selection
    temporaryInput.enterEditing();
    temporaryInput.selectAll();

    /**
     * Event handler for zooming on mobile devices
     */
    if (isMobile) {
      const objActiveCanvas = currentCanvas.getActiveObject();
      if (!objActiveCanvas) return;

      handleZoomIn(currentCanvas, objActiveCanvas, isMobileZoomEnabled, true);
    }

    togglePhotoTextZoneButtons(false, currentCanvas, photoTextZone, sasDynamicButtons);
    togglePhotoTextZoneResetButton(true, currentCanvas, photoTextZone);

    //handle needed actions after user exits editing the message
    temporaryInput.on('editing:exited', () => {
      if (isMobile) resetZoom(currentCanvas);
      //update the original photoTextZone's textbox with the newly added text and make it visible
      if (temporaryInput.text === '') {
        originalText.set({ text: temporaryInput.text });
        photoTextZone.data.hasContent = false;
        togglePhotoTextZoneButtons(true, currentCanvas, photoTextZone, sasDynamicButtons);
        togglePhotoTextZoneResetButton(false, currentCanvas, photoTextZone);
      } else if (temporaryInput.text) {
        originalText.set({
          textAlign: temporaryInput.textAlign,
          fontSize: temporaryInput.fontSize,
          fontFamily: temporaryInput.fontFamily,
          text: enforceMaxLength(temporaryInput.text, 3000),
          visible: true,
          height: temporaryInput.height,
          width: temporaryInput.width,
          fill: temporaryInput.fill,
        });

        // TODO: Implement spellcheck for S&S
        // Add isSpellcheck boolean param to handleTemporaryTextEditor func, with false as default value - isSpellcheck = false
        // Keep in mind that for S&S we'll need to also highlight the errors in temporary text when it is created
        // TemporaryInput.on('editing...') will need to be async = temporaryInput.on('editing:exited', async () => {
        /*
        if (isSpellcheckEnabled) {
          const spellcheckSuggestions = await getSpellcheckSuggestions(originalText.text as string);

          setSuggestions(originalText, spellcheckSuggestions);
          highlightMispelledWords(originalText,spellcheckSuggestions);
        }
        */

        photoTextZone.data.hasContent = true;
        photoTextZone.setOptions({ selectable: true });
        originalText.fire('changed');
      }

      //remove the temporary textbox
      currentCanvas.remove(temporaryInput);
      //bring back focus to photoTextZone
      currentCanvas.setActiveObject(photoTextZone);
      currentCanvas.renderAll();
    });
  }
};
