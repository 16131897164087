import { CustomFabricImage } from '../../global-types';
import { helperSettingsConfig } from '../configs/helper-settings.configs';
import { calculateImagePpi } from './calculate-image-ppi';

/**
 * Validates if the PPI (Pixels Per Inch) of the image added to the canvas is greater than or equal to 150.
 * @returns {boolean} - Returns true if the PPI is greater than or equal to 150, otherwise returns false.
 * @throws {Error} - Throws an error if the PPI cannot be calculated.
 */
export const validateImagePpi = (addedImage: CustomFabricImage): boolean => {
  const { minimumPpiThreshold } = helperSettingsConfig;
  const imagePpi = calculateImagePpi(addedImage);

  // If PPI can't be calculated throw an Error
  if (imagePpi === null) throw new Error('Unable to calculate PPI');

  return imagePpi >= minimumPpiThreshold;
};
