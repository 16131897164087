import { CardFaceData } from '../../global-types';
import { highlightTextMisspellings } from './highlight-text-mispellings';

/**
 * Highlights all misspelled words in a text object
 * @param textZone
 */
export const highlightAllMisspelledWords = (textZones: fabric.Text[], cardFaces: CardFaceData[]) => {
  if (!textZones || textZones.length < 1) {
    return;
  }

  textZones.forEach((textZone) => {
    const currentCardFaceId = textZone.data?.cardFaceId || 0;
    const cardFace = cardFaces[`${currentCardFaceId}`];

    if (!cardFace) {
      // eslint-disable-next-line no-console
      console.error(`Card face not found for text zone: ${textZone}`);
      return;
    }

    const canvas = cardFace.canvas.current as fabric.Canvas;

    highlightTextMisspellings(textZone);

    // check that canvas object is valid before calling requestRenderAll
    // if a canvas object isn't found, we just continue, since the canvas here
    // is used only to ensure the highlight is rerendered, but the highlight itself is still applied
    if (canvas) {
      canvas.requestRenderAll();
    }
  });
};
