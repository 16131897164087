import { CardFaceData, SpellcheckErrors } from '../../global-types';
import { highlightAllMisspelledWords } from './highlight-all-misspelled-words';

/**
 * Function to format texts with errors and highlight all misspelled words in the text objects.
 * @param textsWithErrors SpellcheckErrors | fabric.Textbox[] - mapped object or array of text objects with spelling suggestions
 * @param cardFacesList CardFaceData[] - list of card faces
 */
export const handleHighlightErrors = (
  textsWithErrors: SpellcheckErrors | fabric.Textbox[],
  cardFacesList: CardFaceData[],
): void => {
  // Flatten the textsWithErrors object into an array of text objects
  if (!Array.isArray(textsWithErrors)) {
    textsWithErrors = Object.values(textsWithErrors).flat();
  }

  highlightAllMisspelledWords(textsWithErrors, cardFacesList);
};
