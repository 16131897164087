import { AppContextState } from '../../app-context';
import { InitializationDataContextState } from '../../data-context';

export const getDefaultProductOptions = (
  initializedDataState: InitializationDataContextState,
  appState: AppContextState,
) => ({
  productName: initializedDataState.data?.product?.product_name ?? '',
  productID: initializedDataState.data?.product_id ?? '',
  productType: initializedDataState.data?.project_type_code ?? '',
  projectID: initializedDataState.data?.project_id ?? '',
  productQuantity: appState.productQuantity ?? '',
});
