/**
 * Check if the image is base64
 * @param src - image source
 * @returns boolean
 */
export const isBase64Image = (src: string): boolean => {
  if (!src) {
    return false;
  }

  return src.startsWith('data:image');
};
