import { FabricObject } from '../../../../global-types';
import { CanvasDataTypes } from '../../../../utils';

export const isUserZoneEmpty = (objects: FabricObject[]): boolean => {
  for (const object of objects) {
    if (
      object.data?.type?.includes(CanvasDataTypes.UserText) ||
      object.data?.type?.includes(CanvasDataTypes.UserImage) ||
      object.data?.type?.includes('pod-handwriting-zone')
    ) {
      return false;
    }
  }
  return true;
};
