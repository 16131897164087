import { UseFormRegister } from 'react-hook-form';
import { EnvelopeType } from '../../global-types/envelope';

export const ENVELOPE_OPTION = 'envelopeOption';

type EnvelopeOptionsProps = {
  register: UseFormRegister<EnvelopeSelectionForm>;
};

type EnvelopeSelectionForm = {
  envelopeOption: EnvelopeType;
};

type CardProps = {
  register: UseFormRegister<EnvelopeSelectionForm>;
  handleBack: () => void;
  handleContinue: () => void;
};

export type { EnvelopeOptionsProps, EnvelopeSelectionForm, CardProps };
