/**
 * Returns accountId from cookies. If on dev/stage environment,
 * it will clean up any duplicate account cookies from prod domain.
 * @returns {string} accountId
 */
export const getAccountIdFromCookie = (): string => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;

  if (currentEnv === 'prod') {
    const accountIds = document.cookie
      .split('; ')
      .filter((cookie) => cookie.split('=')[0] == 'accountId' && cookie.split('=')[1]);

    return accountIds[0]?.split('=')[1] || '';
  }

  let accountIdCookies = document.cookie
    .split('; ')
    .filter((cookie) => cookie.split('=')[0] == 'accountId' && cookie.split('=')[1]);
  // This code will set cookies for .hallmark.com domain to an empty value
  if (accountIdCookies.length > 1) {
    // eslint-disable-next-line no-console
    console.warn('Too many accountId cookies. Attempting to clear them.');

    const expirePastDate = 'expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    document.cookie = 'accountId=; Domain=.hallmark.com; Path=/; Secure=true;' + expirePastDate;
    document.cookie = 'accountType=; Domain=.hallmark.com; Path=/; Secure=true;' + expirePastDate;
    document.cookie = 'accessToken=; Domain=.hallmark.com; Path=/; Secure=true;' + expirePastDate;
    document.cookie = 'name=; Domain=.hallmark.com; Path=/; Secure=true;' + expirePastDate;

    // Now document.cookie will have one accountId cookie for dev/stage, and the prod accountId cookie will have a blank value, which gets excluded.
    accountIdCookies = document.cookie
      .split('; ')
      .filter((cookie) => cookie.split('=')[0] == 'accountId' && cookie.split('=')[1]);
  }
  // After duplicated cookies are cleaned, we validate that we only have one cookie for accountId
  // If there are still 2 cookies for accountId, we throw an error since every request would fail
  if (accountIdCookies.length > 1) {
    throw new Error('Unable to clean up cookies.');
  } else {
    return accountIdCookies[0]?.split('=')[1] || ''; // Return the value of the only accountId cookie
  }
};
