import { fabric } from 'fabric';
import handwritingIcon from '../../../assets/phototextzone-add-handwriting.svg';
import minHandwritingIcon from '../../../assets/user-zone-add-wam-icon-us.svg';
import { CanvasDataTypes, helperSettingsConfig } from '../../../utils';
import { photoTextZoneConfig } from '../../../utils/configs/phototextzone-settings.configs';

const { scalingFactor } = helperSettingsConfig;
const { buttonOffsetX, buttonOffsetY } = photoTextZoneConfig;

/**
 * Generate a Write a Message (WAM) button for photoText zones
 * @param zone - zone into which WAM button will be added
 * @param verticalLayout - boolean to determine if the layout is vertical
 * @param useMinIcon - boolean to determine if the min icon should be used
 * @returns promise
 */
export const getPhotoTextZoneWAMButton = (
  zone: fabric.Object,
  verticalLayout = false,
  useMinIcon = false,
): Promise<fabric.Group> => {
  const wamIcon = useMinIcon ? minHandwritingIcon : handwritingIcon;
  return new Promise((resolve) => {
    fabric.Image.fromURL(wamIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-wam-button-icon`,
        hoverCursor: 'pointer',
        angle: zone.angle,
        // The (0.85) multiplier is an arbitrary value added to better adjust the buttons to the zones
        scaleX: scalingFactor * 0.85,
        scaleY: scalingFactor * 0.85,
      });
      const btnLeft = zone.getCenterPoint().x + (!verticalLayout ? buttonOffsetX * (scalingFactor / 2) : 0);
      const btnTop = zone.getCenterPoint().y + (verticalLayout ? buttonOffsetY * (scalingFactor / 2) : 0);

      const addImageButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-wam-button`,
        hoverCursor: 'pointer',
        left: btnLeft,
        top: btnTop,
        angle: zone.angle,

        data: {
          photoTextZoneId: zone.name,
          type: CanvasDataTypes.PhotoTextZoneWAMButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addImageButton);
      });
    });
  });
};
