import { TFunction } from 'i18next';
import { AddressTypes, FormValidation } from '../../global-types';

export enum addressStatus {
  UNKNOWN = 'unknown',
  VALIDATING = 'validating',
  FILLED_IN = 'filledIn',
  VALID = 'valid',
  INVALID = 'invalid',
  INCOMPLETE = 'incomplete',
  ERROR = 'error',
  ERROR_INVALID = 'err_invalid_address',
  ERROR_INCOMPLETE = 'err_incomplete_address',
}

export enum addressAction {
  CONTINUE = 'continue',
  FINISH = 'finish',
}

export type AddressConfirmationProps = {
  isOpen: boolean;
  close: () => void;
  onSkipValidation: (validationAddresses: FormValidation[]) => void;
  onEditAddress: (stepToEdit: number) => void;
  addressesToValidate: FormValidation[];
  showStepper: boolean;
  isAddressFromAddressBook: boolean;
  submitEditedAddress?: ((address: FormValidation[]) => void) | null;
};

export type GetConfirmationModalTextProps = {
  addressType: AddressTypes;
  currentValidationAddress: FormValidation;
  t: TFunction<'translation', undefined, 'translation'>;
};

export type GetConfirmationModalTextReturn = {
  dialogTitle: string;
  dialogBody: string;
};

export type UseAddressValidationTextProps = {
  currentValidationAddress: FormValidation;
};

export type UseAddressValidiationTextReturn = {
  validationTitle: string;
  validationBody: string;
  addressTypeDisplay: string;
};
