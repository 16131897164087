export type PrintOnDemandHeaderProps = {
  onClose: (button: HTMLButtonElement | null) => void;
  isEditor?: boolean;
  onReturnToHomePage: () => void;
  isUK?: boolean;
};

export enum HeaderVariants {
  US = 'us',
  CE = 'ce',
}

export type CEHeaderLayoutProps = {
  onCloseButtonClick: () => void;
  onLogoIconClick: () => void;
  classes: string;
};

export type USHeaderLayoutProps = {
  onCloseButtonClick: () => void;
  onCrownIconClick: () => void;
  classes: string;
  closeButtonRef: React.RefObject<HTMLButtonElement>;
};
