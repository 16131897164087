import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import styles from '../../select-envelope.module.scss';

export const NavigationButtons = ({
  onClickBack,
  onClickContinue,
}: {
  onClickBack: () => void;
  onClickContinue: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        testId={'envelope-edit-card-button'}
        click={onClickBack}
        mode={ButtonModes.TextLink}
        addClass={styles['edit-button']}
        typographyVariant={TypographyVariants.Helper}
        startIcon={{ name: IconNames.ArrowsTailLeftBold, size: 14, color: BrandColors.Blue }}
      >
        {t('envelopeSelection.actionButton.back')}
      </Button>
      <Button click={onClickContinue} mode={ButtonModes.Primary} testId={'envelope-address-button'}>
        {t('envelopeSelection.actionButton.next')}
      </Button>
    </>
  );
};
