import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogFooterType, DialogContentType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { SpellcheckActions } from '../../global-types';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { getTextsWithErrorsByFace } from '../../utils';
import { DialogFooter, SpellcheckDialogProps } from './spellcheck-dialog-types';
import styles from './spellcheck-dialog.module.scss';

export const SpellcheckDialog = ({
  isOpen,
  title,
  onSpellcheck,
  onIgnoreSpellcheck,
  cardFaces,
}: SpellcheckDialogProps) => {
  const { t } = useTranslation();
  const region = getCurrentRegion();

  const dialogAction = useRef<SpellcheckActions>(SpellcheckActions.None);

  React.useEffect(() => {
    if (!isOpen) {
      dialogAction.current = SpellcheckActions.None;
      setClosedByCloseButton(false);
    }
  }, [isOpen]);

  const [closedByCloseButton, setClosedByCloseButton] = useState(false);

  React.useEffect(() => {
    // Add event listener to 'X' button from Dialog component and track when user uses it to close the dialog
    // we're using this listener because the Dialog component doesn't expose a callback or mouse event
    // to identify the close button. MIMIR-3464 was created to tackle this in Design System
    const closeButton = document.getElementById('spellcheck-dialog-close-btn');
    const handleCloseButtonClick = () => {
      setClosedByCloseButton(true);
      dialogAction.current = SpellcheckActions.CancelX;
    };

    if (closeButton) {
      closeButton.addEventListener('click', handleCloseButtonClick);
    }

    return () => {
      if (closeButton) {
        closeButton.removeEventListener('click', handleCloseButtonClick);
      }
    };
  }, []);

  const handleOnSpellcheck = () => {
    if (dialogAction.current !== SpellcheckActions.None) return;
    dialogAction.current = SpellcheckActions.Fix;
    const { firstErrorFaceIdx, textsWithErrors } = getTextsWithErrorsByFace(cardFaces);
    onSpellcheck(firstErrorFaceIdx, textsWithErrors);
  };

  const onIgnoreClick = () => {
    if (dialogAction.current !== SpellcheckActions.None) return;
    dialogAction.current = SpellcheckActions.Ignore;
    onIgnoreSpellcheck(SpellcheckActions.Ignore);
  };

  const onDismissDialog = useCallback(() => {
    if (dialogAction.current === SpellcheckActions.Closed) {
      return;
    }

    if (dialogAction.current === SpellcheckActions.None) {
      // if dialog was dismissed via click on backdrop or Esc key
      dialogAction.current = SpellcheckActions.Cancel;
      onIgnoreSpellcheck(SpellcheckActions.Cancel);
    } else if (closedByCloseButton && dialogAction.current === SpellcheckActions.CancelX) {
      // if dialog was closed via 'X' button
      // setting dialogAction to Closed to prevent double calls to onIgnoreSpellcheck
      dialogAction.current = SpellcheckActions.Closed;
      onIgnoreSpellcheck(SpellcheckActions.CancelX);
    }
    setClosedByCloseButton(false);
  }, [closedByCloseButton, dialogAction.current]);

  const cancelButtonIgnoreAll = (
    <Button
      click={onIgnoreClick}
      mode={ButtonModes.Secondary}
      testId="ignore-button"
      addClass={styles['footer-button']}
    >
      {t('spellcheckDialog.cancel')}
    </Button>
  );

  const checkSpellingButton = (
    <Button
      click={handleOnSpellcheck}
      mode={ButtonModes.Primary}
      testId="check-spelling-button"
      addClass={styles['footer-button']}
    >
      {t('spellcheckDialog.continue')}
    </Button>
  );

  const dialogFooterProps: DialogFooter = {
    footerType: DialogFooterType.MultipleButtons,
    actionButton: checkSpellingButton,
    secondaryActionButton: cancelButtonIgnoreAll,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onDismissDialog}
      title={title}
      accentIcon={IconNames.Search}
      accentIconColor={DialogBrandColors.White}
      type={DialogContentType.FeatureModal}
      footerType={dialogFooterProps.footerType}
      cancelButton={dialogFooterProps.cancelButton}
      actionButton={dialogFooterProps.actionButton}
      secondaryActionButton={dialogFooterProps.secondaryActionButton}
      closeButtonId="spellcheck-dialog-close-btn"
      locale={region}
    >
      <div className={styles['typography']}>
        <Typography variant={TypographyVariants.Body}>{t('spellcheckDialog.description')}</Typography>
      </div>
    </Dialog>
  );
};
