import { Dispatch, SetStateAction } from 'react';

export const throwErrorLoadAddressBook = (
  showErrorToast: (error: string, title?: any) => void,
  addressBookError: string,
  setIsLoadingAddresses: Dispatch<SetStateAction<boolean>>,
) => {
  showErrorToast(addressBookError);
  setIsLoadingAddresses(false);
  throw new Error('Error loading address book');
};
