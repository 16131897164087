import { getIsCardEditted } from '../../../utils';
import { GetRefreshOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const getRefreshTag = (options: GetRefreshOptions) => {
  const startFlowModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const isEditMode = getIsCardEditted(options.cardFacesList);

  const level2 = getModalLevel2();

  const { modal, user } = getModalTag(
    options.productType,
    isEditMode ? 'Edit Mode' : level2,
    options.elementPosition ? startFlowModalEvent : [],
    options.projectID,
  );

  return {
    modal,
    product,
    user,
  };
};
