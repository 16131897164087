/**
 * Function to get the indices of selectable objects in a canvas.
 * This can be used to map the actual indices of the objects on the canvas to be consumed on the order drawer controller,
 * that way the range component inside the order drawer represents the layers from 0 to (selectable objects count - 1).
 * @param canvasObjects {fabric.Object[]} - an array of fabric objects
 * @returns an array of the indices of only selectable objects
 * @example canvasObjectsIndexes.current = getSelectableObjectsIndices(canvasObjects)
 */
export const getSelectableObjectsIndices = (canvasObjects: fabric.Object[]): number[] => {
  return canvasObjects.reduce(function (acc: number[], curr, index) {
    if (curr.selectable) {
      acc.push(index);
    }
    return acc;
  }, []);
};
