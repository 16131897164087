import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '@hallmark/web.core.forms.radio-button';
import { EnvelopeType } from '../../../../global-types/envelope';
import { useFeatureFlags } from '../../../../hooks';
import { ENVELOPE_OPTION, EnvelopeOptionsProps } from '../../select-envelope-types';
import styles from '../../select-envelope.module.scss';

export const EnvelopeOptionsForm = ({ register }: EnvelopeOptionsProps) => {
  const { t } = useTranslation();
  const { IS_MULT_ADDRESS_ENABLED } = useFeatureFlags();

  return (
    <form>
      <div role={'radiogroup'} aria-labelledby={'radio_button_group'} className={styles['envelope-options']}>
        <RadioButton
          domId={EnvelopeType.Blank}
          value={EnvelopeType.Blank}
          register={register(ENVELOPE_OPTION, {
            required: true,
          })}
        >
          {t('envelopeSelection.envelopeLabel.blank')}
        </RadioButton>
        <RadioButton
          domId={EnvelopeType.Return}
          value={EnvelopeType.Return}
          register={register(ENVELOPE_OPTION, {
            required: true,
          })}
        >
          {t('envelopeSelection.envelopeLabel.returnAddressOnly')}
        </RadioButton>
        {IS_MULT_ADDRESS_ENABLED && (
          <RadioButton
            domId={EnvelopeType.ReturnRecipient}
            value={EnvelopeType.ReturnRecipient}
            register={register(ENVELOPE_OPTION, {
              required: true,
            })}
            helperText={t('envelopeSelection.envelopeLabel.returnPlusRecipientAddress.helperText') as string}
          >
            {t('envelopeSelection.envelopeLabel.returnPlusRecipientAddress.name')}
          </RadioButton>
        )}
      </div>
    </form>
  );
};
