import { fabric } from 'fabric';
import { CustomControlOptions } from '../canvas-custom-config-types';

const drawCorner = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  cornerSize: number,
  lineWidth: number,
) => {
  ctx.beginPath();
  ctx.moveTo(left, top);
  ctx.lineTo(left + cornerSize, top);
  ctx.lineTo(left + cornerSize, top + lineWidth);
  ctx.lineTo(left + lineWidth, top + lineWidth);
  ctx.lineTo(left + lineWidth, top + cornerSize);
  ctx.lineTo(left, top + cornerSize);
  ctx.lineTo(left, top);
  ctx.closePath();
  ctx.stroke();
  ctx.fill();
};

export const renderCornerControl = (
  controlName: string,
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
) => {
  const { left, top, size, angle } = options;
  const lineWidth = 3;
  switch (controlName) {
    case 'tl':
      ctx.save();
      // translate centerpoint to control's coordinates before drawing control
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(angle));
      drawCorner(ctx, 0, 0, size, lineWidth);
      ctx.restore();
      break;
    case 'tr':
      ctx.save();
      ctx.translate(left, top);
      // Instead of drawing each particular corner, draw an initial corner (top-left) and rotate as needed,
      // we also need to account for the object's angle when rotating
      ctx.rotate(fabric.util.degreesToRadians(90 + angle));
      drawCorner(ctx, 0, 0, size, lineWidth);
      ctx.restore();
      break;
    case 'bl':
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(-90 + angle));
      drawCorner(ctx, 0, 0, size, lineWidth);
      ctx.restore();
      break;
    case 'br':
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(180 + angle));
      drawCorner(ctx, 0, 0, size, lineWidth);
      ctx.restore();
      break;
    default:
      break;
  }
};
