import { useCallback, useMemo, useState } from 'react';
import { Address, AddressTypes, ErrorType, FormValidation } from '../../../global-types';

/**
 * Handles logic related to the address confirmation dialog and provides necessary functions and variables to be used in this dialog.
 * It takes into consideration the country by just allowing the confirmation dialog to show for the US
 *
 * @returns Object containing address confirmation dialog variables and methods
 */
export const useAddressConfirmationDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addressesToValidate, setAddressesToValidate] = useState<FormValidation[]>([]);

  /**
   * Opens the address confirmation dialog by setting the state variable isDialogOpen to true
   */
  const openAddressConfirmationDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  /**
   * Closes the address confirmation dialog by setting the state variable isDialogOpen to false.
   * Also resets the addresses to validate by setting the state variable addressesToValidate to an empty string
   * This ensures that every time the dialog closes the addresses are reset and don't start to accumulate
   */
  const closeAddressConfirmationDialog = useCallback(() => {
    setIsDialogOpen(false);
    setAddressesToValidate([]);
  }, []);

  /**
   * Gets the address to validate and creates a new address object with the needed keys for the confirmation dialog,
   * and adds the address to validate into the addressesToValidate state variable.
   * It also makes sure that the addressesToValidate are sorted in ascending by their step
   *
   * @param address Address to be added to the addressesToValidate state variable
   * @param errorType Error type from the API response. Can be find on the error description
   * @param step Step number to go when the user clicks on edit this address
   */
  const addAddressToValidate = useCallback((address: Address, errorType: ErrorType, step: number, contactId) => {
    const addressInfoSanitized = {
      title: `${address.first_name} ${address.last_name}`,
      address1: address.address_line_1,
      address2: address.address_line_2,
      address3: '',
      state: address.state_code || '',
      city: address.city,
      zip: address.zip,
    };
    const addressType = address.address_type_code === 'R' ? AddressTypes.RECIPIENT : AddressTypes.SENDER;
    const originalAddressData = { ...address };
    setAddressesToValidate((addresses) =>
      [
        ...addresses,
        {
          errorType,
          originalAddressData,
          contactId,
          addressType,
          address: {
            ...addressInfoSanitized,
          },
          step,
        },
      ].sort((a, b) => a.step - b.step),
    );
  }, []);

  const isConfirmationDialogOpen: boolean = useMemo(
    () => isDialogOpen && addressesToValidate.length > 0,
    [isDialogOpen, addressesToValidate],
  );

  return {
    openAddressConfirmationDialog,
    closeAddressConfirmationDialog,
    addAddressToValidate,
    isConfirmationDialogOpen,
    addressesToValidate,
  };
};
