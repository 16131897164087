import { clearPhotoTextZone } from '../../../utils';
import { ResetTextData } from '../card-face-types';

export const closeResetTextModal = (setResetTextModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  setResetTextModalOpen(false);
};

export const confirmResetText = (
  resetTextData: ResetTextData,
  SAS_DYNAMIC_BUTTONS: boolean,
  setResetTextModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  clearPhotoTextZone(resetTextData.canvas, SAS_DYNAMIC_BUTTONS, resetTextData.zoneName);
  setResetTextModalOpen(false);
};
