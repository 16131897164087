import { RegionalCodesList } from '../../global-types';
import { EnvironmentsType } from '../../global-types/feature-flags';

export const setEnabledEnvironments = (environments: EnvironmentsType[]): boolean => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV ?? null;
  const currentTheme = process.env.REACT_APP_THEME ?? null;

  if (!currentEnv || !currentTheme) {
    return false;
  }
  const currentEnvCode = `${currentTheme}-${currentEnv}`;
  // Checks for current environment in list of environments
  const matchedEnvironment = environments.filter((environment) => environment === currentEnvCode);
  // If no match is found
  if (matchedEnvironment.length <= 0) {
    return false;
  }
  // Get regional code of matched environment (for example, 'us' from 'us-dev');
  const regionalCode = matchedEnvironment[0].split('-')[0];

  const regionsList = {
    us: regionalCode === 'us',
    uk: regionalCode === 'uk',
    ce: regionalCode === 'ce',
    all: regionalCode === 'all',
  };
  return regionsList[regionalCode as RegionalCodesList] || false;
};
