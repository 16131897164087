import { IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import userZoneAddPhotoIconUK from '../../assets/user-zone-add-photo-icon-uk.svg';
import userZoneAddTextIconUK from '../../assets/user-zone-add-text-icon-uk.svg';
import userZoneAddWamIconUK from '../../assets/user-zone-add-wam-icon-uk.svg';
import { DeliveryModes } from '../../components/address-form/address-form-types';
import { HeaderVariants } from '../../components/print-on-demand-header/print-on-demand-header-types';
import { RegionalCodesList } from '../../global-types';
import { TextEditorDrawerType } from '../../global-types/regions-list';
import colorsList from '../../styles/util.scss';
import { RegionalSchema } from '../regional-config-types';

export const configUK: RegionalSchema = {
  deliveryMode: DeliveryModes.MailToRecipient,
  cardEditor: {
    faceTypeText: true,
    textEditorDrawerType: TextEditorDrawerType.Bottom,
    lastCardFaceRoute: 'preview',
  },
  cardSelectBar: {
    containerClass: 'footer',
  },
  addressForm: {
    handleZipChange: true,
    shouldShowOneToManyEnvelopePreview: false,
  },
  saveProjects: {
    isSavedProjectsEnabled: true,
    saveButton: {
      type: 'LoadingButton',
      iconName: IconNames.SyncSyncInactive,
    },
    hasSignInDialog: false,
  },
  header: {
    svgColor: IconButtonBrandColors.Black,
    saveDialogTheme: RegionalCodesList.uk,
    variant: HeaderVariants.US,
    quantityInput: true,
  },
  scssStyles: {
    appBackground: colorsList[BrandColors.EditorBackground],
    headerBackgroundMobile: colorsList[BrandColors.White],
  },
  features: {
    // POD Write a Message includes a WAM button in the toolbar and a WAM button in user zones
    isPodWamEnabled: true,
  },
  userZones: {
    addTextIcon: userZoneAddTextIconUK,
    addPhotoIcon: userZoneAddPhotoIconUK,
    addWAMIcon: userZoneAddWamIconUK,
    areInstructionsEnabled: true,
  },
  isAddressRegulationToastEnabled: false,
  hasUsStates: false,
  skipAddressView: true,
};
