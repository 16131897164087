import { CardFaceData, SpellcheckErrors } from '../../global-types';
import { getAllTextsWithErrors } from './get-all-texts-with-errors';

/**
 * Function that returns the index of the first card-face that has a spelling error
 * If no spelling errors are found for whatever reason, returns the index of the first face
 * @param cardFaces
 * @returns {number} - Index of the first card-face that has a spelling error
 */
export const getTextsWithErrorsByFace = (
  cardFaces: CardFaceData[],
): { firstErrorFaceIdx: number; textsWithErrors: SpellcheckErrors } => {
  let firstFaceId = 0; // Default to the first face
  let textsWithErrors: SpellcheckErrors = {};

  if (cardFaces && cardFaces.length > 0) {
    firstFaceId = cardFaces[0].faceId as number;

    textsWithErrors = getAllTextsWithErrors(cardFaces) as SpellcheckErrors;

    if (!textsWithErrors || Object.keys(textsWithErrors).length === 0) {
      // If no texts with errors, return the initial face index and an empty textsWithErrors object
      return { firstErrorFaceIdx: firstFaceId, textsWithErrors: {} };
    }
    // Find the first faceId of faces with errors
    firstFaceId = Math.min(...Object.keys(textsWithErrors).map(Number));
  }

  return { firstErrorFaceIdx: firstFaceId, textsWithErrors: textsWithErrors };
};
