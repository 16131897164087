// Here we'll place any helper function that could be used globally on text objects

/**
 * Enforces a text to not grow beyond a given lenght by trimming it to the allowed limit
 * @param text the text to validate
 * @param maxLength max character length
 * @returns
 */
export const enforceMaxLength = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
  }

  return text;
};
