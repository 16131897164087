import { CardFaceData, SpellcheckErrors } from '../../global-types';
import { getCanvasTextsWithErrors } from './get-canvas-texts-with-errors';

/**
 * Function that returns a mapped array of text objects with spelling errors mapped to their respective card-face by faceID
 * {Face1: [textObject1, textObject2], Face2: [textObject3, textObject4}
 * @param cardFacesList
 * @returns { [key: string]: fabric.Textbox[] } - Object containing arrays of text objects with spelling errors mapped to their card-face by faceID
 * @example getAllTextsWithErrors(cardFacesList)
 *
 */
export const getAllTextsWithErrors = (cardFacesList: CardFaceData[] | null): SpellcheckErrors | null => {
  if (!cardFacesList || cardFacesList.length < 1) {
    return null;
  }

  const textObjectsByCardFace: { [key: string]: fabric.Textbox[] } = {};
  cardFacesList
    .filter((face) => face.canvas?.current)
    .forEach((face) => {
      const currentCanvas = (face.canvas?.current as fabric.Canvas) || null;
      if (currentCanvas) {
        const faceTexts = getCanvasTextsWithErrors(currentCanvas, face.faceId - 1);
        if (!faceTexts) {
          return null;
        } else if (faceTexts?.length > 0) {
          textObjectsByCardFace[`${face.faceId - 1}`] = faceTexts;
        }
      }
    });

  // Check if textObjectsByCardFace is empty
  if (Object.keys(textObjectsByCardFace).length === 0) {
    return null; // Return null if the object is empty
  }

  return textObjectsByCardFace;
};
