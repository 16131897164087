import { Font } from '../../global-types';

/**
 * Retrieves the label value based on the given font ID.
 * @param {string} urlName - The font name in the url to search for.
 * @param {Array} fontCollection - The font collection to search in.
 * @returns {number | undefined} - The font id value corresponding to the font ID, or null if not found.
 */
export const getFontidByUrlName = (urlName: string, fontCollection: Font[] | undefined): number | undefined =>
  fontCollection?.find((font) => font.url.includes(urlName))?.id;
