import axios from 'axios';

/**
 * Takes a DwClientId from local env or Gitlab Secret and returns an authentication header
 * @returns Promise<any>
 */
export const fetchAuthorizationToken = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const dwClientId = process.env.DW_CLIENT_ID || process.env.REACT_APP_DW_CLIENT_ID;
    if (!dwClientId) {
      reject('DW_CLIENT_ID variable missing in environment');
    }
    const requestBody = JSON.stringify({ type: 'session' });
    // Using type any here becuase AxiosRequestHeaders type doesn't like custom headers
    const configs: any = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'x-dw-client-id': dwClientId,
      },
    };

    axios.post('/dw/shop/v23_2/customers/auth', requestBody, configs).then(
      (response) => {
        const responseHeaders = response.headers || undefined;
        if (!responseHeaders) {
          reject('No Authorization token assiciated with this account');
        }
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
};
