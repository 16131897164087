import { CrownRewardsErrorCodes } from '../../../global-types/errors';
import { HandleDigitalordersErrorProps } from '../preview-types';

/**
 *
 * @param error
 */
export const handleDigitalOrdersError = ({
  error,
  onNoHPlusNonSubscriberError,
  onCrMemberLimitRechedError,
  onNonCrMemberError,
  onDefaultError,
}: HandleDigitalordersErrorProps) => {
  const errorCode = error.status;
  if (errorCode === 403) {
    const errorName = error.data.errors[0].code;
    switch (errorName) {
      case CrownRewardsErrorCodes.NonHplusSubscriber:
        onNoHPlusNonSubscriberError();
        break;
      case CrownRewardsErrorCodes.CrMemberLimitReached:
        onCrMemberLimitRechedError();
        break;
      case CrownRewardsErrorCodes.NonCrMember:
        onNonCrMemberError();
        break;
      default:
        onDefaultError(error);
    }
  } else {
    onDefaultError(error);
  }
};
