/* eslint-disable no-console */
import { getOrderRequestBody, handleDigitalOrdersError } from '.';
import { ConfirmProjectDetailsRequestBody, ErrorResponse } from '../../../global-types';
import { fetchAuthorizationToken, finalizeDigitalOrder } from '../../../services';
import { FinalizeOrderProps } from '../preview-types';

export const finalizeOrder = async ({
  cardPreviewImages,
  btlyUrl,
  onNoHPlusNonSubscriberError,
  onCrMemberLimitRechedError,
  onNonCrMemberError,
  onDefaultError,
  initializedDataState,
  isHPlusEnabled,
}: FinalizeOrderProps): Promise<void> => {
  // Get the authorization token if H+ is enabled
  let authHeader: null | string = null;
  let customerId: null | string = null;
  if (isHPlusEnabled) {
    const response: any = await fetchAuthorizationToken();
    if (response?.headers?.authorization && response?.data?.customer_id) {
      authHeader = response.headers.authorization;
      customerId = response.data.customer_id;
    }
  }

  return new Promise<void>((resolve, reject) => {
    // If there are no card preview images, resolve the promise
    if (cardPreviewImages.length === 0) {
      reject('No card preview images found');
    }

    // Build the order request body for digital-orders call inside finalizeDigitalOrder
    const { orderRequestBody, purchaseDate, projectId } = getOrderRequestBody({
      initializedDataState,
      cardPreviewImages,
      btlyUrl,
    });

    /** if IS_H_PLUS_ENABLED is active, get the authHeader and pass the authorization header to the ecommerce session ID */
    if (authHeader && customerId) {
      orderRequestBody.ecommerce_session_token = authHeader;
      orderRequestBody.customer_id = customerId;
    }

    // Build confirmation request body to be consumed by confirmProjectData service in finalizeDigital Order
    const confirmRequestBody: ConfirmProjectDetailsRequestBody = {
      purchase_date: purchaseDate,
      order_number: '', // Get from digital-orders response
      project_ids: [projectId],
    };

    // Call finalizeDigitalOrder method to begin processing order
    finalizeDigitalOrder(orderRequestBody, confirmRequestBody)
      .then((order) => {
        // Resolve promsise if all API calls are successful
        resolve(order);
      })
      .catch((error: ErrorResponse) => {
        // Handle errors if any API call fails
        handleDigitalOrdersError({
          error,
          onNoHPlusNonSubscriberError,
          onNonCrMemberError,
          onCrMemberLimitRechedError,
          onDefaultError,
        });
      });
  }).catch((error) => {
    return new Promise<void>((reject) => {
      reject(error);
    });
  });
};
