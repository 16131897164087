import { useNavigate, useLocation } from 'react-router-dom';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { updateAddresses, updateDigitalAddress, useInitializationDataContext } from '../../../context/data-context';
import { AddressForm as AddressFormData, EmailAddressForm as EmailAddressFormData } from '../../../global-types';
import { getRegionalConfig } from '../../../regional-config';
import { useEditorNavigation } from '../../editor/hooks/useEditorNavigation';
import { useEnvelopeAddresses } from './useEnvelopeAddresses';

export const useAddressGoBack = ({ step, setStep, isDigitalGreeting, envelope }) => {
  // Contexts
  const {
    initializedDataState: { data: initializedData, addressData },
    initializationDataDispatch,
  } = useInitializationDataContext();

  // Get the addresses to fill based on country and envelope selection
  const addressTypesToFill = useEnvelopeAddresses();

  const {
    addressForm: { shouldShowOneToManyEnvelopePreview },
  } = getRegionalConfig();
  const { handleIndexChange } = useEditorNavigation();
  const { trackReturnToEdit } = useAnalyticsContext();

  // Routing
  const navigate = useNavigate();
  const { search } = useLocation();

  const goBack = (addressFormValues: AddressFormData, emailAddressFormValues: EmailAddressFormData) => {
    if (isDigitalGreeting) {
      const currentEmailAddress = { ...addressData.digital, ...emailAddressFormValues };
      updateDigitalAddress(initializationDataDispatch, currentEmailAddress);
    } else {
      const currentAddressType = addressTypesToFill[+step];
      const currentAddress = { ...addressData[`${currentAddressType}`], ...addressFormValues };
      updateAddresses(initializationDataDispatch, { [currentAddressType]: currentAddress });
    }

    if (step > 0) {
      return setStep((currentStep) => currentStep - 1);
    }
    trackReturnToEdit();
    const searchParams = new URLSearchParams(search);

    if (shouldShowOneToManyEnvelopePreview && envelope) {
      searchParams.set('envelope', envelope);
    }

    const lastProjectLoaded = sessionStorage.getItem('lastProjectLoaded');
    const editorUrl = lastProjectLoaded
      ? `/card/customization/edit/${lastProjectLoaded}`
      : `/card/customization/edit/${initializedData?.project_id}`;

    navigate({ pathname: editorUrl, search: searchParams.toString() });
    handleIndexChange(0, 0);
  };

  return {
    goBack,
  };
};
