import { Dispatch } from 'react';
import { AppAction } from '../../context/app-context';
import { CardType, ProjectTypeCode } from '../../global-types';

type HandleSelectionSpellcheckParams = {
  options: fabric.IEvent<MouseEvent>;
  projectTypeCode: ProjectTypeCode | undefined;
  shouldHighlightErrors: boolean;
  appDispatch: Dispatch<AppAction>;
  setSkipSpellcheckValidation: (dispatch: Dispatch<AppAction>, value: boolean) => void;
  handleSpellcheckCallback: ({ textObject, shouldHighlightErrors, setSkipSpellcheckValidation, appDispatch }) => void;
};

/**
 * Function to handle spellcheck on textboxes
 * @param options fabric.IEvent<MouseEvent> - event object
 * @param projectTypeCode ProjectTypeCode - project type code
 * @param shouldHighlightErrors boolean - flag to highlight errors
 * @param appDispatch React.Dispatch<any> - app dispatch function
 * @param handleSpellcheckCallback (textbox: fabric.Textbox) => void - callback function to handle spellcheck
 * @param setSkipSpellcheckValidation (dispatch: Dispatch<AppAction>, value: boolean) => void - callback function to set skip spellcheck validation
 */
export const handleSelectionSpellcheck = ({
  options,
  projectTypeCode,
  shouldHighlightErrors,
  appDispatch,
  handleSpellcheckCallback,
  setSkipSpellcheckValidation,
}: HandleSelectionSpellcheckParams): void => {
  // Trigger spellcheck only on POD cards, for S&S it will be triggered on handleTemporaryTextEditor
  const deselectedObject = (options?.deselected && options?.deselected[0]) as fabric.Object;
  if (projectTypeCode !== CardType.SAS && deselectedObject?.type === 'textbox') {
    handleSpellcheckCallback({
      textObject: deselectedObject as fabric.Textbox,
      shouldHighlightErrors,
      setSkipSpellcheckValidation,
      appDispatch,
    });
  }
};
