/**
 * Constructs a URL to add a customized product to the cart with optional thumbnail images.
 * @param {string} monolith - The base URL of the monolith system.
 * @param {string} productId - The ID of the product to be added to the cart.
 * @param {string} projectId - The project ID associated with the product customization.
 * @param {Array} images - An array of image objects where each object contains a `url` property.
 * @returns {string} The fully constructed URL for adding the customized product to the cart.
 */
import { CardFacePreviewImage } from '../global-types';

type ConstructAddToCartURLProps = {
  monolithUrl: string;
  productId: string;
  projectId: string;
  images: CardFacePreviewImage[];
};

export function constructAddToCartURL({
  monolithUrl,
  productId,
  projectId,
  images,
}: ConstructAddToCartURLProps): string {
  const frontImageURL = images[0]?.url || '';
  const encodedFrontURL = encodeURIComponent(frontImageURL);
  let encodedInsideURL = '';

  if (images.length > 1 && images[1].url) {
    const insideImageURL = images[1].url || '';
    encodedInsideURL = encodeURIComponent(insideImageURL);
  }

  return `${monolithUrl}/Cart-AddCustomizedProduct?pid=${productId}&projectID=${projectId}&thumbnailImageURL1=${encodedFrontURL}${
    encodedInsideURL ? '&thumbnailImageURL2=' + encodedInsideURL : ''
  }`;
}
