import { GetIsAddressFormValid } from '../address-types';

export const getIsAddressFormValid: GetIsAddressFormValid = ({
  isDigitalGreeting,
  isValidEmail,
  isAddressFormValid,
  shouldValidateForm,
}) => {
  if (isDigitalGreeting) {
    return isValidEmail;
  }

  return isAddressFormValid || !shouldValidateForm;
};
