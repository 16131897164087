import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes, ButtonTypographyVariants } from '@hallmark/web.core.buttons.button';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { Address } from '@hallmark/web.core.typography.address';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { AddressBoxProps } from './address-box-types';
import styles from './address-box.module.scss';
import { AddressBoxStepper } from './fragments/address-box-stepper';

/**
 * Renders a stylized block of address information with an edit CTA.
 */
export const AddressBox = ({
  address,
  onEditAddress,
  addressesToValidate,
  activeIndex,
  showStepper,
}: AddressBoxProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles['address-box']} ${
        addressesToValidate > 1 && showStepper ? styles['address-box-stepper'] : ''
      }`}
    >
      {addressesToValidate > 1 && showStepper && (
        <AddressBoxStepper activeIndex={activeIndex} addressesToValidate={addressesToValidate} />
      )}
      <Typography variant={TypographyVariants.BodyBold} addClass={styles['address-header']}>
        {t('addressConfirmation.addressBoxTitle')}
      </Typography>
      <Address {...address} addClass={styles.address} />
      <Button
        mode={ButtonModes.Quaternary}
        testId="address-box-edit-button"
        typographyVariant={ButtonTypographyVariants.Body}
        addClass={styles['edit-button']}
        click={onEditAddress}
      >
        {`${t('addressConfirmation.editAddress')} `}
        <Icon name={IconNames.EditBold} size={16} color={BrandColors.Purple} />
      </Button>
    </div>
  );
};
