import { dataUriToBlob } from './data-uri-to-blob';
import { readFileAsDataURI } from './read-file-as-data-uri';
import { readFileExifData } from './read-file-exif-data';
import { stripExifData } from './strip-exif-image';

/**
 * Fixes rotation issues in card-renderer caused by EXIF data in iPhone images by stripping this meta-data when needed.
 * @param file Image file to fix the EXIF rotation
 * @returns Promise<Blob | null> Returns a Blob of the fixed image or null if the image does not need to be updated
 */
export const fixExifImageRotation = async (file: File): Promise<Blob | null> => {
  // Check if file has EXIF data
  const fileEXIF = await readFileExifData(file);
  if (!fileEXIF) {
    return null;
  }

  const { Orientation: orientation } = fileEXIF;
  // If the image orientation is less than 1 or greater than 8, the image rotation does not need to be 'fixed' in
  // order for the card-renderer and web image to be consistent.
  if (!orientation || (orientation <= 1 && orientation > 8)) {
    return null;
  }

  // Read the images as a data URI so it can be added to the canvas via stripExifData
  const dataEncodedImage = await readFileAsDataURI(file);
  if (!dataEncodedImage) {
    return null;
  }

  // Strip the EXIF data from the image by filtering it through an HTML canvas
  const strippedImage = await stripExifData(dataEncodedImage);
  if (!strippedImage) {
    return null;
  }

  // Convert the stripped image back to a Blob that can be uploaded
  const imageBlob = await dataUriToBlob(strippedImage);
  if (!imageBlob) {
    return null;
  }

  return imageBlob;
};
