import { CanvasDataTypes } from '../../../utils';

export const createTextElement = ({
  width,
  fabric,
  initialTextMessage,
  isUK,
  defaultTextSettings,
  cardFaceClipPath,
}) => {
  const textElement = new fabric.Textbox(initialTextMessage, {
    top: 100,
    left: 100,
    width,
    height: 100,
    hasControls: true,
    borderColor: isUK ? '#333333' : '#604099',
    textAlign: 'center',
    ...defaultTextSettings,
  });
  textElement.set({ data: { type: CanvasDataTypes.UserText } });
  if (cardFaceClipPath) {
    textElement.set({
      // 62 is default textBox height
      top: cardFaceClipPath.top + cardFaceClipPath.height / 2 - (textElement.height || 62) / 2,
      left: cardFaceClipPath.left,
      width: cardFaceClipPath.width,
    });
    textElement.clipPath = cardFaceClipPath;
  }

  return textElement;
};
