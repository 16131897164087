import { fabric } from 'fabric';
import typeIcon from '../../../assets/phototextzone-type-message.svg';
// TODO: check importing icon using regionalConfig
import minTypeIcon from '../../../assets/user-zone-add-text-icon-us.svg';
import { CanvasDataTypes, helperSettingsConfig } from '../../../utils';
import { photoTextZoneConfig } from '../../../utils/configs/phototextzone-settings.configs';

const { scalingFactor } = helperSettingsConfig;
const { buttonOffsetX, buttonOffsetY } = photoTextZoneConfig;

/**
 * Generate an Add Text button for photo text zones
 * @param zone - phototext zone into which photo button will be added
 * @param verticalLayout - boolean to determine if the layout is vertical
 * @param useMinIcon - boolean to determine if the min icon should be used
 * @returns promise
 */
export const getPhotoTextZoneTextButton = (
  zone: fabric.Object,
  verticalLayout = false,
  useMinIcon = false,
): Promise<fabric.Group> => {
  const editTextIcon = useMinIcon ? minTypeIcon : typeIcon;
  return new Promise((resolve) => {
    fabric.Image.fromURL(editTextIcon, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-text-button-icon`,
        hoverCursor: 'pointer',
        angle: zone.angle,
        // The (0.85) multiplier is an arbitrary value added to better adjust the buttons to the zones
        scaleX: scalingFactor * 0.85,
        scaleY: scalingFactor * 0.85,
      });
      const btnLeft = zone.getCenterPoint().x - (!verticalLayout ? buttonOffsetX * (scalingFactor / 2) : 0);
      const btnTop = zone.getCenterPoint().y - (verticalLayout ? buttonOffsetY * (scalingFactor / 2) : 0);

      const addTextButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-text-button`,
        hoverCursor: 'pointer',
        left: btnLeft,
        top: btnTop,
        angle: zone.angle,

        data: {
          photoTextZoneId: zone.name,
          type: CanvasDataTypes.PhotoTextZoneTextButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addTextButton);
      });
    });
  });
};
