import { datadogRum } from '@datadog/browser-rum';
import packageInfo from '../../../../package.json';
import { DataDogVariables } from '../analytics-context-types';
import { getTrackingConsent } from './get-tracking-consent';

const getMissingVariableList = (variables: DataDogVariables) => {
  const missingVariablesList: string = Object.keys(variables).reduce((message, currentValue) => {
    const variableValue = variables[`${currentValue}`];
    const stringToAdd = variableValue === '' || !variableValue ? `${currentValue}, ` : '';
    return message + stringToAdd;
  }, '');
  return missingVariablesList;
};

export const initializeDataDog = () => {
  const env = process.env.REACT_APP_DATADOG_ENV;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
  const site = process.env.REACT_APP_DATADOG_SITE;
  const dataLayer = window.dataLayer as any;

  try {
    if (!env || !clientToken || !applicationId || !site) {
      const missingVariablesList = getMissingVariableList({ env, clientToken, applicationId, site });
      throw Error(`Missing data dog environmental variables: ${missingVariablesList.slice(0, -2)}`);
    }

    const tracingUrls = [
      // Customazation facad urls
      'https://www.(dev.services|stage.services|services).hallmark.com/.*',
      // Base app urls
      'https://www.(dev.|stage.)?hallmark.com/card/customization/.*',
    ];

    const trackingConsent = getTrackingConsent();

    datadogRum.init({
      env,
      clientToken,
      applicationId,
      site,
      version: packageInfo.version,
      service: 'customization',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 5,
      trackUserInteractions: true,
      trackingConsent: trackingConsent,
      trackResources: true,
      trackSessionAcrossSubdomains: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: tracingUrls,
    });

    datadogRum.setGlobalContextProperty('dwsid', dataLayer?.user?.profile?.dwsid?.substr(0, 10));
    datadogRum.setGlobalContextProperty(
      'profile_info.accountId',
      document.cookie
        ?.split('; ')
        .find((c) => c.startsWith('accountId'))
        ?.split('=')[1],
    );
    datadogRum.setGlobalContextProperty(
      'profile_info.accountType',
      document.cookie
        ?.split('; ')
        .find((c) => c.startsWith('accountType'))
        ?.split('=')[1],
    );
    datadogRum.setGlobalContextProperty('profile_info.memberType', dataLayer?.user?.profile?.memberType);
    datadogRum.setGlobalContextProperty('profile_info.CRNumber', dataLayer?.user?.profile?.rewardsID);
    datadogRum.setGlobalContextProperty('profile_info.KOCMembershipID', dataLayer?.user?.profile?.kocMembershipID);
    datadogRum.setGlobalContextProperty('ip_address', dataLayer?.page?.pageInfo?.IP);
    datadogRum.setGlobalContextProperty('page_type', dataLayer?.page?.pageInfo?.pageType);
    datadogRum.setGlobalContextProperty('order_info.order_id', dataLayer?.transaction?.transactionID);
    datadogRum.setGlobalContextProperty('order_info.totals.order_total', dataLayer?.transaction?.total?.cartTotal);
    datadogRum.setGlobalContextProperty(
      'order_info.discounts.discount_total',
      dataLayer?.transaction?.total?.discountTotal,
    );
    datadogRum.setGlobalContextProperty(
      'order_info.discounts.discount_total_order_level',
      dataLayer?.transaction?.total?.orderLevelDiscountTotal,
    );
    datadogRum.setGlobalContextProperty(
      'order_info.discounts.discount_total_item_level',
      dataLayer?.transaction?.total?.itemLevelDiscountTotal,
    );
    datadogRum.setGlobalContextProperty(
      'order_info.totals.shipping_total',
      dataLayer?.transaction?.total?.shippingInfo?.shippingTotal,
    );
    datadogRum.setGlobalContextProperty('order_info.totals.tax_amount', dataLayer?.transaction?.total?.taxAmount);
    datadogRum.setGlobalContextProperty('order_info.total_quantity', dataLayer?.transaction?.total?.totalQty);
    datadogRum.setGlobalContextProperty(
      'order_info.number_of_shipments',
      dataLayer?.transaction?.total?.numberOfShipments,
    );
    datadogRum.setGlobalContextProperty(
      'order_info.shipment_info',
      dataLayer?.transaction?.total?.shipmentInfo?.reduce((o, s, i) => ({ ...o, [`shipment_${i + 1}`]: s }), {}),
    );
    datadogRum.setGlobalContextProperty(
      'order_info.payment_methods',
      dataLayer?.transaction?.total?.paymentMethods?.reduce(
        (o, p, i) => ({ ...o, [`payment_method_${i + 1}`]: p }),
        {},
      ),
    );
    datadogRum.startSessionReplayRecording();
    // eslint-disable-next-line no-console
    console.log('Datadog has loaded successfully!');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error with loading datadog', error);
  }
};
