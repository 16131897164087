import { CardFaceData } from '../../../global-types';
import { getRemovableObjects } from '../../../utils';

/**
 * Clean the canvas of removable on save objects.
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 */
export const cleanCanvas = (cardFaces: CardFaceData[]): void => {
  cardFaces.forEach((face) => {
    if (face.canvas.current) {
      getRemovableObjects(face.canvas.current).forEach((obj) => face?.canvas?.current?.remove(obj));
    }
  });
};
