import { CardContextState, Dispatch, actionTypes } from './card-context-types';

export const setCardDataAction = (dispatch: Dispatch, cardData: CardContextState) => {
  dispatch({
    type: actionTypes.SET_CARD_DATA,
    payload: {
      cardData: cardData,
    },
  });
};

export const setActiveCardIndex = (dispatch: Dispatch, activeCardIndex: number) => {
  dispatch({
    type: actionTypes.SET_ACTIVE_CARD_INDEX,
    payload: {
      activeCardIndex: activeCardIndex,
    },
  });
};

export const setSlideCardIndex = (dispatch: Dispatch, slideCardIndex: number) => {
  dispatch({
    type: actionTypes.SET_SLIDE_CARD_INDEX,
    payload: { slideCardIndex },
  });
};

export const resetCardDataAction = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.RESET_CARD_DATA,
  });
};

export const setSelectedPhotoZone = (
  dispatch: Dispatch,
  photoZoneId: string | null,
  uploadedImage?: fabric.Image | null,
) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PHOTOZONE,
    payload: {
      photoZoneId,
      uploadedImage,
    },
  });
};

export const setCoverPreviewUrl = (dispatch: Dispatch, coverPreviewUrl: string) => {
  dispatch({
    type: actionTypes.SET_COVER_PREVIEW_URL,
    payload: {
      coverPreviewUrl,
    },
  });
};

export const setThumbnailUrl = (dispatch: Dispatch, thumbnailUrl: string) => {
  dispatch({
    type: actionTypes.SET_THUMBNAIL_URL,
    payload: {
      thumbnailUrl,
    },
  });
};
