import { AddressForm } from '../../global-types';
import { Contact } from '../address-book/address-book-types';

const validStringRegExp = /^[a-z\s&'-]*$/i;
const validZipCodeRegExp = /(^\d{5}$)/;
const validPostCodeRegExp =
  /(^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$)/; // UK

export const validStringRule = (errorMessage: string) => (data: string) =>
  !!data.match(validStringRegExp) || errorMessage;

export const validZipCodeRule = (errorMessage: string) => (zip: string) => {
  const theme = process.env.REACT_APP_THEME;
  switch (theme) {
    case 'uk':
      return !!zip.match(validPostCodeRegExp) || errorMessage;
    default:
      return !!zip.match(validZipCodeRegExp) || errorMessage;
  }
};

export const getThemeZipLength = () => {
  const theme = process.env.REACT_APP_THEME;
  switch (theme) {
    case 'uk':
      return { min: 5, max: 8 };
    default:
      return { min: 5, max: 5 };
  }
};

export const getContactFormData = (addressForm: AddressForm, contact: Contact) => {
  const addressFormKeys = Object.keys(addressForm);

  const contactFormData = {};
  addressFormKeys.forEach((key) => {
    if (key in contact) {
      contactFormData[`${key}`] = contact[`${key}`];
    }
  });

  return contactFormData;
};
