import warningIcon from '../../assets/warning-icon.svg';
import { addIconToObject, IconsTypes } from '../../components/card-editor/utils/add-icon-to-object';
import { hideMiddleControls } from '../canvas';

/**
 * Adds image to the canvas in the initial position.
 * This is used for images that are not in a photo zone.
 * @param imgInstance  the image to be added
 * @param canvas  the canvas to add the image to
 * @param onIconAdded  the callback function to be called when the icon is clicked
 */
export const addImageToCanvas = (
  imgInstance: fabric.Image,
  canvas: fabric.Canvas,
  onIconAdded?: (icon: fabric.Image) => void,
): void => {
  imgInstance.scaleToHeight(200);
  imgInstance.scaleToWidth(200);

  hideMiddleControls(imgInstance);
  if (canvas) {
    // Add a warning icon to low resolution images added to the canvas
    addIconToObject(canvas, imgInstance, warningIcon, IconsTypes.LowRes, {
      iconOffset: 35,
      isLowResIcon: true,
      onIconAdded,
    });
  }
  canvas?.add(imgInstance);
  canvas?.setActiveObject(imgInstance);
};
