import { useCookies } from 'react-cookie';
import { useAppContext, setErrorMessages, setIsSystemErrorOpen } from '../context/app-context';
import { setIsRedirectDialogOpen } from '../context/app-context/app-context-actions';
import { ErrorResponse, RegionalCodesList } from '../global-types';
import { getMonolithUrl } from '../utils';
import { cleanAccountCookies, handleApiErrors, removeTokens } from '../utils';
import { isAnyOfRegions } from '../utils/utility';

type OnSystemErrorOptions = {
  redirect?: boolean;
  errorCallback?: () => void;
};

export type OnSystemError = (errors: ErrorResponse, options?: OnSystemErrorOptions) => void;

type UseSytemErrorHandlingMethods = {
  onSystemError: OnSystemError;
};

/**
 * Collection of custom hooks that return functions that handle system and API errors.
 */
export const useSystemErrorHandling = (): UseSytemErrorHandlingMethods => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const { appDispatch } = useAppContext();

  /**
   * Handles system api errors
   * @param errors
   */
  const onSystemError: OnSystemError = (errors: ErrorResponse, options: OnSystemErrorOptions = { redirect: false }) => {
    const { redirect, errorCallback }: OnSystemErrorOptions = options;
    const onAccountError = () => {
      cleanAccountCookies(removeCookie);
      removeTokens();
    };

    const callback = () => {
      setErrorMessages(
        appDispatch,
        errors?.data?.errors?.map((error) => error.description),
      );

      if (redirect) {
        let queryString = '';

        if (isAnyOfRegions([RegionalCodesList.uk])) {
          queryString = '?consent=false';
        }

        if (isAnyOfRegions([RegionalCodesList.ce])) {
          queryString = '?consent=false';
        }

        const redirectUrl = `${getMonolithUrl()}${queryString}`;

        setIsRedirectDialogOpen(appDispatch, { isDialogOpen: true, redirectUrl });
      } else {
        setIsSystemErrorOpen(appDispatch, true);
      }

      if (errorCallback) {
        errorCallback();
      }
    };

    handleApiErrors(errors, onAccountError, callback);
  };

  return { onSystemError };
};
