import { Contact } from '../../components/address-book/address-book-types';
import { InitializationData, Font, HallmarkMembershipStatus } from '../../global-types';
import { AddressResponseData, Address, DigitalAddress, QuickAddressesResponse } from '../../global-types/addresses';

export type Data = InitializationData;

//TODO: add the other things we may need or create a new state for the data as a whole
export type InitializationDataContextState = {
  initialized: boolean;
  fontFactor: number;
  defaultSize: number | null;
  defaultColor: string | null;
  fonts: Font[];
  hasFonts: boolean;
  data: Data | null;
  addressData: {
    sender: AddressResponseData | Address | null;
    recipient: AddressResponseData | Address | null;
    digital: DigitalAddress | Address | null;
  };
  savedContacts: Contact[];
  contactPaging: NonNullable<QuickAddressesResponse['data']>['paging'] | null;
  entryURL: string;
  lineItemUUID: string | null;
  isUS?: boolean;
  isUK?: boolean;
  isCE?: boolean;
  allRegions?: boolean;
  region?: string;
  membershipStatus: HallmarkMembershipStatus;
  //TODO: add colors
};

// Actions
// TODO: determine if we have other actions we may need based on the init data? Do we really need fonts here?
export enum actionTypes {
  SET_INIT_DATA = 'SET_INIT_DATA',
  UPDATE_FONT_STATE = 'UPDATE_FONT_STATE',
  SET_FONTS = 'SET_FONTS',
  UPDATE_DIGITAL_ADDRESS = 'UPDATE_DIGITAL_ADDRESS',
  UPDATE_ADDRESSES = 'UPDATE_ADDRESSES',
  CLEAN_ADDRESSES = 'CLEAN_ADDRESSES',
  UPDATE_CONTACTS = 'UPDATE_CONTACTS',
  APPEND_CONTACTS = 'APPEND_CONTACTS',
  SET_CONTACT_PAGING = 'SET_CONTACT_PAGING',
  RESET_DATA_CONTEXT = 'RESET_DATA_CONTEXT',
  SET_LINE_ITEM_UUID = 'SET_LINE_ITEM_UUID',
  UPDATE_PROJECT_NAME = 'UPDATE_PROJECT_NAME',
  SET_MEMBERSHIP_STATUS = 'SET_MEMBERSHIP_STATUS',
}

export type SetFonts = {
  type: actionTypes.SET_FONTS;
  payload: Font[];
};

// TODO: can we set this as the init data or how do we want this to look?
export type InitializationDataOptions = InitializationData | null;

export type UpdateOptions = {
  initialized?: boolean;
  fontFactor?: number;
  defaultSize?: number;
  defaultColor?: string;
  hasFonts?: boolean;
};

export type UpdateFontState = {
  type: actionTypes.UPDATE_FONT_STATE;
  payload: UpdateOptions;
};

export type SetInitializationDataState = {
  type: actionTypes.SET_INIT_DATA;
  payload: InitializationDataOptions;
};

export type UpdateDigitalAddress = {
  type: actionTypes.UPDATE_DIGITAL_ADDRESS;
  payload: DigitalAddress | Address;
};

export type UpdateAddresses = {
  type: actionTypes.UPDATE_ADDRESSES;
  payload: { recipient?: AddressResponseData | Address; sender?: AddressResponseData | Address };
};

export type CleanAddresses = {
  type: actionTypes.CLEAN_ADDRESSES;
};

export type UpdateContacts = {
  type: actionTypes.UPDATE_CONTACTS;
  payload: Contact[];
};

export type SetContactPaging = {
  type: actionTypes.SET_CONTACT_PAGING;
  payload: NonNullable<QuickAddressesResponse['data']>['paging'] | null;
};

export type ResetDataContext = {
  type: actionTypes.RESET_DATA_CONTEXT;
};

export type SetLineItemUUID = {
  type: actionTypes.SET_LINE_ITEM_UUID;
  payload: string;
};

export type UpdateProjectName = {
  type: actionTypes.UPDATE_PROJECT_NAME;
  payload: string;
};

export type AppendContacts = {
  type: actionTypes.APPEND_CONTACTS;
  payload: Contact[];
};

export type SetMembershipStatus = {
  type: actionTypes.SET_MEMBERSHIP_STATUS;
  payload: HallmarkMembershipStatus;
};

export type DataActions =
  | SetFonts
  | UpdateContacts
  | UpdateFontState
  | CleanAddresses
  | SetInitializationDataState
  | UpdateDigitalAddress
  | UpdateAddresses
  | ResetDataContext
  | SetLineItemUUID
  | UpdateProjectName
  | SetContactPaging
  | AppendContacts
  | SetMembershipStatus;

export type Dispatch = (action: DataActions) => void;

export type CreateContextProps =
  | { initializedDataState: InitializationDataContextState; initializationDataDispatch: Dispatch }
  | undefined;
