import { CardContextState } from '../../../context/card-context';

/**
 * Gets WAM Image position, which varies based on card orientation
 * @param {CardContextState} cardState - cardState to access card dimensions
 * @returns {object} - Object containing top, left, width and height values
 */
export const getPodWamPosition = (
  cardState: CardContextState,
): { wamTop: number; wamLeft: number; wamWidth: number; wamHeight: number } => {
  // WAM position changes based on if card is portrait (inside has left/right) or landscape (inside has top/bottom)
  let topPosition, leftPosition;
  const dimensions = cardState.cardFacesList[1].dimensions;
  const CENTER_OFFSET = 175; // Half of WAM image width/height
  if (dimensions.height > dimensions.width) {
    // Landscape-oriented card, WAM image is centered on inside top
    // To center, we offset by half of POD WAM width/height, as top and left represent the top left corner of the image
    topPosition = dimensions.height * 0.25 - CENTER_OFFSET; // Top panel center is 25% of height
    leftPosition = dimensions.width * 0.5 - CENTER_OFFSET; // Horizontal center is 50% of width
  } else {
    // Portrait-oriented card, WAM image is centered on inside left
    // To center, we offset by half of POD WAM width/height, as top and left represent the top left corner of the image
    topPosition = dimensions.height * 0.5 - CENTER_OFFSET; // Vertical center is 50% of height
    leftPosition = dimensions.width * 0.25 - CENTER_OFFSET; // Left panel horizontal point is 25% of width
  }
  return {
    wamTop: topPosition,
    wamLeft: leftPosition,
    wamWidth: 350,
    wamHeight: 350,
  };
};
