import { TextEditorDrawerType } from '../../../global-types/regions-list';
import { CanvasDataTypes } from '../../../utils';

const DRAWER_FRONT_ADD_TEXT = 'editor--drawer-front-add-text';
const DRAWER_INSIDE_ADD_TEXT = 'editor--drawer-inside-add-text';
const DRAWER_FRONT_ADD_PHOTO = 'editor--drawer-front-add-photo';
const DRAWER_INSIDE_ADD_PHOTO = 'editor--drawer-inside-add-photo';

/**
 * Get the class name for the editor overlap style
 * @param styles - The styles object
 * @param isTextDrawerOpen - The state of the text drawer
 * @param canvas - The reference to the fabric.Canvas
 * @param isMobile - The state of the mobile view
 * @param isFrontFace - The state of the front face
 * @param isImageUploadDrawerOpen - The state of the image upload drawer
 * @param isImageUploadToPhotoZoneOpen - The state of the image upload to photo zone
 * @param textEditorDrawerType - The type of the text editor drawers
 * @returns The class name
 */
export const getClassEditorOverlapStyle = (
  styles: Record<string, string>,
  isTextDrawerOpen: boolean,
  canvas: React.RefObject<fabric.Canvas> | undefined,
  isMobile: boolean,
  isFrontFace: boolean,
  isImageUploadDrawerOpen: boolean,
  isImageUploadToPhotoZoneOpen: boolean,
  textEditorDrawerType: TextEditorDrawerType | undefined,
  isSignAndSend = false,
): string => {
  const editorClass = styles['editor'];

  if (isMobile) {
    return editorClass;
  }

  const activeCanvasObject = canvas?.current?.getActiveObject() as fabric.Object;

  const isAddTextConditionMet = () => {
    if (isSignAndSend && isTextDrawerOpen) {
      return true;
    }
    return (
      isTextDrawerOpen &&
      activeCanvasObject &&
      activeCanvasObject.data?.type !== CanvasDataTypes.EditableText &&
      textEditorDrawerType === TextEditorDrawerType.Side
    );
  };

  const isAddPhotoConditionMet = isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen;

  if (isAddTextConditionMet() && canvas?.current) {
    return getEditorClassForTextDrawer(styles, activeCanvasObject, canvas.current, isFrontFace, isSignAndSend);
  }

  if (isAddPhotoConditionMet) {
    return `${editorClass} ${styles[isFrontFace ? DRAWER_FRONT_ADD_PHOTO : DRAWER_INSIDE_ADD_PHOTO]}`;
  }

  return editorClass;
};

/**
 * This function validates that if the drawer no longer overlaps the text then it should not move to the right.
 * @param styles
 * @param activeCanvasObject
 * @param canvas
 * @param isFrontFace
 * @returns
 */
const getEditorClassForTextDrawer = (
  styles: Record<string, string>,
  activeCanvasObject: fabric.Object,
  canvas: fabric.Canvas,
  isFrontFace: boolean,
  isSignAndSend = false,
): string => {
  const { left: boundingLeft } = activeCanvasObject.getBoundingRect();
  const { width, height } = canvas;

  if (width && height && (isSignAndSend || width > height)) {
    const widthSegment = width / 2.5;

    if (boundingLeft && boundingLeft < widthSegment) {
      return `${styles['editor']} ${styles[isFrontFace ? DRAWER_FRONT_ADD_TEXT : DRAWER_INSIDE_ADD_TEXT]}`;
    }
  }

  return styles['editor'];
};
