import React, { Reducer, createContext, useReducer, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useCardContext } from '../../context/card-context';
import { cropAndRotateFormData } from '../../global-types';
import { CustomFabricObject } from '../../global-types/canvas';
import { useActiveCanvas, useIsPodProductCode } from '../../hooks';
import { getObjectByName, setAngle, hideMiddleControls, getCardFaceClipPath } from '../../utils';
import { fillPhotoZone } from '../../utils/canvas-utils';
import { useAnalyticsContext } from '../analytics-context';
import {
  hideLoadingScreen,
  setIsImageEditDrawerOpen,
  setIsSystemErrorOpen,
  setIsToasterOpen,
  showLoadingScreen,
  useAppContext,
} from '../app-context';
import { useInitializationDataContext } from '../data-context';
import { initState } from './crop-context-state';
import { CropContextState, CropAction, CropProviderProps, CreateContextProps } from './crop-context-types';
import {
  applyClipPathToImage,
  createCroppingArea,
  getCropAndRotateFormData,
  getCropImagePosition,
  getCroppingAreaCoords,
} from './utils';
import { uploadCroppedImage } from './utils/upload-cropped-image';

const CropContext = createContext<CreateContextProps>(undefined);

const cropReducer: Reducer<CropContextState, CropAction> = (state, action) => {
  const { type } = action || { type: null };
  switch (type) {
    default: {
      throw new Error(`Unhandled action type in card-context`);
    }
  }
};

export const CropContextProvider = ({ children }: CropProviderProps) => {
  const [cropState, cropDispatch] = useReducer(cropReducer, initState);
  const { appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { t } = useTranslation();
  const isPodProductCode = useIsPodProductCode();
  const { updateEditFormats } = useAnalyticsContext();
  const [currentImage, setCurrentImage] = useState<CustomFabricObject | null>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isSavingCroppedImage, setIsSavingCroppedImage] = useState(false);
  const croppingArea = useRef<fabric.Rect>();
  const canvas = useActiveCanvas() as { current: fabric.Canvas };
  const { cardFacesList } = cardState;
  const currentCardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];

  const handleIconAdded = useCallback(
    (icon: fabric.Image) => {
      icon.on('mousedown', () => {
        setIsToasterOpen(appDispatch, {
          title: t('lowResolutionImage.title'),
          children: t('lowResolutionImage.message'),
          variant: ToastVariants.Warning,
        });
      });
    },
    [appDispatch, t],
  );

  const cropDeselectionHandler = useCallback(() => {
    // Maintain cropping rectangle selected until cropping is finished
    if (isCropping) {
      const croppingRect = croppingArea.current;
      if (croppingRect) {
        canvas.current.setActiveObject(croppingRect).renderAll();
      }
    }
  }, [isCropping]);

  /**
   * Restores the selectable and envented values of the canvas objects as they are disabled during cropping
   */
  const restoreSelectableObjects = () => {
    const canvasObjects = canvas.current.getObjects();
    canvasObjects.forEach((obj) => {
      if (obj.data?.isSelectable) {
        obj.selectable = true;
      }
      if (obj.data?.isEvented) {
        obj.evented = true;
      }
    });
  };

  const startCropping = () => {
    const currentImage = canvas?.current?.getActiveObject() as CustomFabricObject;
    if (currentImage && currentImage.clipPath) {
      currentImage.clipPath = undefined;
    }
    setCurrentImage(currentImage);
    setIsCropping(true);
  };

  const finishCropping = async () => {
    canvas.current?.off('selection:cleared', cropDeselectionHandler);

    if (
      croppingArea &&
      croppingArea.current &&
      currentImage &&
      currentImage.top !== undefined &&
      currentImage.left !== undefined
    ) {
      // Set the angle and position of the crop area
      const { totalRotation, currentAngle, newCoords } = getCroppingAreaCoords({ croppingArea, currentImage });
      croppingArea.current.set({ left: newCoords.x, top: newCoords.y, angle: 0 }).setCoords();
      (croppingArea.current as CustomFabricObject)._resetOrigin();
      setAngle(currentImage, totalRotation);

      // Get the position of image being cropped
      const { xPos, yPos } = getCropImagePosition({ croppingArea, currentImage });

      // Only populate if values are not fractions of a pixel
      if (0 !== Math.round(xPos * 1000) && 0 !== Math.round(yPos * 1000)) {
        // Track when user is updating the image and where they are cropping it
        updateEditFormats({ crop: `x:${xPos}, y:${yPos}` });
      }

      // Generate the form data for cropping and rotating the image later
      const cropAndRotateFormData: cropAndRotateFormData = getCropAndRotateFormData({
        croppingArea: croppingArea,
        currentImage: currentImage,
        totalRotation,
        xPos,
        yPos,
      });

      // Create a new favric image.  IF FE Cropping is enabled, create a new image from the cropped image
      const croppedImage = new Image();
      croppedImage.src = currentImage.toDataURL({
        left: xPos,
        top: yPos,
        width: croppingArea.current.getScaledWidth(),
        height: croppingArea.current.getScaledHeight(),
      });
      showLoadingScreen(appDispatch, 'Cropping your image...');

      // Restore objects selectable and evented original values
      restoreSelectableObjects();

      if (!initializedData?.project_id || !currentImage.name) {
        throw Error('Unable to crop image: Project ID or Image Name is missing from application state');
      }

      return new Promise<fabric.Image>((resolve) => {
        fabric.Image.fromURL(
          croppedImage.src,
          (img) => {
            hideMiddleControls(img);
            const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
            img.crossOrigin = 'anonymous';
            if (cardFaceClipPath) {
              // Ensure image respects copyrighted zone boundaries
              img.clipPath = cardFaceClipPath;
            }
            // pod and photozone image
            if (isPodProductCode && currentImage.data?.photoZoneId) {
              img.data = {
                ...currentImage.data,
              };
              img.name = currentImage.name;
              canvas.current.remove(currentImage);
              canvas.current.remove(croppingArea.current as fabric.Object);
              croppingArea.current = undefined;
              fillPhotoZone(currentImage.data.photoZoneId, img, cardFacesList, handleIconAdded);
              setAngle(img, currentAngle);
              setCurrentImage(null);
              setIsCropping(false);
              hideLoadingScreen(appDispatch);
              return resolve(img);
            }

            const photoZone = getObjectByName(currentImage.data?.zoneName, canvas.current);
            // if we have a zone we want to make sure we scale the image into the zone size
            if (photoZone) {
              img.scaleToWidth(photoZone.getScaledWidth());
              if (img.getScaledHeight() > photoZone.getScaledHeight()) {
                img.scaleToHeight(photoZone.getScaledHeight());
              }
            } else {
              img.scaleToWidth(croppingArea.current?.getScaledWidth() as number);
            }
            if (currentImage.data?.clipPathSettings && !currentImage?.data?.isPodHandwriting) {
              const clipPath = new fabric.Rect(currentImage.data.clipPathSettings);
              img.top = (clipPath.top as number) - img.getScaledHeight() / 2;
              img.left = (clipPath.left as number) - img.getScaledWidth() / 2;
              img.clipPath = clipPath;
            } else {
              img.top = croppingArea.current?.top;
              img.left = croppingArea.current?.left;
            }
            img.data = {
              ...currentImage.data,
              version_id: '',
            };
            img.name = currentImage.name;

            img.onSelect = () => {
              setIsImageEditDrawerOpen(appDispatch);
              return false;
            };
            canvas?.current?.add(img);
            canvas?.current?.setActiveObject(img);
            setAngle(img, currentAngle);
            // Remove cropping rectangle
            canvas.current.remove(croppingArea.current as fabric.Object);
            croppingArea.current = undefined;
            canvas.current.remove(currentImage);
            setCurrentImage(null);
            setIsCropping(false);
            hideLoadingScreen(appDispatch);
            resolve(img);
          },
          { crossOrigin: 'anonymous' },
        );
      })
        .then((fabricImage) => {
          if (!initializedData?.project_id || !fabricImage.name) {
            setIsSystemErrorOpen(appDispatch, true);
            throw Error('Unable to upload cropped image: Project ID or Image Name is missing');
          }

          setIsSavingCroppedImage(true);

          uploadCroppedImage({
            croppedImageUrl: croppedImage.src,
            cropAndRotateFormData: cropAndRotateFormData,
            imageName: fabricImage.name,
            projectId: initializedData?.project_id,
            onUploadSuccess: (imageUrl) => {
              if (imageUrl) {
                fabricImage.data.croppedUrl = imageUrl;
                setIsSavingCroppedImage(false);
              }
            },
            onUploadError: (error) => {
              setIsSystemErrorOpen(appDispatch, true);
              setIsSavingCroppedImage(false);
              throw Error(error.toString());
            },
          });
        })
        .catch((error) => {
          setIsSystemErrorOpen(appDispatch, true);
          throw Error(error);
        });
    }
  };

  const cancelCropping = () => {
    canvas.current?.off('selection:cleared', cropDeselectionHandler);
    if (croppingArea.current && currentImage) {
      canvas.current.remove(croppingArea.current);
      applyClipPathToImage(currentImage);
      canvas.current.setActiveObject(currentImage);
      setCurrentImage(null);
      setIsCropping(false);
      restoreSelectableObjects();
      canvas.current.requestRenderAll();
    }
  };

  useEffect(() => {
    if (isCropping) {
      createCroppingArea(
        currentImage,
        canvas,
        croppingArea,
        initializedData?.project_type_code,
        cropDeselectionHandler,
      );
    }
  }, [isCropping]);

  return (
    <CropContext.Provider
      value={{
        cropDispatch,
        cropState,
        startCropping,
        finishCropping,
        isSavingCroppedImage,
        isCropping,
        cancelCropping,
      }}
    >
      {children}
    </CropContext.Provider>
  );
};

// Hooks
export const useCropContext = () => {
  const context = useContext(CropContext);
  if (context === undefined) {
    throw new Error('useCropContext must be used within CropProvider');
  }

  return context;
};
