import { setMembershipStatus, useInitializationDataContext } from '../context/data-context';
import { ErrorResponse } from '../global-types';
import { fetchAuthorizationToken } from '../services';
import { parseMembershipData } from '../utils';

export const useMembershipData = () => {
  const { initializationDataDispatch } = useInitializationDataContext();

  const loadMembershipData = async () => {
    // Call finalizeDigitalOrder method to begin processing order
    fetchAuthorizationToken()
      .then((authResponse) => {
        const parsedMembershipData = parseMembershipData(authResponse.data);
        setMembershipStatus(initializationDataDispatch, parsedMembershipData);
      })
      .catch((error: ErrorResponse) => {
        // eslint-disable-next-line no-console
        console.warn('Failed to load membership status:', error);
      });
  };

  return { loadMembershipData };
};
