import { CanvasDataTypes } from '../canvas-utils';

/**
 * Discards the active text object being edited from the canvas and renders.
 * @param {fabric.Canvas} currentCanvas The fabric canvas instance.
 * @returns void

 */
export const discardTextObjectAndRender = (currentCanvas: fabric.Canvas): void => {
  const activeObject = currentCanvas.getActiveObject();

  if (
    activeObject?.name === CanvasDataTypes.TemporaryTAMInput ||
    activeObject?.name === CanvasDataTypes.UserText ||
    activeObject?.name === CanvasDataTypes.Placeholder ||
    activeObject?.data?.type === CanvasDataTypes.EditableText ||
    activeObject?.data?.type === CanvasDataTypes.EditableArea
  ) {
    currentCanvas.discardActiveObject();
    currentCanvas.renderAll();
  }
};
