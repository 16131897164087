import React from 'react';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useAddressSelectionContext } from '../../context/address-selection-indicator/address-selection';

export const AddressSelectionIndicator = () => {
  const {
    addressSelectionState: { count, maxCount },
  } = useAddressSelectionContext();

  return maxCount === 0 ? (
    <span data-testid={'address-selection-indicator'} hidden></span>
  ) : (
    <Typography variant={TypographyVariants.Body} data-testid={'address-selection-indicator'}>
      {count}/{maxCount}
    </Typography>
  );
};
