import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useAnalyticsContext } from '../../../../context/analytics-context';
import { useActiveCanvas } from '../../../../hooks';
import { overlayImageDefaultSettings } from '../../../../utils';
import { handleFocus, handleTextTabClick } from '../../font-drawer/utils/handle-text-tab-click';
import { handleOrderChange } from '../utils/handle-order-change';

export const usePositionTab = () => {
  const canvas = useActiveCanvas();
  const { updateEditFormats } = useAnalyticsContext();
  const { lowestIndex, highestIndex } = useMemo(() => {
    if (canvas?.current) {
      const objects = canvas.current.getObjects();
      const overlayImgIndex = objects.findIndex((obj) => obj.name === overlayImageDefaultSettings.name);
      const foldLineIndex = objects.findIndex((obj) => obj?.data?.type === 'fold-line');
      const highestIndex = objects.length - 1;
      let lowestIndex;

      // If there is an overlayImage object, the text cannot go behind that object.
      if (overlayImgIndex !== -1) {
        lowestIndex = overlayImgIndex + 1;
      } else if (foldLineIndex !== -1) {
        // If there is no overlayImage object but there is an  image with data.type === "fold-line"
        // the text cannot go behind the "fold-line" object.
        lowestIndex = foldLineIndex + 1;
      }
      return { lowestIndex, highestIndex };
    }
    return { lowestIndex: 0, highestIndex: 0 };
  }, [canvas]);

  const handleBringToFrontClick = () => {
    handleOrderChange(highestIndex, canvas?.current as fabric.Canvas);
    handleFocus(canvas?.current || null, isMobile, handleTextTabClick);
    updateEditFormats({ order: 'Bring To Front' });
  };

  const handleSendToBackClick = () => {
    handleOrderChange(lowestIndex, canvas?.current as fabric.Canvas);
    handleFocus(canvas?.current || null, isMobile, handleTextTabClick);
    updateEditFormats({ order: 'Send To Back' });
  };

  return {
    handleBringToFrontClick,
    handleSendToBackClick,
  };
};
