import { GetAbandonOrDeleteProjectOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const getAbandonOrDeleteProjectTag = (options: GetAbandonOrDeleteProjectOptions) => {
  const ABANDON_OR_DELETE_PROJECT_NAMES = {
    abandon: 'abandon project',
    delete: 'delete project',
  };

  const abandonProjectModalEvent = [
    {
      eventInfo: {
        eventName: 'exit',
        eventAction: ABANDON_OR_DELETE_PROJECT_NAMES[`${options.eventAction}`],
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();
  const { modal, user } = getModalTag(options.productType, level2, abandonProjectModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
