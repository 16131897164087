import { getAssetUrls } from '../../services/image';

/**
 * Create a single asset URL for a file
 * @param file
 * @returns asset S3 URL
 */
export const createSingleAssetUrl = async (file: File): Promise<string> => {
  const format = file.type.split('/')[1];
  let url = '';
  try {
    const response = await getAssetUrls(1, format);
    url = Array.isArray(response.data) ? response.data[0] : '';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to generate asset URL for format ${format}:`, error);
    return '';
  }
  return url;
};
