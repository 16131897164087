import { useLocation } from 'react-router-dom';

/**
 * Custom hook to determine if the current pathname matches any of the provided routes.
 *
 * @returns {Object} An object containing the `isEnabledRoutes` function.
 */
export const useEnabledRoutes = () => {
  const { pathname } = useLocation();

  /**
   * Checks if the current pathname includes any of the specified routes.
   *
   * @param {string[]} routes - An array of route strings to check against the current pathname.
   * @returns {boolean} `true` if the current pathname includes any of the specified routes, otherwise `false`.
   */
  const isEnabledRoutes = (routes: string[]): boolean => {
    if (!pathname) return false;

    const matchedRoute = routes.filter((route) => pathname.includes(route));

    if (matchedRoute.length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  return { isEnabledRoutes };
};
