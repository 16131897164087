import { ImageType } from '../../global-types/images';
import { fixExifImageRotation } from './fix-exif-image-rotation';
import { isValidImage } from './is-valid-image';
import { mapImageType } from './map-image-type';

export class InvalidImageFormatError extends Error {
  constructor() {
    super();
    this.name = 'InvalidImageFormatError';
  }
}

/**
 * Validates the format of image and. If JPEG, it will also fix the EXIF data and return the fixed image.
 * @param imageFile An image file to validate
 */
export const validateUploadedImageFormat = async (imageFile: File): Promise<Blob | null> => {
  const { isValid, imageHeader } = await isValidImage(imageFile);

  if (!isValid || !imageHeader) {
    throw new InvalidImageFormatError();
  }

  if (mapImageType(imageHeader) === ImageType.JPEG) {
    return await fixExifImageRotation(imageFile);
  }

  return null;
};
