import React from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import styles from '../../select-envelope.module.scss';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <header className={styles['envelope-card-header']}>
      <Typography variant={TypographyVariants.Subtitle} htmlTagOverride={HtmlTagOverrides.H2} addClass={styles.title}>
        {t('envelopeSelection.title')}
      </Typography>
      <Typography variant={TypographyVariants.Body}>{t('envelopeSelection.description')}</Typography>
    </header>
  );
};
