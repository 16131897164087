import { CardFaceData } from '../../global-types';
import { getAllTextsWithErrors } from './get-all-texts-with-errors';

/**
 * Function that validates if there are spelling errors in any card face
 * @param cardFaces
 * @returns {boolean} - Returns true if there are no spelling errors, false otherwise
 */
export const validateSpelling = (cardFaces: CardFaceData[]): boolean => {
  const textsWithErrors = getAllTextsWithErrors(cardFaces);

  if (textsWithErrors) {
    return false;
  }

  return true;
};
