import { GetSpellcheckDialogOptions } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getSpellcheckDialogTag = (options: GetSpellcheckDialogOptions) => {
  const spellcheckDialogModalEvent = [
    {
      eventInfo: {
        eventName: 'spell check',
        eventAction: `${options.spellcheckCalls}`,
      },
    },
    {
      eventInfo: {
        eventName: options.eventAction === 'fix' ? 'fix' : 'ignore',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getModalTag(
    options.productType,
    'Preview Mode',
    spellcheckDialogModalEvent,
    options.projectID,
  );

  return {
    modal,
    product,
    user,
  };
};
