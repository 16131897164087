import { underlineText } from './underline-text';

/**
 * Highlights misspellings in a given fabric.Text object
 * @param textObject The fabric.Text object spelling suggestions.
 */
export const highlightTextMisspellings = (textObject: fabric.Text) => {
  if (!textObject.text || !textObject.data?.suggestions) {
    return;
  }

  const {
    text,
    data: { suggestions = [] },
  } = textObject;

  // Loop through the mispelled words and underline them
  suggestions.forEach((suggestion) => {
    let start = text.indexOf(suggestion.word);
    while (start !== -1) {
      // Loop to handle all occurrences of the misspelled word
      const end = start + suggestion.word.length;
      underlineText(textObject, start, end);
      // Create a regex to find the next occurrence of the whole word
      const wordRegex = RegExp(`\\b${suggestion.word}\\b`, 'g');
      const match = wordRegex.exec(text.slice(end));

      // Adjust the start index based on the sliced text
      start = match ? match.index + end : -1;
    }
  });
};
