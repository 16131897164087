import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../context/analytics-context';
import { setIsToasterOpen, useAppContext, addPhotoTrayImages } from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { useInitializationDataContext } from '../context/data-context';
import { ApiResponse, CardType, ImageResponse } from '../global-types';
import { uploadPhotoS3 } from '../services/image';
import { imageConverter } from '../utils/utility/image-converter';
import { getAndMapAssetUrls } from '../utils/utility/image-map-asset-urls';
import { InvalidImageFormatError } from '../utils/utility/validate-uploaded-image-format';
import { validateUploadedImageFormat } from '../utils/utility/validate-uploaded-image-format';
import { useDatadog } from './useDatadog';

const FILE_MAX_SIZE = 10 * 1024 * 1024;

export const usePhotoTrayImages = () => {
  const {
    appState: { photoTrayImages },
    appDispatch,
  } = useAppContext();
  const { trackUploadPhotoError, updateUploadedPhotos, trackUploadedPhotos } = useAnalyticsContext();
  const { cardState } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const datadog = useDatadog();
  const errorMessage = t('imageUploadDrawer.imageErrorMessage');
  const isSignAndSend = initializedData?.project_type_code === CardType.SAS;

  const uploadImage = async (file: File, s3Url = ''): Promise<ImageResponse | undefined | unknown> => {
    const formData = new FormData();
    formData.append('is_handwriting_image', `${isSignAndSend}`);
    formData.append('display_indicator', `${!isSignAndSend}`);
    formData.append('file', file);

    try {
      if (!s3Url) throw new Error('No asset url present');

      const fileUpdated = await validateUploadedImageFormat(file);
      fileUpdated && formData.set('file', fileUpdated);

      const response = await uploadPhotoS3(formData, false, s3Url);
      updateUploadedPhotos(file.size);

      return response;
    } catch (error) {
      if (error instanceof InvalidImageFormatError) {
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.onlyImagesMessage'),
        });
      } else {
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: errorMessage,
        });
      }

      return;
    }
  };

  const onUploadImages = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const input = event.currentTarget;

      if (!initializedData || !input.files || input.files.length === 0) {
        throw Error('Required data undefined when uploading image');
      }

      const files = Array.from(input.files ?? []);
      const allowedSizeFiles = await Promise.all(files.map((file) => imageConverter(file)())).then((files) =>
        files.filter((file) => file.size <= FILE_MAX_SIZE),
      );

      if (files.length > allowedSizeFiles.length) {
        input.value = '';
        trackUploadPhotoError(`${t('imageUploadDrawer.sizeRestrictionAlert')}`);
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.sizeRestrictionAlert'),
        });
      }

      if (allowedSizeFiles.length > 20) {
        input.value = '';
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Warning,
          title: t('imageUploadDrawer.imageLimitTitle'),
          children: t('imageUploadDrawer.imageLimitMessage'),
        });

        return;
      }

      setIsUploading(true);

      datadog.addAction('upload-photo', {
        files: Array.from(files ?? []).map((uploadedFile) => {
          return {
            name: uploadedFile.name,
            lastModified: uploadedFile.lastModified,
            size: uploadedFile.size,
            type: uploadedFile.type,
          };
        }),
        uploadType: 'handwriting',
        cardFace: cardState.cardFacesList[cardState.activeCardIndex]?.type ?? null,
        zoneId: cardState.selectedPhotoZone?.id ?? null,
      });

      // Create a mapped array of files with their s3 urls { file, format, assetUrl}
      const filesToUpload = await getAndMapAssetUrls(allowedSizeFiles);

      if (filesToUpload.length !== allowedSizeFiles.length) {
        input.value = '';
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Warning,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.imageErrorMessage'),
        });
      }
      // Call uploadImage with params for S3 upload
      const uploadingImages = filesToUpload.map(async (file) => {
        return uploadImage(file.file, file.assetUrl);
      });

      Promise.all(uploadingImages).then((images) => {
        const uploadedImages = images
          .map((image) => {
            const img = image as ApiResponse<ImageResponse | ImageResponse[]>;
            if (Array.isArray(img?.data)) {
              return img.data[0] as ImageResponse;
            }
            return undefined;
          })
          .filter((image) => !!image) as ImageResponse[];

        addPhotoTrayImages(appDispatch, uploadedImages);
        setIsUploading(false);
        trackUploadedPhotos();
      });
      input.value = '';
    },
    [appDispatch, trackUploadedPhotos, uploadImage],
  );

  return { onUploadImages, photoTrayImages, isUploading, setIsUploading };
};
