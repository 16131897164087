import { FabricObject } from '../../../global-types';
import { helperSettingsConfig } from '../../../utils';
import { renderCornerControl } from './corner-control';
import { renderMiddleControl } from './middle-control';
import { drawRotateControl } from './rotate-control';

const { scalingFactor } = helperSettingsConfig;

export const renderCustomCroppingControl = (
  controlName: string,
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  fabricObject: FabricObject,
) => {
  const initialSize = 28;
  let scaledSize = initialSize;
  const { scaleX = 0, scaleY = 0, angle = 0 } = fabricObject;
  // if the cropping rectangle gets too small, scale controls down
  if (scaleX < 1 || scaleY < 1) {
    scaledSize = Math.max(Math.min(initialSize * scaleX, initialSize * scaleY), 10);
    fabricObject.cornerSize = scaledSize;
  }

  const options = {
    left: left,
    top: top,
    size: scaledSize,
    angle: angle,
  };

  ctx.strokeStyle = '#666666';
  ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';

  if (controlName === 'mtr') {
    // keep rotate control same size
    options.size = initialSize;
    drawRotateControl(ctx, options, scalingFactor);
  } else if (controlName.startsWith('m') && controlName != 'mtr') {
    renderMiddleControl(controlName, ctx, options);
  } else if (!controlName.startsWith('m')) {
    renderCornerControl(controlName, ctx, options);
  }
};
