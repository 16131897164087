import { fabric } from 'fabric';
import { OnPhotoTrayImageDrop } from '../../global-types/canvas';
import { linkPhoto } from '../../services';
import { defaultImageSettingsConfig, scaleToHeight, scaleToWidth } from '../configs/default-image-settings.config';

export const onPhotoTrayImageDrop = async ({
  event,
  data,
  isUK,
  setIsImageUploadDrawerOpen,
  hideMiddleControls,
  canvas,
  appDispatch,
}: OnPhotoTrayImageDrop) => {
  event.e.preventDefault();
  event.e.stopPropagation();
  const dragEvent = event.e as DragEvent;
  const id = dragEvent.dataTransfer?.getData('id');
  if (!id) return;

  const el = document.getElementById(id) as HTMLImageElement;
  const imgEl = new global.Image();
  imgEl.crossOrigin = 'anonymous';
  imgEl.src = el.src;
  await imgEl.decode();
  const payload = {
    image_reference_id: id,
  };

  const response = await linkPhoto(payload, data?.project_id as string);
  const settings = {
    ...defaultImageSettingsConfig({ event, data: response.data, isUK }),
  };

  const imgInstance = new fabric.Image(imgEl, settings);
  imgInstance.originX = 'left';
  imgInstance.originY = 'top';
  imgInstance.scaleToHeight(scaleToHeight);
  imgInstance.scaleToWidth(scaleToWidth);
  const scaleX = imgInstance.scaleX || 1;
  const scaleY = imgInstance.scaleY || 1;
  const offsetMultiplier = 1.5;
  imgInstance.left = imgInstance.width
    ? event.e.offsetX * offsetMultiplier - (imgInstance.width * scaleX) / 2
    : event.e.offsetX * offsetMultiplier;
  imgInstance.top = imgInstance.height
    ? event.e.offsetY * offsetMultiplier - (imgInstance.height * scaleY) / 2
    : event.e.offsetY * offsetMultiplier;

  setIsImageUploadDrawerOpen(appDispatch);
  hideMiddleControls(imgInstance);
  canvas?.add(imgInstance);
  canvas?.setActiveObject(imgInstance);
};
