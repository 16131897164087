import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../context/analytics-context';
import { useAppContext, setIsToasterOpen, setErrorMessages, setIsSystemErrorOpen } from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { useInitializationDataContext } from '../context/data-context';
import {
  CardFacePreviewImage,
  ApiResponse,
  ProjectTypeCode,
  AddToCartParams,
  RegionalCodesList,
} from '../global-types';
import { addProjectToCart } from '../services';
import { getMonolithUrl } from '../utils';
import { removeBeforeUnloadEvent } from '../utils';
import { constructAddToCartURL } from '../utils/construct-add-to-cart-url';
import { isAnyOfRegions } from '../utils/utility';
import { getAddToCartRequestBody, handleCloseAndKeepShopping } from '../views/preview/utils';

export const useSubmitOrder = (
  images: CardFacePreviewImage[],
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  addToCart: ({ requestBody, closeModalOverride, keepShoppingOverride, cartOptions }: AddToCartParams) => void,
) => {
  const {
    initializedDataState: { data: initializedData, addressData, lineItemUUID },
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { appState, appDispatch } = useAppContext();
  const { trackAddToCartError } = useAnalyticsContext();
  const monolithUrl = getMonolithUrl();

  const { t } = useTranslation();

  const { coverPreviewUrl } = cardState;
  const { isSendToMe, productQuantity } = appState;
  const projectId = initializedData?.project_id || '';
  const productId = initializedData?.product_id || '';

  const handleSubmit = useCallback(
    (quantity: string) => {
      if (isAnyOfRegions([RegionalCodesList.uk])) {
        removeBeforeUnloadEvent();
        const encodedThumbnailPath = encodeURIComponent(coverPreviewUrl);
        const url = `${monolithUrl}/pages/add-to-cart`.concat(
          `?variant_id=${productId}`,
          `&project_id=${projectId}`,
          `&thumbnail=${encodedThumbnailPath}`,
          `&send_to_myself=${isSendToMe}`,
          `&qty=${productQuantity}`,
        );
        window.location.href = url;
        return url;
      } else if (isAnyOfRegions([RegionalCodesList.ce])) {
        const url = constructAddToCartURL({ monolithUrl, productId, projectId, images });
        window.location.href = url;
      } else {
        const requestBody = getAddToCartRequestBody({
          productId,
          coverPreviewUrl,
          projectId,
          address: addressData.recipient ?? addressData.sender,
          scanCode: initializedData?.scan_code || '',
          lineItemUUID,
          quantity,
        });

        const exit = () => handleCloseAndKeepShopping(initializedData?.project_type_code as ProjectTypeCode);

        if (!appState.isProjectSaved) {
          setIsSystemErrorOpen(appDispatch, true);
          return;
        }
        addProjectToCart(projectId)
          .then((response: ApiResponse<Record<string, never>>) => {
            if (response && response.meta?.code >= 400) {
              throw new Error();
            }
            addToCart({ requestBody, closeModalOverride: exit, keepShoppingOverride: exit });
          })
          .catch((errors) => {
            setErrorMessages(appDispatch, errors.errors);
            trackAddToCartError(t('previewView.toastErrorDescription'));
            setIsToasterOpen(appDispatch, {
              title: t('previewView.toastErrorTitle'),
              children: t('previewView.toastErrorDescription'),
              variant: ToastVariants.Error,
            });
          });

        setIsDialogOpen(false);
      }
    },
    [productId, coverPreviewUrl, projectId, addressData.recipient, appState.isProjectSaved, images],
  );

  return { handleSubmit };
};
