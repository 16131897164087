import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@hallmark/web.core.display.image';
import { ENVELOPES } from './constants';
import styles from './styles.module.scss';
import { PreviewProps } from './types';

// EnvelopePreview Component to preview the selected envelope image in the envelope selection card
export const EnvelopePreview = ({ selectedEnvelope }: PreviewProps) => {
  const { t } = useTranslation();

  const currentEnvelope = ENVELOPES.find((envelope) => envelope.id === selectedEnvelope);

  return (
    <div
      className={styles['envelope-preview']}
      data-testid={`envelope-preview${selectedEnvelope !== undefined ? '-' + selectedEnvelope : ''}`}
    >
      {currentEnvelope && (
        <Image
          key={currentEnvelope.id}
          src={currentEnvelope.image}
          alt={t(`envelopeView.${currentEnvelope.id}EnvelopeLabel`)}
        />
      )}
    </div>
  );
};
