import { hideLoadingScreen } from '../../context/app-context';
import { Dispatch as AppDispatch } from '../../context/app-context/app-context-types';
import { setCoverPreviewUrl, setThumbnailUrl } from '../../context/card-context/card-context-actions';
import { Dispatch as CardDispatch } from '../../context/card-context/card-context-types';
import { CardType, InitializationData } from '../../global-types';
import { LinkedAsset, LinkedAssets, PreviewImage } from '../../global-types/images';
import { linkAssets } from '../../services/image';
import { getAssetType } from './get-asset-type';

/**
 * After all preview images are uploaded to S3, save linked assets to get smaller preview image for carts
 *
 * @param assetStorageUrls A list of presigned S3 URLs to which we can upload one asset per URL
 * @param projectId id of project
 * @param cardDispatch Dispatch to update card state
 * @param appDispattch Dispatch to update app state
 * @param isOneToMany whether card is one-to-many
 * @param previewImages an array of preview images

 * @returns void
 */
export const saveLinkedAssets = async (
  assetStorageUrls,
  initializedData: InitializationData,
  cardDispatch: CardDispatch,
  appDispatch: AppDispatch,
  isOneToMany: boolean,
  previewImages: PreviewImage[],
) => {
  // Loop through assetStorageUrls, split to get image URL, then call
  const assetsToLink: LinkedAsset[] = [];
  if (assetStorageUrls && assetStorageUrls.data && assetStorageUrls.data.length > 0) {
    assetStorageUrls.data.forEach((url, index) => {
      const [splitUrl] = url.split('?');
      const asset = {
        asset_type_code: getAssetType(initializedData.project_type_code as CardType, index, isOneToMany),
        url: splitUrl,
      };
      assetsToLink.push(asset);
    });
  }
  linkAssets(assetsToLink, initializedData.project_id).then((linkedAssetsResponse: LinkedAssets) => {
    // We now have preview image URLs
    // Associate preview image URLs with preview images. DG needs URLs for preview animation
    for (const image of previewImages) {
      if (image.type === 'Front') {
        image.url = linkedAssetsResponse.data.find((el) => el.asset_type_code === 'F')?.url;
      } else if (image.type === 'Inside') {
        image.url = linkedAssetsResponse.data.find((el) => el.asset_type_code === 'I')?.url;
      }
    }
    // Preview assets are uploaded to S3 and linked, so show address form
    hideLoadingScreen(appDispatch);
    // asset_type_code "P" (preview) asset from linkedAssets is for cart/saved-projects previews
    const previewAsset = linkedAssetsResponse.data.find((el) => el.asset_type_code === 'P');
    if (previewAsset) {
      setCoverPreviewUrl(cardDispatch, previewAsset.url);
      setThumbnailUrl(cardDispatch, previewAsset?.resize_urls?.S || '');
    } else {
      // For S&S and as a fallback, preview asset is cardFace 0's background image
      setCoverPreviewUrl(cardDispatch, initializedData.variables.template_data.Faces[0].BackgroundUrl);
    }
  });
};
