/**
 * Remove suggestions from a text object
 * @param textObject the text object to remove suggestions from
 */
export const removeSuggestions = (textObject: fabric.Object) => {
  textObject.set({
    data: {
      ...textObject.data,
      suggestions: null,
    },
  });
};
