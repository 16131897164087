import { ProjectTypeCode } from '../global-types';

// mock data will go here
export const initializeResponse = {
  meta: {
    code: 201,
    version: '68ee2554',
  },
  data: {
    project_id: '5b2e8554-3740-4796-86cd-d0754f4ccaa3',
    account_id: '2125518956',
    name: 'POD Project',
    product_id: '2PGM1238',
    scan_code: '0002394021',
    version: 1,
    is_digital_fulfillment: false,
    is_draft: false,
    expiration_date: '2023-04-14T19:30:22.051960811Z',
    project_type_code: 'P' as ProjectTypeCode,
    project_status_code: 'C',
    created_at: '2023-04-07T19:30:22.051984335Z',
    last_updated_at: '2023-04-07T19:30:22.051985095Z',
    purchase_quantity: 1,
    font_collection: {
      default_size: 55,
      default_color: '#000000',
      fonts: [
        {
          id: 101,
          name: 'Simply Yours',
          url: 'https://content.dev.hallmark.com/POD_Fonts/108317.ttf',
        },
        {
          id: 102,
          name: 'Grateful for You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/126056.ttf',
        },
        {
          id: 103,
          name: 'Warmest Wishes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BerdingScript.ttf',
        },
        {
          id: 104,
          name: 'Yours Always',
          url: 'https://content.dev.hallmark.com/POD_Fonts/TuesdayHMK-MGE.ttf',
        },
        {
          id: 105,
          name: 'All My Best',
          url: 'https://content.dev.hallmark.com/POD_Fonts/KrickHMK-Regular.ttf',
        },
        {
          id: 106,
          name: 'Take It Easy',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JohnsonBallpointPen.ttf',
        },
        {
          id: 107,
          name: 'Hey Sunshine',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AnnettePrintMGE-Regular.ttf',
        },
        {
          id: 108,
          name: 'Stay Strong',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JasonPrint.ttf',
        },
        {
          id: 109,
          name: "'Til Next Time",
          url: 'https://content.dev.hallmark.com/POD_Fonts/126059.ttf',
        },
        {
          id: 110,
          name: 'Catch You Later',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JohnsonPrint.ttf',
        },
        {
          id: 111,
          name: 'Keep in Touch',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JenniferPrintLight.ttf',
        },
        {
          id: 112,
          name: 'Hugs to You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BrentPrint.ttf',
        },
        {
          id: 113,
          name: 'Kind Regards',
          url: 'https://content.dev.hallmark.com/POD_Fonts/TypewriteWornOneHMK.ttf',
        },
        {
          id: 114,
          name: 'Buh-Bye',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AmbergerSansTextA.ttf',
        },
        {
          id: 115,
          name: 'Cheers to You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BeamNewHMK-Regular.ttf',
        },
        {
          id: 116,
          name: 'Later Gator',
          url: 'https://content.dev.hallmark.com/POD_Fonts/CrayottBookKB.ttf',
        },
        {
          id: 117,
          name: 'WHAT’S UP',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AlmondMilkHMK-Regular.ttf',
        },
        {
          id: 119,
          name: 'Just Saying',
          url: 'https://content.dev.hallmark.com/POD_Fonts/SarahndipityHMK-Smooth.ttf',
        },
        {
          id: 120,
          name: 'OMG Hi',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BeamNewHMK-Bold.ttf',
        },
        {
          id: 121,
          name: "How Ya Doin'",
          url: 'https://content.dev.hallmark.com/POD_Fonts/HelloOne-HMK.ttf',
        },
        {
          id: 122,
          name: 'Just a Note',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AstaSlabHMK-Medium.ttf',
        },
        {
          id: 123,
          name: 'Keep Smiling',
          url: 'https://content.dev.hallmark.com/POD_Fonts/MiziletteHMK-SemiBoldUpright.ttf',
        },
        {
          id: 124,
          name: 'Sincerely',
          url: 'https://content.dev.hallmark.com/POD_Fonts/QueensHatHMK-Italic.ttf',
        },
        {
          id: 125,
          name: 'Hiya Pal',
          url: 'https://content.dev.hallmark.com/POD_Fonts/MichaelaVFHMK.ttf',
        },
        {
          id: 126,
          name: 'Be Seeing You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/FieldnotesHMK-Rough.ttf',
        },
        {
          id: 127,
          name: 'Good Vibes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/GretaHMK-Regular.ttf',
        },
        {
          id: 128,
          name: 'Best Wishes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BernhardFashionOnePKA.ttf',
        },
        {
          id: 129,
          name: 'Hang Loose',
          url: 'https://content.dev.hallmark.com/POD_Fonts/RittenPrintLowRiseHMK-Regular.ttf',
        },
        {
          id: 130,
          name: 'Much Appreciated',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BethelHMK-Regular.ttf',
        },
      ],
    },
    product: {
      product_id: '2PGM1238',
      template_id: 'PGM1238',
      product_name: 'Personalized Photo Collage Photo Card',
      vendor_lead_time: 1,
      envelope_color: '#FFFFF',
      price_book: {
        price: 2.99,
        price_tiers: [
          {
            price: 2.99,
            quantity: 1,
          },
          {
            price: 2.49,
            quantity: 21,
          },
          {
            price: 1.99,
            quantity: 51,
          },
          {
            price: 1.79,
            quantity: 81,
          },
          {
            price: 1.49,
            quantity: 101,
          },
        ],
      },
    },
    fulfillment: {},
    variables: {
      template_data: {
        CardFormat: 'portrait',
        CardSize: '49',
        CardType: 'photo',
        DefaultTextArea: {
          FontFamily: 'AnnettePrintMGE-Regular',
          FontSize: 28,
          IsFixed: false,
          IsHybrid: true,
          IsMultiline: true,
          Rotation: 0,
          Text: 'Double click to edit',
          TextAlign: 'left',
          TextColor: '#000000',
          MustEditTextArea: false,
        },
        Dimensions: {
          Height: 179,
          Width: 125,
        },
        Faces: [
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 1476,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 1,
            FrameUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Frame.png',
            IsEditable: true,
            OverlayBackgroundUrl: '',
            PhotoZones: [
              {
                Height: 652.9983,
                LeftPosition: 106.77853,
                Rotation: 353.75,
                TopPosition: 233.6771,
                Width: 590.9988,
              },
              {
                Height: 681.999,
                LeftPosition: 762.4272,
                Rotation: 10,
                TopPosition: 681.0872,
                Width: 594.52795,
              },
              {
                Height: 673.9982,
                LeftPosition: 66.857346,
                Rotation: 351.25,
                TopPosition: 1203.9602,
                Width: 589.57086,
              },
            ],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [
              {
                FontFamily: 'OMG Hi',
                FontId: 120,
                FontSize: 16,
                Height: 404.20428,
                IsFixed: true,
                IsHybrid: false,
                IsMultiline: true,
                LeftPosition: 753.5654,
                Rotation: 0,
                Text: 'ALWAYS \nGOING TO \nLOVE THESE \nMEMORIES\nOF US.',
                TextAlign: 'center',
                TextColor: '#FFFFFF',
                TopPosition: 221.56609,
                Width: 566.99884,
                MustEditTextArea: false,
              },
            ],
            Type: 'front',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P2-3_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 2870,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 2,
            FrameUrl: '',
            IsEditable: true,
            OverlayBackgroundUrl: '',
            PhotoZones: [],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P2-3_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [],
            Type: 'inside',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P4_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 1394,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 3,
            FrameUrl: '',
            IsEditable: false,
            OverlayBackgroundUrl: '',
            PhotoZones: [],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P4_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [],
            Type: 'back',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
        ],
        Name: 'PGM1238',
        OpenOrientation: 'right',
        ParentDimensions: {
          Height: 179,
          Width: 125,
        },
        IsFrontOfCardNotEditable: false,
        IsInsideOfCardNotEditable: false,
      },
    },
  },
};

// mock data for Digital Greetings cards
export const initializeResponseDG = {
  meta: {
    code: 201,
    version: '68ee2554',
  },
  data: {
    project_id: '5b2e8554-3740-4796-86cd-d0754f4ccaa3',
    account_id: '2125518956',
    name: 'POD Project',
    product_id: '2PGM1238',
    scan_code: '0002394021',
    version: 1,
    is_digital_fulfillment: false,
    expiration_date: '2023-04-14T19:30:22.051960811Z',
    project_type_code: 'P' as ProjectTypeCode,
    project_status_code: 'C',
    created_at: '2023-04-07T19:30:22.051984335Z',
    last_updated_at: '2023-04-07T19:30:22.051985095Z',
    font_collection: {
      default_size: 55,
      default_color: '#000000',
      fonts: [
        {
          id: 101,
          name: 'Simply Yours',
          url: 'https://content.dev.hallmark.com/POD_Fonts/108317.ttf',
        },
        {
          id: 102,
          name: 'Grateful for You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/126056.ttf',
        },
        {
          id: 103,
          name: 'Warmest Wishes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BerdingScript.ttf',
        },
        {
          id: 104,
          name: 'Yours Always',
          url: 'https://content.dev.hallmark.com/POD_Fonts/TuesdayHMK-MGE.ttf',
        },
        {
          id: 105,
          name: 'All My Best',
          url: 'https://content.dev.hallmark.com/POD_Fonts/KrickHMK-Regular.ttf',
        },
        {
          id: 106,
          name: 'Take It Easy',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JohnsonBallpointPen.ttf',
        },
        {
          id: 107,
          name: 'Hey Sunshine',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AnnettePrintMGE-Regular.ttf',
        },
        {
          id: 108,
          name: 'Stay Strong',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JasonPrint.ttf',
        },
        {
          id: 109,
          name: "'Til Next Time",
          url: 'https://content.dev.hallmark.com/POD_Fonts/126059.ttf',
        },
        {
          id: 110,
          name: 'Catch You Later',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JohnsonPrint.ttf',
        },
        {
          id: 111,
          name: 'Keep in Touch',
          url: 'https://content.dev.hallmark.com/POD_Fonts/JenniferPrintLight.ttf',
        },
        {
          id: 112,
          name: 'Hugs to You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BrentPrint.ttf',
        },
        {
          id: 113,
          name: 'Kind Regards',
          url: 'https://content.dev.hallmark.com/POD_Fonts/TypewriteWornOneHMK.ttf',
        },
        {
          id: 114,
          name: 'Buh-Bye',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AmbergerSansTextA.ttf',
        },
        {
          id: 115,
          name: 'Cheers to You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BeamNewHMK-Regular.ttf',
        },
        {
          id: 116,
          name: 'Later Gator',
          url: 'https://content.dev.hallmark.com/POD_Fonts/CrayottBookKB.ttf',
        },
        {
          id: 117,
          name: 'WHAT’S UP',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AlmondMilkHMK-Regular.ttf',
        },
        {
          id: 119,
          name: 'Just Saying',
          url: 'https://content.dev.hallmark.com/POD_Fonts/SarahndipityHMK-Smooth.ttf',
        },
        {
          id: 120,
          name: 'OMG Hi',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BeamNewHMK-Bold.ttf',
        },
        {
          id: 121,
          name: "How Ya Doin'",
          url: 'https://content.dev.hallmark.com/POD_Fonts/HelloOne-HMK.ttf',
        },
        {
          id: 122,
          name: 'Just a Note',
          url: 'https://content.dev.hallmark.com/POD_Fonts/AstaSlabHMK-Medium.ttf',
        },
        {
          id: 123,
          name: 'Keep Smiling',
          url: 'https://content.dev.hallmark.com/POD_Fonts/MiziletteHMK-SemiBoldUpright.ttf',
        },
        {
          id: 124,
          name: 'Sincerely',
          url: 'https://content.dev.hallmark.com/POD_Fonts/QueensHatHMK-Italic.ttf',
        },
        {
          id: 125,
          name: 'Hiya Pal',
          url: 'https://content.dev.hallmark.com/POD_Fonts/MichaelaVFHMK.ttf',
        },
        {
          id: 126,
          name: 'Be Seeing You',
          url: 'https://content.dev.hallmark.com/POD_Fonts/FieldnotesHMK-Rough.ttf',
        },
        {
          id: 127,
          name: 'Good Vibes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/GretaHMK-Regular.ttf',
        },
        {
          id: 128,
          name: 'Best Wishes',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BernhardFashionOnePKA.ttf',
        },
        {
          id: 129,
          name: 'Hang Loose',
          url: 'https://content.dev.hallmark.com/POD_Fonts/RittenPrintLowRiseHMK-Regular.ttf',
        },
        {
          id: 130,
          name: 'Much Appreciated',
          url: 'https://content.dev.hallmark.com/POD_Fonts/BethelHMK-Regular.ttf',
        },
      ],
    },
    product: {
      product_id: '2PGM1238',
      template_id: 'PGM1238',
      product_name: 'Personalized Photo Collage Photo Card',
      vendor_lead_time: 1,
      envelope_color: '#FFFFF',
    },
    fulfillment: {},
    variables: {
      template_data: {
        CardFormat: 'portrait',
        CardSize: '49',
        CardType: 'photo',
        DefaultTextArea: {
          FontFamily: 'AnnettePrintMGE-Regular',
          FontSize: 28,
          IsFixed: false,
          IsHybrid: true,
          IsMultiline: true,
          Rotation: 0,
          Text: 'Double click to edit',
          TextAlign: 'left',
          TextColor: '#000000',
          MustEditTextArea: false,
        },
        Dimensions: {
          Height: 179,
          Width: 125,
        },
        Faces: [
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 1476,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 1,
            FrameUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Frame.png',
            IsEditable: true,
            OverlayBackgroundUrl: '',
            PhotoZones: [
              {
                Height: 652.9983,
                LeftPosition: 106.77853,
                Rotation: 353.75,
                TopPosition: 233.6771,
                Width: 590.9988,
              },
              {
                Height: 681.999,
                LeftPosition: 762.4272,
                Rotation: 10,
                TopPosition: 681.0872,
                Width: 594.52795,
              },
              {
                Height: 673.9982,
                LeftPosition: 66.857346,
                Rotation: 351.25,
                TopPosition: 1203.9602,
                Width: 589.57086,
              },
            ],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P1_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [
              {
                FontFamily: 'OMG Hi',
                FontId: 120,
                FontSize: 16,
                Height: 404.20428,
                IsFixed: true,
                IsHybrid: false,
                IsMultiline: true,
                LeftPosition: 753.5654,
                Rotation: 0,
                Text: 'ALWAYS \nGOING TO \nLOVE THESE \nMEMORIES\nOF US.',
                TextAlign: 'center',
                TextColor: '#FFFFFF',
                TopPosition: 221.56609,
                Width: 566.99884,
                MustEditTextArea: false,
              },
            ],
            Type: 'front',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P2-3_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 2870,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 2,
            FrameUrl: '',
            IsEditable: true,
            OverlayBackgroundUrl: '',
            PhotoZones: [],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P2-3_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [],
            Type: 'inside',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
          {
            BackgroundUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P4_Background.png',
            CanvasJson: null,
            Dimensions: {
              Height: 2114,
              Width: 1394,
            },
            DisplayDefaultTextArea: false,
            EditableAreas: [],
            FaceId: 3,
            FrameUrl: '',
            IsEditable: false,
            OverlayBackgroundUrl: '',
            PhotoZones: [],
            PreviewUrl: 'https://content.dev.hallmark.com/webassets/PGM1238/PGM1238_P4_Preview.png',
            ReplaceBackgroundUrl: '',
            Texts: [],
            Type: 'back',
            UserImages: null,
            UserTextZones: [],
            CanAddPhoto: true,
            CanAddText: true,
            DisplayIndicator: true,
            PreviewDisplayIndicator: true,
          },
        ],
        Name: 'PGM1238',
        OpenOrientation: 'right',
        ParentDimensions: {
          Height: 179,
          Width: 125,
        },
        IsFrontOfCardNotEditable: false,
        IsInsideOfCardNotEditable: false,
      },
    },
  },
};
