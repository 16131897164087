/**
 * Update the position of an icon when the parent object is moved.
 * @param icon - fabric object
 * @param parentObject - fabric object
 * @param offset - number
 */
export const handleImageWithIconMoves = (icon: fabric.Object, parentObject: fabric.Object, offset: number): void => {
  if (icon.left && icon.top && parentObject.left && parentObject.top) {
    if (parentObject.left > 0) {
      icon.left = parentObject.left + offset;
    }
    if (parentObject.top > 0) {
      icon.top = parentObject.top + offset;
    }
    icon.setCoords();
  }
};
