import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { addPhotoTrayImages, setIsToasterOpen, increasePagePhotoTrayImages } from '../context/app-context';
import { ImageResponse } from '../global-types';
import { getPhotos, getPhotosPagination } from '../services/image';
import { usePhotoTrayImages } from './usePhotoTrayImages';

export const usePhotoDrawerInfiniteScroll = (
  appDispatch: any,
  isImageUploadDrawerOpen: boolean,
  isImageUploadToPhotoZoneOpen: boolean,
  setHasMoreImages: (isUploading: boolean) => void,
  hasMoreImages: boolean,
  IS_DRAWER_PAGINATION_ENABLED: boolean,
  page: number,
  observerTarget: React.RefObject<HTMLElement>,
) => {
  const { t } = useTranslation();
  const { onUploadImages, photoTrayImages, setIsUploading, isUploading } = usePhotoTrayImages();
  const [totalPages, setTotalPages] = useState(1);
  const fetchPhotoTrayImages = useRef<boolean>(photoTrayImages.length === 0);
  const [photoTrayImagesFinal, setPhotoTrayImages] = useState<ImageResponse[]>([]);
  const deletedImageIdsRef = useRef<Set<string>>(new Set());
  const isAnyDrawerOpen = isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen;

  useEffect(() => {
    setPhotoTrayImages(photoTrayImages.filter((image) => !deletedImageIdsRef.current.has(image.photo_tray_image_id)));
  }, [photoTrayImages]);

  const processResponse = (response: any) => {
    const responseData = response.data?.paging;

    /** Service getPhotosPagination */
    if (responseData) {
      const totalPages = response.data?.paging?.total_pages || 1;
      const totalItems = response.data?.paging?.total_items || 0;

      if (totalItems === photoTrayImagesFinal.length) {
        setHasMoreImages(false);
        return;
      }

      if (!response.data || response?.data?.photo_tray_images === null || !isAnyDrawerOpen) {
        setHasMoreImages(false);
        return;
      }

      setTotalPages(totalPages);

      if (page > totalPages) {
        setHasMoreImages(false);
        return;
      }

      if (page < totalPages) {
        increasePagePhotoTrayImages(appDispatch);
      } else {
        setHasMoreImages(false);
      }

      addPhotoTrayImages(appDispatch, [...response.data.photo_tray_images]);
      return;
    }

    /** Service getPhotos */
    if (fetchPhotoTrayImages.current) {
      setIsUploading(true);
      if (response.data && response.data.length > 0) {
        addPhotoTrayImages(appDispatch, [...response.data]);
        setHasMoreImages(false);
      }
      fetchPhotoTrayImages.current = false;
    }
  };

  const handleDeleteImageLocally = (photoId: string) => {
    deletedImageIdsRef.current.add(photoId);
    setPhotoTrayImages((prevImages) => prevImages.filter((image) => image.photo_tray_image_id !== photoId));
  };

  /** Error handling */
  const handleFetchError = (error: any) => {
    setIsToasterOpen(appDispatch, {
      title: t('imageUploadDrawer.imageUploadErrorTitle'),
      children: t('imageUploadDrawer.imageUploadError'),
      variant: ToastVariants.Error,
    });
    // eslint-disable-next-line no-console
    console.error('Error loading photos', error);
    setHasMoreImages(false);
  };

  const loadMore = useCallback(
    (page: number) => {
      if (!hasMoreImages || isUploading) return;

      setIsUploading(true);

      const fetchPhotos = IS_DRAWER_PAGINATION_ENABLED
        ? getPhotosPagination(page)
        : getPhotos(); /** Logic for loading all images at once */

      fetchPhotos
        .then(processResponse)
        .catch(handleFetchError)
        .finally(() => {
          setIsUploading(false);
        });
    },
    [appDispatch, hasMoreImages, isAnyDrawerOpen, isUploading, setIsUploading, photoTrayImages],
  );

  useEffect(() => {
    if (!isAnyDrawerOpen || !observerTarget.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isUploading) loadMore(page);
      },
      { threshold: 1 },
    );

    observer.observe(observerTarget.current);

    return () => observer.disconnect();
  }, [observerTarget, page, isAnyDrawerOpen, isUploading, loadMore]);

  return {
    loadMore,
    handleDeleteImageLocally,
    page,
    hasMoreImages,
    isUploading,
    setHasMoreImages,
    totalPages,
    setIsUploading,
    onUploadImages,
    photoTrayImagesFinal,
    setPhotoTrayImages,
  };
};
