/* eslint-disable arrow-body-style */

/* eslint-disable prefer-template */
import { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import API from '@dotcom/api-middleware';
import { AddToCartParams, AddToCartResponse, CartData, RecentBasket } from '../global-types';
import { fetchBasketContent } from '../services';
import { getMonolithUrl } from '../utils';
import { jsonToQueryString } from '../utils';
// import BRAddToCart from '../bloomreach/brtag_addtocart';
import { useCsrfToken } from './useCsrfToken';

const headers = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const monolithUrl = getMonolithUrl();
const BasketService = new API(monolithUrl, headers);

BasketService.updateDefaultConfig('withCredentials', true);

export const useBasket = () => {
  const [recentBasket, setBasketData] = useState<RecentBasket | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [basketError, setBasketError] = useState<boolean>(false);
  const [basket, setBasket] = useState<string>('');
  const [closeModalOverride, setCloseModalOverride] = useState<() => void>();
  const [keepShoppingOverride, setKeepShoppingOverride] = useState<() => void>();
  const [cartData, setCartData] = useState<CartData | null>(null);
  const csrfToken = useCsrfToken();
  const [cookies] = useCookies(['sourcecode']);

  const addProductUrl = process.env.REACT_APP_ADD_PRODUCT_URL;

  const processCart = useCallback(() => {
    if (!csrfToken || !cartData) {
      setBasketError(true);
      setLoading(false);
      setCartData(null);
    } else {
      const request = {
        ...cartData.requestBody,
        csrf_token: csrfToken,
      };

      // Formatting request with querystring to prevent CORS errors
      BasketService.post(addProductUrl, jsonToQueryString(request))
        .then((response: AddToCartResponse) => {
          // bloomreach
          // try {
          //   BRAddToCart();
          // } catch (e) {
          //   console.error(e);
          // }
          // fetch basket
          if (!response || !response.line_item_added) {
            throw new Error();
          }
          fetchBasketContent(cookies.sourcecode)
            .then((basketContent) => {
              if (basketContent && basketContent.totalQty) {
                setBasket(basketContent.totalQty);
                setBasketData({ ...response, ...cartData.cartOptions });
                setLoading(false);
              } else {
                throw new Error();
              }
            })
            .catch(() => {
              setBasketError(true);
              setLoading(false);
            });
        })
        .catch(() => {
          setBasketError(true);
          setLoading(false);
        });
    }
  }, [csrfToken, cookies, cartData]);

  useEffect(() => {
    if (loading && cartData && csrfToken) {
      processCart();
    }
  }, [csrfToken, loading, cartData, processCart]);

  const addToCart = ({ requestBody, closeModalOverride, keepShoppingOverride, cartOptions = {} }: AddToCartParams) => {
    setBasketError(false);
    setBasketData(null);
    setCloseModalOverride(() => closeModalOverride);
    setKeepShoppingOverride(() => keepShoppingOverride);
    setCartData({ requestBody, cartOptions });
    setLoading(true);
  };

  return {
    loading,
    recentBasket,
    basketError,
    addToCart,
    basket,
    setBasket,
    closeModalOverride,
    keepShoppingOverride,
  };
};
