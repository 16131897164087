import { NavigateFunction } from 'react-router-dom';

export const replaceInitializationUrl = (projectId: string, queryParams, navigate: NavigateFunction) => {
  queryParams.delete('product_type_code');
  navigate(
    {
      pathname: `/card/customization/edit/${projectId}`,
      search: queryParams.toString(),
    },
    { replace: true },
  );
};
