import { GetElementPositionProps } from '../analytics-context-types';

export const getElementPosition = ({ paramsElement, object, face }: GetElementPositionProps): string => {
  const { cardState, initializedDataState, activeCardFace, canvas } = paramsElement ?? {};
  let cardSide: string | undefined;
  let cardHalfSize: number;
  const { cardFormat: format } = cardState || {};

  const isSignSend = initializedDataState?.data?.project_type_code === 'S';
  const cardFaceType = face || activeCardFace?.type;
  const activeObject = object ? object : canvas?.current?.getActiveObject();
  const canvasWidth = canvas?.current?.getWidth() ?? 0;
  const canvasHeight = canvas?.current?.getHeight() ?? 0;
  if (activeObject) {
    if (cardFaceType === 'front') {
      return 'outside-front';
    } else if (cardFaceType === 'back') {
      return 'outside-back';
    } else if (format === 'portrait' && !isSignSend) {
      cardHalfSize = canvasWidth / 2;
      cardSide = (activeObject.left as number) >= cardHalfSize ? 'right' : 'left';
    } else if (format === 'landscape' || format === 'vertical') {
      cardHalfSize = canvasHeight / 2;
      cardSide = (activeObject.top as number) >= cardHalfSize ? 'bottom' : 'top';
    }
    return `${cardFaceType}${cardSide ? `-${cardSide}` : ''}`;
  }
  return cardFaceType || '';
};
