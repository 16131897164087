import { getUneditedTextBoxWarning } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const uneditedTextBoxWarningTag = (options: getUneditedTextBoxWarning) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const modalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: 'text',
        eventAction: 'edit text-error',
      },
    },
  ];

  const level2 = getModalLevel2(options.productType);
  const { modal, user } = getModalTag(options.productType, level2, modalEvent, options.projectID);

  // Adds message from props to modal tag.
  const newModal = {
    ...modal,
    messages: options.statusMessage.map((message) => ({ statusMessages: message })),
  };

  return {
    modal: newModal,
    product,
    user,
  };
};
