import { useIsOneToMany } from './use-is-one-to-many';
import { useFeatureFlags } from './useFeatureFlags';

export const useMultiAddresses = () => {
  const isOneToMany = useIsOneToMany();
  const { IS_MULT_ADDRESS_ENABLED, IS_ENVELOPE_PRINTING_OPTIONS_ENABLED } = useFeatureFlags();

  return {
    shouldUseMultiAddresses: IS_MULT_ADDRESS_ENABLED && isOneToMany,
    shouldUseEnvelopePrintingOptions: IS_ENVELOPE_PRINTING_OPTIONS_ENABLED && isOneToMany,
  };
};
