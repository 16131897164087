import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@hallmark/web.core.buttons.button';
import {
  IconButton,
  IconButtonBrandColors,
  IconButtonVariants,
  IconNames,
} from '@hallmark/web.core.buttons.icon-button';
import { HallmarkCrown, InlineSVG } from '@hallmark/web.core.display.inline-svg';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList } from '../../global-types';
import { useEnabledRoutes, useHeaderClick, useSaveProjectDialog } from '../../hooks';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { config } from '../../regional-config';
import { isLoggedIn } from '../../utils';
import { Quantity } from '../quantity';
import { SaveProjectDialog } from '../save-project-dialog';
import { SaveProjectLink } from '../save-project-link';
import { PrintOnDemandHeaderProps } from './print-on-demand-header-types';
import styles from './print-on-demand-header.module.scss';

const PrintOnDemandHeader = ({ onClose, isEditor, onReturnToHomePage, isUK }: PrintOnDemandHeaderProps) => {
  const { isEnabledRoutes } = useEnabledRoutes();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { initializedDataState } = useInitializationDataContext();
  const { region } = initializedDataState;
  const {
    saveProjects: { isSavedProjectsEnabled },
  } = config;
  const classes = classNames(styles.header, isEditor && styles.editor);
  const headerParentRef = useHeaderClick();

  const {
    isSaveProjectDialogOpen,
    isAbandoningProject,
    onCloseDialog,
    onSignIn,
    onAbandonProject,
    onSaveProject,
    isExitFromXButton,
    setIsExitFromXButton,
  } = useSaveProjectDialog();

  // TODO: This should be re-added once the project summary is implemented for 1:1 cards (PRCM-3032)
  const showProjectSummary = useIsOneToMany();

  const onCrownIconClick = () => {
    setIsExitFromXButton(false);
    onReturnToHomePage();
  };

  const onCloseButtonClick = () => {
    setIsExitFromXButton(true);
    onClose(closeButtonRef.current);
  };

  const isSaveProjectLinkEnabled = isEnabledRoutes(['/edit/']) && isSavedProjectsEnabled;
  return (
    <div className={classes} ref={headerParentRef}>
      {showProjectSummary ? <Quantity /> : <div className={styles['quantity-link-placeholder']} />}
      <Button click={onCrownIconClick} addClass={styles.crown} testId="crown-icon-button" tabIndex={0}>
        <InlineSVG
          path={`${HallmarkCrown}`}
          title={`${t('header.hallmarkCrownLogo')}`}
          description={`${t('header.hallmarkCrownLogo')}`}
          role="img"
          svgColor={isUK ? IconButtonBrandColors.Black : IconButtonBrandColors.Purple}
        />
      </Button>

      <div className={`${styles['save-project']}`}>
        {isSaveProjectLinkEnabled ? <SaveProjectLink /> : <div className={styles['save-project']} />}

        <IconButton
          ariaLabel={`${t('header.close')}`}
          testId="close-icon-button"
          icon={IconNames.Menuclose}
          addClass={styles['close-button']}
          variant={IconButtonVariants.Simple}
          size={!isMobile ? 28 : 24}
          ref={closeButtonRef}
          click={onCloseButtonClick}
          altText={`${t('header.closeButton')}`}
        />
      </div>

      {isSaveProjectDialogOpen && (
        <SaveProjectDialog
          isOpen={isSaveProjectDialogOpen}
          close={onCloseDialog}
          isLoggedIn={isLoggedIn()}
          isAbandoningProject={isAbandoningProject}
          onSaveProject={(data) => onSaveProject(data, isExitFromXButton)}
          onSignIn={onSignIn}
          onAbandonProject={onAbandonProject}
          theme={region || RegionalCodesList.us} // region will fallback to 'us' if process.env.REACT_APP_THEME is not set
        />
      )}
    </div>
  );
};

const MemoizedPrintOnDemandHeader = React.memo(PrintOnDemandHeader);

export { MemoizedPrintOnDemandHeader as PrintOnDemandHeader };
