import { CardContextState } from '../../../context/card-context';
import { InitializationDataContextState } from '../../../context/data-context';
import { CardFaceData } from '../../../global-types';
import { convertPointToPixel, getFontidByUrlName } from '../../../utils';
import { PlaceHolderSettings } from '../card-editor-types';
import { getPlaceholderLandscapeSettings, getPlaceholderPortraitSettings, addPlaceholder } from '../card-editor-utils';

export const addPlaceholders = (
  face: CardFaceData,
  initializedDataState: InitializationDataContextState,
  cardState: CardContextState,
  isUK: boolean | undefined,
  isPodProductCode: boolean,
) => {
  if (face.type !== 'inside' || !isPodProductCode || face.editableAreas?.length || !face.canvas.current) {
    return;
  }
  const defaultFontSize = convertPointToPixel(
    initializedDataState.data?.variables.template_data.DefaultTextArea.FontSize || 50,
  );
  const defaultText = initializedDataState.data?.variables.template_data.DefaultTextArea.Text || '';
  const defaultTextAreaSettings = { ...initializedDataState.data?.variables.template_data.DefaultTextArea };
  const defaultParams = { cardState, currentCanvas: face.canvas.current, defaultFontSize };
  const settingsA = {};
  const settingsB = {};
  const defaultColor = defaultTextAreaSettings?.TextColor || initializedDataState.defaultColor;

  const { displayDefaultTextArea } = face;
  // Creates the object that contains the values received in the defaultTextAreaSettings object obtained from the initialize state,
  // based in the value of DisplayDefaultTextArea obtained from the faces data
  defaultTextAreaSettings.FontFamilyId = getFontidByUrlName(
    defaultTextAreaSettings?.FontFamily || '',
    initializedDataState.data?.font_collection.fonts,
  );
  const defaultOptions = displayDefaultTextArea ? defaultTextAreaSettings : {};

  if (cardState.cardFormat === 'landscape') {
    Object.assign(
      settingsA,
      getPlaceholderLandscapeSettings({
        ...defaultParams,
        placement: 'top',
        isUK,
        color: defaultColor,
        defaultOptions,
      }),
    );
    Object.assign(
      settingsB,
      getPlaceholderLandscapeSettings({
        ...defaultParams,
        placement: 'bottom',
        isUK,
        color: defaultColor,
        defaultOptions,
      }),
    );
  } else {
    Object.assign(
      settingsA,
      getPlaceholderPortraitSettings({
        ...defaultParams,
        placement: 'left',
        isUK,
        color: defaultColor,
        defaultOptions,
      }),
    );
    Object.assign(
      settingsB,
      getPlaceholderPortraitSettings({
        ...defaultParams,
        placement: 'right',
        isUK,
        color: defaultColor,
        defaultOptions,
      }),
    );
  }
  if (face.displayDefaultTextArea) {
    addPlaceholder(settingsA as PlaceHolderSettings, face.canvas.current, defaultText);
    addPlaceholder(settingsB as PlaceHolderSettings, face.canvas.current, defaultText);
  }
};
