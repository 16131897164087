import { useTranslation } from 'react-i18next';
import { AddressTypes, ErrorType } from '../../../global-types';
import { capitalize } from '../../../utils';
import {
  addressStatus,
  UseAddressValidiationTextReturn,
  UseAddressValidationTextProps,
} from '../address-confirmation-types';

/**
 * Concatenates and returns the validation title,body text, and address type to display based on the address status.
 * @param currentValidationAddress - The current address to validate
 * @returns title,body text, and address type to display based on the address status.
 */
export const useAddressValidationText = ({
  currentValidationAddress,
}: UseAddressValidationTextProps): UseAddressValidiationTextReturn => {
  const { t } = useTranslation();

  // Check if the address is incomplete or invalid, and set the status accordingly
  const isIncomplete = currentValidationAddress.errorType === ErrorType.Incomplete;
  const addressStatusStr = isIncomplete ? addressStatus.INCOMPLETE : addressStatus.INVALID;
  // Get the address type; Sender or Recipient, and display the appropriate message (Return for Sender, Recipient for Recipient)
  const addressType = currentValidationAddress.addressType;
  const addressTypeDisplay = addressType === AddressTypes.SENDER ? 'return' : addressType;
  // Concatenate validation title
  const validationTitle = t('addressConfirmation.addressConfirmationTitle', {
    addressStatus: capitalize(addressStatusStr),
    addressType: capitalize(addressTypeDisplay),
  });
  // Concactinate validation body text and display the appropriate message based on the address status
  const incompleteAddressMessage = t('addressConfirmation.incompleteAddressMessage', {
    addressOwner: addressTypeDisplay,
  });
  const invalidAddressMessage = t('addressConfirmation.invalidAddressMessage', { addressOwner: addressTypeDisplay });
  const validationBody = isIncomplete ? incompleteAddressMessage : invalidAddressMessage;

  return { validationTitle, validationBody, addressTypeDisplay };
};
