import { GetSaveQuickAddress } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getSaveQuickAddressTag = (options: GetSaveQuickAddress) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );
  const modalEvent = [
    {
      eventInfo: {
        eventName: 'Quick Addresses',
        eventAction: 'Save Address',
      },
    },
  ];

  // this condition we used to validate if come from form sender form.
  if (options.isSender) {
    modalEvent.unshift({
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    });
  }

  const level2 = options.isSender ? 'Preview' : 'Address the Envelope - Sender';

  const { modal, user } = getModalTag(options.productType, level2, modalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
