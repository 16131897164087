/**
 * A mapped object containing copies of canvas objects for each cardface,
 * the key is a cardFace's faceId so the objects can be easily mapped to each face
 * @example const photoZones = {'1': [PhotoZoneObject1, PhotoZoneObject2], '2': [PohotoZoneObject1]}
 */
export type CardFaceObjects = {
  [key: string]: fabric.Object[];
};

/**
 * Object containing a card-face's editable area's (user zones) buttons,
 * the key is a cardFace's faceId so the objects can be easily mapped to each face
 * @example const photoZones = {'1': [PhotoZoneObject1, PhotoZoneObject2], '2': [PohotoZoneObject1]}
 */
export type CardFaceEditableAreasObject = {
  [key: string]: {
    areas: fabric.Object[];
    buttons: fabric.Object[];
  };
};

/**
 * An object containing copies of the different canvas objects for a POD/DG card that need to be restored
 * after making a save-personalization API call.
 * @example
 * const podObjects = {photozoneButtons: {'1': [btn1, btn2, ...],}, editableAreas: {'1': [area1, ...],}}
 */
export type PODCanvasObjects = {
  photozoneButtons?: CardFaceObjects;
  editableAreas?: CardFaceEditableAreasObject;
  placeholders?: CardFaceObjects;
};

/**
 * An object containing copies of the different canvas objects for a S&S card that need to be restored
 * after making a save-personalization API call.
 * @example
 * const sasObjects = {phototextzones: {'1': [photoTextZone1, ...],}, phototextzoneImages: {'1': [image1, ...],}}
 */
export type SASCanvasObjects = {
  phototextzones?: CardFaceObjects;
  phototextzoneImages?: CardFaceObjects;
  phototextzoneButtons?: CardFaceObjects;
};

/**
 * An object containing copies of the canvas objects for POD/DG or S&S cards.
 * May be used to restore the original canvas state after saving the project.
 * @example
 * const canvasObjectsState = {type: 'P', objects: {photozoneButtons: {'1': [btn1, btn2, ...],}, editableAreas: {'1': [area1, ...],},}
 */
export type CanvasObjectsState = {
  projectType: 'S' | 'P' | 'D';
  canvasObjects: PODCanvasObjects | SASCanvasObjects | null;
};

export type SaveProjectOptions = {
  onSystemError: (error) => void;
  missingBackgroundImageError?: string;
};

export const DEFAULT_PROJECT_NAMES = {
  P: 'POD Project',
  D: 'Digital Greetings',
  S: 'S&S Project',
} as const;
