import { CanvasDataTypes } from '..';

/**
 * Function used to get the children object of a group by its name. Normally used for example to get the
 * user image or text object from a photo text zone.
 * @param name
 * @param currentGroup
 * @returns The object with the provided name from the group
 */

export const getGroupedItemByName = (name: CanvasDataTypes, currentGroup: fabric.Group): fabric.Object | undefined => {
  return currentGroup._objects.find((item) => item?.name?.includes(name));
};
