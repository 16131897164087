export const addressFormFieldList = [
  {
    typeOfField: 'textfield',
    name: 'first_name',
  },
  {
    typeOfField: 'textfield',
    name: 'last_name',
  },
  {
    typeOfField: 'textfield',
    name: 'address_line_1',
  },
  {
    typeOfField: 'collapse',
    collapse: true,
    nestedFields: [
      {
        typeOfField: 'textfield',
        name: 'address_line_2',
      },
      {
        typeOfField: 'textfield',
        name: 'company_name',
      },
    ],
  },
  {
    typeOfField: 'textfield',
    name: 'city',
  },
  {
    typeOfField: 'dropdown',
    name: 'state_code',
  },
  {
    typeOfField: 'textfield',
    name: 'zip',
  },
  {
    typeOfField: 'checkbox',
    name: 'isQuickAddress',
  },
];
