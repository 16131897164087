import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import HallmarkPlus from '../../../assets/hallmark-plus.svg';

export const useHplusContents = () => {
  const { t } = useTranslation();

  const secondContainerText = (
    <Typography variant={TypographyVariants.Body}>{t('noCrNoHDialog.secondContainerText')}</Typography>
  );

  const learnMoreButton = (
    <Button
      mode={ButtonModes.Secondary}
      click={() => window.location.replace('/hallmarkplus-account/')}
      testId="learn-more-dialog-button"
    >
      {t('noCrNoHDialog.secondContainerButtonText')}
    </Button>
  );

  const hPlusContainerContent = {
    image: HallmarkPlus,
    body: secondContainerText,
    btnAction: learnMoreButton,
  };

  return { hPlusContainerContent };
};
