import { fabric } from 'fabric';

/**
 * This function ensures that when the user clicks on the textTab the text box (Add Text) is selected.
 * @param canvas
 * @param isMobileApp
 */
export const handleTextTabClick = (canvas: fabric.Canvas | null, isMobileApp: boolean) => {
  if (canvas && isMobileApp) {
    const activeText: fabric.Object | null = canvas.getActiveObject();

    // If the text box exists and if the text box is editable then it is edited.
    if (activeText && activeText.type === 'textbox' && (activeText as fabric.Textbox).editable) {
      const textbox = activeText as fabric.Textbox;

      // Enters edit mode
      textbox.enterEditing();
      textbox.selectAll();

      // The canvas is updated to reflect the changes.
      canvas.requestRenderAll();

      // Focus on Fabric.js editing element
      if (textbox.hiddenTextarea) {
        textbox.hiddenTextarea.focus();
      }
    }
  }
};

/**
 * This function ensures that when the user clicks on the Drawer options (Font, Font Size, Align) in desktop mode the focus is not lost.
 * @param canvas
 * @param isMobile
 * @param handleTextTabClick
 */
export const handleFocus = (
  canvas: fabric.Canvas | null,
  isMobile: boolean,
  handleTextTabClick: (canvas: fabric.Canvas | null, isMobileApp: boolean) => void,
) => {
  if (canvas && !isMobile) {
    handleTextTabClick(canvas, true);
  }
};
