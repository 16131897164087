import API from '@dotcom/api-middleware';
import { MiniCart } from '../global-types';
import { getMonolithUrl } from '../utils';

export const fetchBasketContent = (sourcecode?: string): Promise<MiniCart> => {
  const headers = {
    Accept: 'application/json',
  };
  const BasketService = new API(getMonolithUrl(), headers);
  BasketService.updateDefaultConfig('withCredentials', true);

  return new Promise((resolve) => {
    BasketService.get(`/mini-cart${sourcecode ? `?src=${sourcecode}` : ''}`).then((response: MiniCart) =>
      resolve(response),
    );
  });
};
