import { RegionalCodesList } from '../global-types';
import { configCE } from './ce/config';
import { ApplyStyleConfig, RegionalSchema, SupportedRegionsList } from './regional-config-types';
import { configUK } from './uk/config';
import { configUS } from './us/config';

const REGIONS: SupportedRegionsList = {
  us: configUS,
  uk: configUK,
  ce: configCE,
};

export const isRegionValid = (region: string): boolean => {
  return Object.prototype.hasOwnProperty.call(REGIONS, region);
};

export const getRegionalConfig = (): RegionalSchema => {
  const currentRegion = process.env.REACT_APP_THEME as string;

  // Validate that the region is supported
  if (!isRegionValid(currentRegion)) {
    throw new Error(`Current region is unsupported: ${currentRegion}`);
  }

  return REGIONS[currentRegion as keyof SupportedRegionsList];
};

export const isOptionValid = (config: RegionalSchema, option: string): boolean => {
  return config !== undefined && option in config;
};

export const getConfigOption = (option: string) => {
  const currentRegion = process.env.REACT_APP_THEME as string;
  const regionalConfig = getRegionalConfig() as RegionalSchema;

  if (!isOptionValid(regionalConfig, option)) {
    // eslint-disable-next-line no-console
    console.warn(`Option ${option} is not valid for region: ${currentRegion}`);
    return null;
  }

  return (regionalConfig as RegionalSchema)[option as keyof RegionalSchema];
};

export const getCurrentRegion = (): string => {
  const currentRegion = process.env.REACT_APP_THEME as string;
  return isRegionValid(currentRegion) ? currentRegion : RegionalCodesList.us;
};

/**
 * Apply styles from a configuration object to the root document element.
 * @param {Object} styleConfig - An object where keys are CSS variable names (without the '--' prefix) and values are the CSS values.
 */

export const applyScssStyles = (styleConfig: ApplyStyleConfig): void => {
  Object.entries(styleConfig).forEach(([key, value]: [string, string]) => {
    document.documentElement.style.setProperty(`--${key}`, value);
  });
};
