import { isMobile } from 'react-device-detect';
import { fabric } from 'fabric';
import { BrandColors } from '@hallmark/web.styles.colors';
import addPhotoIconUK from '../../assets/camera-icon-button-48-uk.svg';
import addPhotoIcon from '../../assets/camera-icon-button-48.svg';
import editIconSVG from '../../assets/edit-icon-button-48.svg';
import { CardContextState } from '../../context/card-context';
import { CardEditableArea, CardFaceData, CardText, EditableZoneSettings, FabricTextBox } from '../../global-types';
import colorsList from '../../styles/util.scss';
import {
  getSizeAfterScaling,
  helperSettingsConfig,
  bleedInPixels,
  deepCopy,
  getOverlayImageSettings,
  getGroupedItemByName,
  getFontName,
} from '../../utils';
import { CanvasDataTypes } from '../../utils/canvas-utils/canvas-data-types';
import { PlaceHolderSettings, GetPlaceHolderParams } from './card-editor-types';
import { getDefaultPlaceholderText, getTextSettings, isSpecialCard } from './utils';
import { hasNoBleed } from './utils/utils';

const { mmInPixels, scalingFactor, bleedInMM } = helperSettingsConfig;
const spacingUnit = 20; // this is the old textPadding
const isMobileApp = isMobile;

const photozoneDefaultSettings = {
  originX: 'left',
  originY: 'top',
  fill: 'transparent',
  hoverCursor: 'pointer',
  strokeWidth: 0,
  selectable: false,
  evented: true,
  lockMovementX: true,
  lockMovementY: true,
  hasImg: false,
  hasRotatingPoint: false,
  lockRotatingPoint: true,
};

// defaults for editable texts
export const editableTextDefaultSettings = {
  name: CanvasDataTypes.EditableText,
  backgroundColor: 'rgba(237, 141, 56, 0.2)',
  editableText: true,
  isModified: false,
  lockMovementX: true,
  lockMovementY: true,
  hoverCursor: 'pointer',
  padding: 5,
  selectable: true,
  selectionColor: 'rgba(237, 141, 56, 0.2)',
  hasRotatingPoint: false,
  lockRotatingPoint: true,
};

const photoTextZoneDefaultSettings = {
  fill: 'transparent',
  stroke: colorsList['medium-gray'],
  strokeDashArray: [5],
  strokeWidth: 1,
  hasBorders: true,
  hasControls: false,
  hoverCursor: 'pointer',
  isModified: false,
  lockMovementX: true,
  lockMovementY: true,
  lockRotation: true,
  lockScalingFlip: true,
  lockSkewingX: true,
  padding: -2,
  selectable: true,
  paintFirst: 'stroke',
  evented: true,
  hasRotatingPoint: false,
};

const editableAreaDefaultSettings = {
  name: 'editableArea',
  stroke: 'rgba(41, 48, 53, 1)',
  strokeWidth: 4,
  strokeDashArray: [12, 18],
  strokeLineCap: 'round',
  fill: 'rgba(255, 255, 255, 0.20)',
  isModified: false,
  selectable: false,
  eventable: false,
  lockMovementX: true,
  lockMovementY: true,
  lockRotation: true,
  hoverCursor: 'default',
  hasRotatingPoint: false,
  lockRotatingPoint: false,
};

export function getEditableTextSettings(text: CardText, cardState: CardContextState) {
  const bleed = hasNoBleed(cardState) ? 0 : bleedInMM;

  return Object.assign(deepCopy(editableTextDefaultSettings), {
    width: text.Width / scalingFactor,
    height: text.Height / scalingFactor,
    left: (text.LeftPosition - bleed * mmInPixels) / scalingFactor,
    originY: 'center',
    top: (text.TopPosition - bleed * mmInPixels) / scalingFactor + text.Height / (scalingFactor * 2),
    angle: text.Rotation,
    fontFamily: getFontName(text.FontId),
    fontSize: text.FontSize * 2,
    text: text.Text,
    textAlign: text.TextAlign,
    fill: text.TextColor,
    isMultiline: text.IsMultiline,
    lockRotation: true,
    hasRotationControls: false,
    lockMovementX: text.IsFixed,
    lockMovementY: text.IsFixed,
    data: {
      type: CanvasDataTypes.EditableText,
      hasContent: false,
      isFixed: text.IsFixed,
      ID: text.ID,
      displayFontToolbox: text.DisplayFontToolbox,
    },
    CanDeleteTextArea: text.CanDeleteTextArea,
    CanResizeTextArea: text.CanResizeTextArea,
    CanEditFontSize: text.CanEditFontSize,
    FontAutoSize: text.FontAutoSize,
    CanEditFontColor: text.CanEditFontColor,
    backgroundColor: 'transparent',
    MustEditTextArea: text.MustEditTextArea,
  });
}

// this does not have any defaults, as the editable areas are different for every card
export function getEditableAreaSettings(area: CardEditableArea, index: number, cardState: CardContextState) {
  const bleed = hasNoBleed(cardState) ? 0 : bleedInMM;
  return Object.assign(deepCopy(editableAreaDefaultSettings), {
    name: `area-${index}`,
    width: area.Width / scalingFactor,
    height: area.Height / scalingFactor,
    left: (area.LeftPosition - bleed * mmInPixels) / scalingFactor,
    top: (area.TopPosition - bleed * mmInPixels) / scalingFactor,
    angle: area.Rotation,
    hasRotatingPoint: false,
    lockRotatingPoint: true,
    data: { type: CanvasDataTypes.EditableArea, removableOnSave: true },
  });
}

export function getPhotoZoneSettings(zone: EditableZoneSettings, index: number, cardState: CardContextState) {
  // if the card is a simple paper photo card, normal postcard
  // or wooden photo, we do not remove the bleed from the photozones
  // because the photozones' (0, 0) coordinates are in the corner of the canvas, that already excludes the bleed
  // if we remove it from the photozones as well, the placement would be translated towards the top left corner
  let bleedPx =
    cardState.cardType === 'photo' || cardState.cardType === 'woodenphoto' || cardState.isPostcard
      ? 0
      : bleedInPixels();

  // if the card is a chocophoto card, we need to ADD the bleed to the photozones
  // because we don't cut the bleed from the image, so we need to translate the photozones
  // toward the bottom right corner with the bleed value
  bleedPx *= cardState.isChocolate ? -1 : 1;

  return Object.assign(deepCopy(photozoneDefaultSettings), {
    name: `photozone-${index}`,
    left: Math.ceil(zone.LeftPosition / scalingFactor - bleedPx),
    top: Math.ceil(zone.TopPosition / scalingFactor - bleedPx),
    width: Math.ceil(zone.Width / scalingFactor),
    height: Math.ceil(zone.Height / scalingFactor),
    angle: zone.Rotation,
    zoneId: `p${index}`,
    hasRotatingPoint: false,
    lockRotatingPoint: true,
    data: {
      order: index,
      hasContent: false,
      type: CanvasDataTypes.PhotoZone,
      id: `photozone-${index}`,
    },
  });
}

export function addPhotozoneBackground(activeImage: fabric.Object, canvas: fabric.Canvas, cardState: CardContextState) {
  // adds back the grey background when a photozone image is deleted
  const activeImageIndex = activeImage.data.photoZoneId.slice(-1);
  const { activeCardIndex, cardFacesList } = cardState;
  const activeCardFace = cardFacesList[`${activeCardIndex}`] ?? {};
  const zoneSettings = getPhotoZoneSettings(activeCardFace.zones[`${activeImageIndex}`], activeImageIndex, cardState);
  const zone = new fabric.Rect(zoneSettings);
  zone.set('fill', '#838684');
  canvas.add(zone).sendToBack(zone);
}

export function getPhotoTextZoneSettings(text: CardText, id: number) {
  const scaledPosition = {
    left: text.LeftPosition / scalingFactor,
    top: text.TopPosition / scalingFactor,
  };

  const scaledTextDimensions = {
    width: text.Width / (scalingFactor * 2),
    height: text.Height / (scalingFactor * 2),
  };

  return Object.assign(deepCopy(photoTextZoneDefaultSettings), {
    name: `${CanvasDataTypes.PhotoTextZone}-${id}`,
    width: text.Width / scalingFactor,
    height: text.Height / scalingFactor,
    left: scaledPosition.left + scaledTextDimensions.width,
    originY: 'center',
    originX: 'center',
    top: scaledPosition.top + scaledTextDimensions.height,
    angle: text.Rotation,
    hasRotatingPoint: false,
    lockRotatingPoint: true,
    objectCaching: false,
    data: {
      hasContent: false,
      ID: text.ID,
      type: CanvasDataTypes.PhotoTextZone,
    },
    CanResizeTextArea: text.CanResizeTextArea,
    CanEditFontSize: text.CanEditFontSize,
    CanEditFontColor: text.CanEditFontColor,
    FontAutoSize: text.FontAutoSize,
    MustEditTextArea: text.MustEditTextArea,
  });
}

function getMobileFontSize(currentCanvas: fabric.Canvas): number {
  const canvasZoom = currentCanvas.getZoom();
  if (15 / canvasZoom > 32) {
    return 32;
  } else {
    return 15 / canvasZoom;
  }
}

/** This creates a button for a PhotoTextZone and returns a promise that resolves when the button is added to the photo zone button
 * */
export function addPhotoTextZoneButton(
  zone: fabric.Group & FabricTextBox,
  currentCanvas: fabric.Canvas,
  label?: string,
): Promise<void> {
  return new Promise((resolve) => {
    fabric.Image.fromURL(
      editIconSVG,
      (editIcon) => {
        editIcon.set({ originX: 'left', originY: 'center', left: 0, top: 0 });
        const buttonLabel = new fabric.Text(label || '', {
          textAlign: 'center',
          fontSize: !isMobileApp ? 15 : getMobileFontSize(currentCanvas),
          lineHeight: 25,
          fill: colorsList['dark-gray'],
          selectable: false,
          evented: false,
          originX: 'left',
          originY: 'center',
          top: 0,
          left: 0,
          fontFamily: getFontName(115), // this will get the BeamNewHMK-84 font
          objectCaching: true, // Necessary to avoid performance issues with OpenType.js
          name: CanvasDataTypes.PhotoTextZoneLabel,
          CanResizeTextArea: zone.CanResizeTextArea,
        } as FabricTextBox);
        // Set the width of the label to make sure it's always centered
        // some browsers (firefox) render text with a larger declared width, distorting the alignment
        if (!isMobileApp) {
          buttonLabel.width = 81;
        }

        const buttonGroup = new fabric.Group([editIcon, buttonLabel], {
          evented: false,
          name: CanvasDataTypes.PhotoTextZoneButton,
          hoverCursor: 'pointer',
          selectable: false,
          originX: 'center',
          originY: 'center',
          height: zone.height,
          left: zone.left,
          top: zone.top,
          angle: zone.angle,
          data: {
            linkedZoneName: zone.name,
          },
          objectCaching: false,
        });
        document.fonts.ready.then(() => {
          const originalText = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, zone) as fabric.Text;
          if (originalText.text && originalText.text.length > 0) {
            buttonGroup.visible = false;
            originalText.moveTo(0);
          } else {
            originalText.visible = false;
            buttonGroup.moveTo(0);
          }
          zone.addWithUpdate(buttonGroup);
          resolve();
        });
      },
      { width: 48, height: 48 },
    );
  });
}

/** This creates a button for a PhotoZone and returns a promise with the fabric.Group
 *  object that contains the camera icon for the zone once the image is loaded.
 * */
export function getPhotoZoneButton(
  zone: fabric.Object,
  currentCanvas: fabric.Canvas,
  isUK: boolean | undefined,
): Promise<fabric.Group> {
  return new Promise((resolve) => {
    fabric.Image.fromURL(
      isUK ? addPhotoIconUK : addPhotoIcon,
      (image) => {
        // as of fabric 2 setting the width/height crops the image
        // to load the image at the desired dimensions the new functions
        // scaleToWidth/Height should be used;
        // by calling both we are 100% sure that the proportions are kept
        if (image.width && image.height) {
          image.scaleToWidth(getSizeAfterScaling(image.width, currentCanvas));
          image.scaleToHeight(getSizeAfterScaling(image.height, currentCanvas));
        }
        image.setOptions({
          selectable: false,
          originX: 'top',
          originY: 'center',
          top: -15,
          strokeWidth: 2,
        });

        const btnGroup = new fabric.Group([image], {
          selectable: false,
          name: `${zone.name}-button`,
          hoverCursor: 'pointer',
          width: zone.width,
          height: zone.height,
          left: zone.left,
          top: zone.top,
          angle: zone.angle,

          data: {
            photoZoneId: zone.name,
            type: CanvasDataTypes.PhotoZoneButton,
          },
        });

        document.fonts.ready.then(() => {
          resolve(btnGroup);
        });
      },
      { width: 48, height: 48 },
    );
  });
}

/**
 *  Loads the PhotoFrameImage from the card face and returns promise that
 *  resolves with a fabric.Image
 */
export function getPhotoFrameImage(face: CardFaceData, currentState: CardContextState): Promise<fabric.Image> {
  return new Promise((resolve) => {
    const photoFrameUrl = face.photoZoneTemplate?.replaceAll('\\', '/') as string;

    fabric.Image.fromURL(
      photoFrameUrl,
      (image) => {
        image.set(getOverlayImageSettings(currentState));
        image.scaleToWidth(face.dimensions.width);
        resolve(image);
      },
      {
        crossOrigin: 'anonymous',
        name: CanvasDataTypes.PhotoFrameName,
      },
    );
  });
}

export function getPlaceholderPortraitSettings({
  cardState,
  currentCanvas,
  defaultFontSize,
  placement,
  isUK,
  color,
  defaultOptions,
}: GetPlaceHolderParams<'left' | 'right'>): PlaceHolderSettings {
  const settings = getTextSettings({
    cardState,
    defaultFontSize,
    color,
    isUK,
    name: CanvasDataTypes.Placeholder,
    defaultOptions,
  });
  const canvasWidth = currentCanvas.getWidth();
  const canvasZoom = currentCanvas.getZoom();
  const placeholderWidth =
    cardState.isWooden || cardState.isChocolate
      ? canvasWidth - 8 * spacingUnit
      : canvasWidth / (2 * canvasZoom) - 2 * settings.left;
  Object.assign(settings, {
    top: isSpecialCard(cardState) ? 3 * spacingUnit : 4 * spacingUnit,
    width: placeholderWidth,
  });

  if (placement === 'right') {
    Object.assign(settings, {
      left: canvasWidth / (2 * canvasZoom) + settings.left,
    });
  }

  return settings;
}

export function getPlaceholderLandscapeSettings({
  cardState,
  currentCanvas,
  defaultFontSize,
  placement,
  isUK,
  color,
  defaultOptions,
}: GetPlaceHolderParams<'top' | 'bottom'>): PlaceHolderSettings {
  const canvasWidth = currentCanvas.getWidth();
  const canvasHeight = currentCanvas.getHeight();
  const canvasZoom = currentCanvas.getZoom();
  const settings = getTextSettings({
    cardState,
    defaultFontSize,
    color,
    isUK,
    name: CanvasDataTypes.Placeholder,
    defaultOptions,
  });
  settings.width = canvasWidth / canvasZoom - 2 * settings.left;

  if (placement === 'top') {
    settings.top = isSpecialCard(cardState) ? 3 * spacingUnit : 4 * spacingUnit;
  } else {
    settings.top = canvasHeight / (2 * canvasZoom) + 4 * spacingUnit;
  }
  return settings;
}

export function addPlaceholder(settings: PlaceHolderSettings, currentCanvas: fabric.Canvas, defaultText: string) {
  const PLACEHOLDER_TEXT = getDefaultPlaceholderText(settings, defaultText);
  const placeholder = new fabric.Textbox(PLACEHOLDER_TEXT, settings);

  placeholder.on(
    'editing:entered',
    function (this: fabric.Textbox & { _textBeforeEdit: string; __lastIsEditing: boolean }) {
      const currentFill = currentCanvas.getActiveObject()?.fill;
      const currentFillMatch = Object.keys(colorsList).find(
        (key) => colorsList[`${key}`].toUpperCase() === currentFill?.toString().toUpperCase(),
      );
      const colorName = currentFillMatch || BrandColors.Black;
      // Checks if the text has been edited, if not deleted the default text
      if (this._textBeforeEdit === PLACEHOLDER_TEXT && !this.__lastIsEditing) {
        this.set({ text: '', fill: colorsList[`${colorName}`], data: { ...this.data, edited: true } });
        if (this.hiddenTextarea) {
          this.hiddenTextarea.value = '';
        }
      }
    },
  );
  placeholder.on(
    'editing:exited',
    function (this: fabric.Textbox & { _textBeforeEdit: string; __lastIsEditing: boolean }) {
      if (this.text === '') {
        this.set({
          text: PLACEHOLDER_TEXT,
          data: { ...this.data, edited: false },
        });
      }
    },
  );
  placeholder.on(
    'mousedblclick',
    function (this: fabric.Textbox & { _textBeforeEdit: string; __lastIsEditing: boolean }) {
      if (this._textBeforeEdit !== PLACEHOLDER_TEXT || this.__lastIsEditing) {
        this.selectAll();
      }
    },
  );

  currentCanvas.add(placeholder);
}

/**
 * Find horizontal center point in a zone
 *
 * @param zone - zone in which to find a center point
 * @returns number
 */
export const getHorizontalCenterPoint = (zone: fabric.Object): number => {
  // Need to check for undefined since left could be 0
  if (zone.width !== undefined && zone.left !== undefined) {
    return zone.left + zone.width / 2;
  }
  return 0;
};

/**
 * Find vertical center point in a zone
 *
 * @param zone - zone in which to find a center point
 * @returns number
 */
export const getVerticalCenterPoint = (zone: fabric.Object): number => {
  // Need to check against undefined since top could be 0
  if (zone.height !== undefined && zone.top !== undefined) {
    return zone.top + zone.height / 2;
  }
  return 0;
};
