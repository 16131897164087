import { CardFaceData, TemplateData } from '../../global-types';
import { validateTransformedData } from './validate-transformed-data';

/**
 * Validate that the card faces are not empty and have the same amount of objects as the template
 * @param cardFacesList
 * @param templateData
 * @throws Error if the current json has less objects than the template json
 */

export const validateCardFacesAreNotEmpty = (cardFacesList: CardFaceData[], templateData: TemplateData): void => {
  cardFacesList.forEach((face) => {
    if (!face.originalCanvasJson) {
      const templateCardFace = templateData?.Faces.find(
        (currentTemplateCardFace) => currentTemplateCardFace.FaceId === face.faceId,
      );
      if (templateCardFace && validateTransformedData(face, templateCardFace)) {
        throw new Error('The current json has less objects than the template json');
      }
    }
  });
};
