import { TrackEventFunction } from '../analytics-context-types';

export const trackEvent = ({
  tagType,
  trackType,
  options,
  params: { dataLayerAvailable, buildTag, debug, track },
  keysToDelete = ['messages', 'modal.messages'],
}: TrackEventFunction) => {
  if (dataLayerAvailable) {
    const tag = buildTag(tagType, options, keysToDelete);
    // eslint-disable-next-line no-console
    debug && console.log(`tagType: ${tagType}`, tag);
    track(trackType);
  }
};
