import { MappedFileWithAsset } from '../../global-types/images';
import { getAssetUrls } from '../../services';

/**
 *  Generate signed S3 urls for each file format and return the files with the assetUrls
 * @param files
 * @returns array of files with their related asset urls { file: File, format: string, assetUrl: string}
 */
export const getAndMapAssetUrls = async (files: File[]): Promise<MappedFileWithAsset[]> => {
  // Create mapped array of files with format and assetUrl properties
  const mappedFiles: MappedFileWithAsset[] = files.map((file) => {
    // get file's type and assign to new property 'format'
    const format = file.type.split('/')[1];
    return {
      file: file,
      format: format,
      assetUrl: '',
    };
  });
  // Create an array of unique image formats to generate S3 urls for each format
  // Note: this way of cleaning duplicates is not efficient for larger arrays, but it's fine for this case
  const formats = [...mappedFiles.map((file) => file.format)].filter((format, index, self) => {
    return self.indexOf(format) === index;
  });

  // Call getS3Urls for each format and map the results for each file
  const filesWithAssetUrls = await Promise.all(
    formats.map(async (format) => {
      const formatFiles = mappedFiles.filter((file) => file.format === format);
      let urls;
      try {
        const response = await getAssetUrls(formatFiles.length, format);
        urls = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to generate asset URLs for format ${format}:`, error);
        urls = [];
      }
      return formatFiles.map((file, index) => {
        return { ...file, assetUrl: urls?.[`${index}`] || '' };
      });
    }),
  );

  // Flatten the array of arrays from filesWithAssetUrls
  const mappedFilesWithUrls: MappedFileWithAsset[] = ([] as unknown as MappedFileWithAsset[]).concat(
    ...filesWithAssetUrls,
  );
  // Only return files with an associated assetUrl
  return mappedFilesWithUrls.filter((file) => !!file.assetUrl);
};

// TODO: create function to handle the upload of multiple images to s3 asset storage
