export type OnGoingEventsContextState = {
  onGoingEvents: string[];
};

export enum actionTypes {
  SET_INIT_ON_GOING_EVENTS = 'SET_INIT_ON_GOING_EVENTS',
  ADD_ON_GOING_EVENT = 'ADD_ON_GOING_EVENT',
  RESET_ON_GOING_EVENTS = 'RESET_ON_GOING_EVENTS',
}

export type SetInitializationOnGoingEvents = {
  type: actionTypes.SET_INIT_ON_GOING_EVENTS;
  payload: [];
};

export type AddOnGoingEvent = {
  type: actionTypes.ADD_ON_GOING_EVENT;
  payload: string;
};

export type ResetOnGoingEvents = {
  type: actionTypes.RESET_ON_GOING_EVENTS;
};

export type DataActions = SetInitializationOnGoingEvents | AddOnGoingEvent | ResetOnGoingEvents;

export type Dispatch = (action: DataActions) => void;

export type CreateContextProps =
  | { onGoingEventsState: OnGoingEventsContextState; onGoingEventsDispatch: Dispatch }
  | undefined;
