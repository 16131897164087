import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { FooterContentProps } from '../order-drawer-types';
import styles from '../order-drawer.module.scss';

export const FooterContent = ({ onSubmit, onClose }: FooterContentProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-wrapper']}>
      <Button
        testId="rotate-drawer--cancel"
        addClass={styles['footer-cancel-button']}
        click={onClose}
        mode={ButtonModes.Secondary}
      >
        {t('footerContent.cancel')}
      </Button>
      <Button testId="rotate-drawer--apply" addClass={styles['footer-primary-button']} click={onSubmit}>
        {t('footerContent.apply')}
      </Button>
    </div>
  );
};
