import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { Address, AddressForm, ErrorType } from '../../../global-types';
import { useSystemErrorHandling } from '../../../hooks/useSystemErrorHandling';
import { getIsDigitalGreeting, helperSettingsConfig } from '../../../utils';
import { SubmittedAddresses } from '../address-types';
import { handleSaveAddress } from '../utils/handle-save-address';
import { useEnvelopeAddresses } from './useEnvelopeAddresses';

interface SubmitAddressesProps {
  shouldSave: boolean;
  submittedAddresses: SubmittedAddresses;
  setValidAddresses: React.Dispatch<React.SetStateAction<boolean>>;
  saveContact: (
    address: Omit<AddressForm, 'isQuickAddress'>,
    shouldSkipNextContact: boolean,
    index: number,
  ) => Promise<void> | undefined;
  setShouldSave: React.Dispatch<React.SetStateAction<boolean>>;
  addAddressToValidate: (address: Address, errorType: ErrorType, step: number, contactId: any) => void;
  openAddressConfirmationDialog: () => void;
}

export const useSubmitAddresses = ({
  shouldSave,
  submittedAddresses,
  setValidAddresses,
  saveContact,
  setShouldSave,
  addAddressToValidate,
  openAddressConfirmationDialog,
}: SubmitAddressesProps) => {
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  // Contexts
  const {
    initializedDataState: { data: initializedData, addressData, savedContacts },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const { appDispatch } = useAppContext();
  // Error handler to show dialog when error is catch
  const { onSystemError } = useSystemErrorHandling();
  const isDigitalGreeting = getIsDigitalGreeting();
  const addressTypesToFill = useEnvelopeAddresses();

  useEffect(() => {
    const projectId = initializedData?.project_id;
    if (!shouldSave || !projectId) {
      return;
    }

    if (shouldSave && isDigitalGreeting) {
      return;
    }

    const addressesToSave = addressTypesToFill.map(
      (addressType) => submittedAddresses[`${addressType}`],
    ) as AddressForm[];

    let remainingContactsToSave = addressesToSave.reduce(
      (count, address) => (address.isQuickAddress ? count + 1 : count),
      0,
    );
    const allowedContactsToSave = helperSettingsConfig.limitSaveQuickAddresses - savedContacts.length;
    const savingAddresses = addressesToSave.map(async (address, index, addresses) => {
      const { isQuickAddress, ...addressToSave } = address as AddressForm;
      const shouldSkipNextContact =
        isQuickAddress && allowedContactsToSave > 0 && allowedContactsToSave - remainingContactsToSave < 0;
      if (shouldSkipNextContact) {
        addresses[index + 1].isQuickAddress = false;
        remainingContactsToSave--;
      }
      await handleSaveAddress({
        projectId,
        data: addressToSave,
        step: index,
        appDispatch,
        initializationDataDispatch,
        setIsAddressLoading,
        addressData,
        addAddressToValidate,
        openAddressConfirmationDialog,
        onSystemError,
      });
      if (isQuickAddress) {
        saveContact(addressToSave, shouldSkipNextContact, index);
      }
    });

    Promise.all(savingAddresses)
      .then(() => {
        setValidAddresses(true);
      })
      .catch(() => {
        setValidAddresses(false);
      })
      .finally(() => {
        setShouldSave(false);
      });
  }, [shouldSave, initializedData?.project_id, submittedAddresses, saveContact]);

  return { isAddressLoading, setIsAddressLoading };
};
