import React from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useMultiAddresses } from '../../../hooks/use-multi-addresses';
import { AddressSelectionIndicator } from '../../address-selection-indicator/address-selection-indicator';
import { EnvelopeFormHeaderProps } from '../address-form-types';
import styles from '../address-form.module.scss';
import { EnvelopeRadioGroup } from './envelope-radio-group';

export const Header = ({ register }: EnvelopeFormHeaderProps) => {
  const { t } = useTranslation();
  const { shouldUseMultiAddresses, shouldUseEnvelopePrintingOptions } = useMultiAddresses();

  return (
    <>
      <header className={styles.header}>
        <Typography
          variant={TypographyVariants.Subtitle}
          htmlTagOverride={HtmlTagOverrides.H2}
          addClass={styles.title}
          data-testid={'address-form-title'}
        >
          {t('addressing.title')}
        </Typography>
        {shouldUseMultiAddresses && <AddressSelectionIndicator />}
      </header>
      {!shouldUseEnvelopePrintingOptions && shouldUseMultiAddresses && <EnvelopeRadioGroup register={register} />}
    </>
  );
};
