import { isLoggedIn } from '..';
import { leaveAction } from '../../context/analytics-context/analytics-context-types';

export const triggerAbandonOrDeleteProjectEvent = () => {
  if (isLoggedIn()) {
    return leaveAction.DELETE;
  } else {
    return leaveAction.ABANDON;
  }
};
