import { useCallback } from 'react';
import { useAnalyticsContext } from '../../../../context/analytics-context/analytics-context';
import { useAppContext } from '../../../../context/app-context';
import { setAllDrawersClosed } from '../../../../context/app-context/app-context-actions';
import { discardTextObjectAndRender } from '../../../../utils';
import { resetZoom } from '../../../card-face/utils/zoom-mobile-text';

export const useCloseFontDrawer = () => {
  const { trackTextEdits } = useAnalyticsContext();
  const { appDispatch } = useAppContext();

  const onFontDrawerSubmit = useCallback(
    (canvas) => {
      const activeObject = canvas?.current?.getActiveObject();
      if (activeObject) {
        trackTextEdits(activeObject);
      }
      onFontDrawerCancel(canvas);
    },
    [appDispatch],
  );

  const onFontDrawerCancel = useCallback(
    (canvas) => {
      if (canvas?.current) {
        resetZoom(canvas.current);
        discardTextObjectAndRender(canvas.current);
      }
      setAllDrawersClosed(appDispatch);
    },
    [appDispatch],
  );

  return {
    onFontDrawerSubmit,
    onFontDrawerCancel,
  };
};
