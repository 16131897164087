import { useState } from 'react';
import { useAppContext, setIsSaving } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useFeatureFlags, useSaveProject } from '../../../hooks';
import { getRegionalConfig } from '../../../regional-config';

export const useAutoSaveProject = () => {
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const { appDispatch } = useAppContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { IS_AUTOSAVE_PROJECTS_ENABLED } = useFeatureFlags();
  const { saveProject } = useSaveProject();
  const config = getRegionalConfig();

  const autoSaveProject = async () => {
    if (config.saveProjects.isSavedProjectsEnabled && IS_AUTOSAVE_PROJECTS_ENABLED) {
      setIsSaving(appDispatch, true);
      // Generate previews if we don't have any
      if (initializedData?.project_type_code !== 'S' && !previewGenerated) {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
        });
        setPreviewGenerated(true);
      } else {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
        });
      }
      setIsSaving(appDispatch, false);
    }
  };

  return { autoSaveProject };
};
