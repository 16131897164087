import { ProjectAsset } from '../../global-types';
import { AssetTypes } from '../../global-types/initialization-data';

/**
 * Sets the asset URL based on the provided assets and fallback URL.
 *
 * @param {ProjectAsset[] | undefined} assets - The list of project assets.
 * @param {string | undefined} fallbackUrl - The fallback URL to use if no asset URL is found.
 * @returns {string} - The resolved URL, which could be the thumbnail URL, asset URL, or fallback URL.
 */
export const setAssetUrl = (assets: ProjectAsset[] | undefined, fallbackUrl: string | undefined) => {
  const asset =
    assets?.find((asset) => asset.asset_type_code === AssetTypes.FRONT_PANEL_PREVIEW) ||
    assets?.find((asset) => asset.asset_type_code === AssetTypes.FRONT_PANEL);
  const assetUrl = asset?.url;
  const thumbnailUrl = asset?.resize_urls?.S;
  const url = thumbnailUrl || assetUrl || fallbackUrl || '';

  return url;
};
