import { useEffect, useState } from 'react';
import { DataLayerObject } from '@hallmark/web.page-components.datalayer';
import { useInitializationDataContext } from '../data-context';
import {
  clearDataLayerEvents,
  clearDataLayerModalEvents,
  clearDataLayerModalLevels,
} from './analytics-context-helpers';
import { getTag } from './analytics-context-tags';
import { AnalyticsTag, TagOptions } from './analytics-context-types';

export const useAnalytics = () => {
  const [dataLayerAvailable, setDataLayerAvailable] = useState(false);

  const { initializedDataState } = useInitializationDataContext();
  const { membershipStatus } = initializedDataState;

  useEffect(() => {
    const dataLayerCheckTimer = setInterval(() => {
      if (typeof window !== 'undefined' && window.dataLayer?.['siteID'] !== 'undefined') {
        setDataLayerAvailable(true);
      }
    }, 500);
    return () => {
      clearInterval(dataLayerCheckTimer);
    };
  }, []);

  const buildTag = (tagType: string, options?: TagOptions, keysToDelete?: string[]) => {
    const tag: AnalyticsTag = getTag(tagType, options || ({} as TagOptions));

    // Add Hallmark membership status to user tag for digital cards
    if (options?.productType === 'D' && tag.user) {
      // leave out customerId for analytics tags
      // Disabled linter rule because customerId is unused; we only need the rest of the values from membershipStatus.
      const { customerId, ...membershipInfo } = membershipStatus; // eslint-disable-line @typescript-eslint/no-unused-vars

      tag.user.profile = { ...tag.user.profile, ...membershipInfo };
    }

    clearDataLayerEvents();
    clearDataLayerModalEvents();
    clearDataLayerModalLevels();
    DataLayerObject({ dataObj: JSON.stringify(tag), keysToDelete: keysToDelete || [] });
    return tag;
  };

  const track = (trackType = 'page') => {
    if (window && window._satellite && typeof window._satellite.track === 'function') {
      window._satellite.track(trackType);
    }
  };

  return {
    dataLayerAvailable,
    buildTag,
    track,
  };
};
