import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { AddressForm } from '../../global-types/addresses';

/**
 * Function that handles zip code change.
 * @param {boolean|undefined} isHandleZipChange - Indicates whether to handle the zip code change.
 * @param {function} trigger - Form trigger function to validate the zip code field.
 * @param {string} zip - The zip code to handle.
 * @param {function} setValue - Function to set the value of the zip code field in the form.
 * @returns {void}
 */
export const handleZipChange = (
  isHandleZipChange: boolean | undefined,
  trigger: UseFormTrigger<AddressForm>,
  zip: string,
  setValue: UseFormSetValue<AddressForm>,
) => {
  if (isHandleZipChange) {
    let newZip = zip.replace(/ /g, '');
    if (newZip.length > 4) {
      const firstHalf = newZip.substring(0, newZip.length - 3);
      const lastHalf = newZip.slice(newZip.length - 3);
      newZip = `${firstHalf} ${lastHalf}`;
      setValue('zip', newZip.toUpperCase());
    } else {
      setValue('zip', newZip.toUpperCase());
    }
    setTimeout(() => {
      trigger('zip');
    }, 250);
  }
};
