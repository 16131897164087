import { CardFaceData } from '../../global-types';

/**
 * Clean spellcheck suggestions from all text objects in the canvas after.
 * This can be used after spellcheck validation is ignored
 * @param cardFacesList
 */

export const cleanSuggestionsFromCardFaces = (cardFacesList: CardFaceData[]): void => {
  cardFacesList.forEach((face) => {
    const canvas = face.canvas?.current;
    canvas?.getObjects().forEach((object) => {
      if (object.type === 'textbox') {
        object.set({ data: { ...object.data, suggestions: null } });
      }
    });
  });
};
