import { CustomFabricImage } from '../../global-types';
import { helperSettingsConfig } from '../configs/helper-settings.configs';

/**
 * Calculates the PPI (Pixels Per Inch) of an image.
 * @param {CustomFabricImage} image - The object representing the image.
 * @returns {number | null} - The calculated PPI of the image. Returns null if the PPI can't be calculated.
 */
export const calculateImagePpi = (image: CustomFabricImage): number | null => {
  const { printerDpi } = helperSettingsConfig;

  // Calculate the size in inches
  const widthInInches = image.getScaledWidth() / printerDpi;
  const heightInInches = image.getScaledHeight() / printerDpi;

  const imageDiagonalInInches =
    widthInInches && heightInInches ? Math.sqrt(Math.pow(widthInInches, 2) + Math.pow(heightInInches, 2)) : null;

  const imageDiagonalOriginalSize =
    image.width && image.height ? Math.sqrt(Math.pow(image.width, 2) + Math.pow(image.height, 2)) : null;

  // Calculate the PPI
  const imagePpi =
    imageDiagonalOriginalSize && imageDiagonalInInches ? imageDiagonalOriginalSize / imageDiagonalInInches : null;

  return imagePpi;
};
