import { CardFace, CardFaceData } from '../../global-types';
import { getPermanentObjects } from './get-permanent-objects';
import { getSumCardFaceObjects } from './get-sum-card-face-objects';

/**
 * Validates the transformed data by comparing the number of permanent objects in the transformed data with the number of template objects.
 * @param cardFace - the transformed data
 * @param templateCardFace - the template card face
 * @returns true if the number of permanent objects in the transformed data is less than the template objects number, false otherwise
 */

export const validateTransformedData = (cardFace: CardFaceData, templateCardFace: CardFace): boolean => {
  let printJsonHasErrors = false;
  const templateObjectsNumber = getSumCardFaceObjects(templateCardFace);
  const permanentObjects = getPermanentObjects(cardFace.canvas.current?.getObjects() || []);
  if (permanentObjects.length < templateObjectsNumber) {
    printJsonHasErrors = true;
  }
  return printJsonHasErrors;
};
