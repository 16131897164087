export type BadRequestResponse = {
  meta: {
    code: 400;
    version: string;
  };
  errors: {
    code: string;
    description: string;
  }[];
};

export type UnauthorizedResponse = {
  meta: {
    code: 401;
    version: string;
  };
  errors: {
    code: string;
    description: string;
  }[];
};

export type UnprocessableEntityResponse = {
  meta: {
    code: 422;
    version: string;
  };
  errors: {
    code: string;
    description: string;
    field: string;
  }[];
};

export type InternalServerErrorResponse = {
  meta: {
    code: 500;
    version: string;
  };
  errors: {
    code: string;
    description: string;
  }[];
};

export type NotFoundResponse = {
  meta: {
    code: 404;
    version: string;
  };
  errors: {
    code: string;
    description: string;
  }[];
};

export enum CrownRewardsErrorCodes {
  NonCrMember = 'err_non_cr_member',
  CrMemberLimitReached = 'err_cr_member_limit_reached',
  NonHplusSubscriber = 'err_non_h-plus_subscriber',
}
