import { fabric } from 'fabric';
import { CanvasDataTypes } from '..';

/**
 * Get only permanent objects from an array of objects
 * @param objects Array of fabric objects
 * @returns Array of permanent objects
 */

export const getPermanentObjects = (objects: fabric.Object[]): fabric.Object[] =>
  objects.filter((object) => {
    const { data, clipPath } = object;
    return (
      !data ||
      !data.type ||
      (clipPath && data.type === CanvasDataTypes.UserImage) ||
      (clipPath && data.type === CanvasDataTypes.UserText) ||
      (data.type !== CanvasDataTypes.PhotoZoneImage &&
        data.type !== CanvasDataTypes.UserText &&
        data.type !== CanvasDataTypes.UserImage)
    );
  });
