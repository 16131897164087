import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { useFeatureFlags } from '../../../../hooks';
import { getIsDigitalGreeting } from '../../../../utils';
import { AddressFooterProps } from '../../address-types';
import styles from './address-footer-styles.module.scss';

export const AddressFooter = ({
  isValid,
  submitLabel,
  goBack,
  isAddressLoading,
  addClass,
  handleSubmit,
}: AddressFooterProps): React.ReactElement => {
  const { t } = useTranslation();
  const { IS_MULT_ADDRESS_ENABLED } = useFeatureFlags();
  const isDigitalGreeting = getIsDigitalGreeting();
  const shouldDisplayFooter = !IS_MULT_ADDRESS_ENABLED || isDigitalGreeting;

  return shouldDisplayFooter ? (
    <div className={styles['address-footer']}>
      <Button
        startIcon={{ name: IconNames.ArrowsTailLeftBold }}
        mode={ButtonModes.TextLink}
        click={goBack}
        addClass={classNames(addClass, styles['footer-button'])}
        testId={'address-form-back-button'}
      >
        {t('addressForm.back')}
      </Button>
      <Button
        type={ButtonTypes.Submit}
        mode={ButtonModes.Primary}
        click={handleSubmit}
        addClass={classNames(addClass, styles['footer-button'])}
        disabled={!isValid || isAddressLoading}
        tabIndex={isValid ? 0 : -1}
        testId={'address-form-continue-submit'}
      >
        {submitLabel}
      </Button>
    </div>
  ) : (
    <></>
  );
};
