import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../../hooks';
import { HeaderContentProps } from '../scale-drawer-types';
import styles from '../scale-drawer.module.scss';

export const HeaderContent = ({ onChangeCallback }: HeaderContentProps): React.ReactElement => {
  const { register } = useForm();
  const [scaleValue, setScaleValue] = useState(100);
  const [originalScaleValue, setOriginalScaleValue] = useState<number>(1);
  const canvas = useActiveCanvas();

  const rangeName = 'ScaleRange';

  const { t } = useTranslation();

  const onChange = (valueInRange: number) => {
    const scaleFactor = valueInRange / 100;
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject?.type == 'image') {
      activeObject.set({
        scaleX: originalScaleValue * scaleFactor,
        scaleY: originalScaleValue * scaleFactor,
      });
      canvas?.current?.renderAll();
      setScaleValue(valueInRange);
      if (onChangeCallback) {
        onChangeCallback(valueInRange);
      }
    }
  };

  useEffect(() => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject && activeObject?.type === 'image') {
      const scaleVal = activeObject.scaleX as number;
      setOriginalScaleValue(scaleVal);
    }
  }, []);

  return (
    <div className={styles['header-wrapper']}>
      <div className={styles['scale-label']}>
        <Typography variant={TypographyVariants.FieldLabel}>{t('scaleDrawer.out')}</Typography>
        <Typography variant={TypographyVariants.InputText} addClass={styles['value']}>{`${scaleValue}`}</Typography>
        <Typography variant={TypographyVariants.FieldLabel}>{t('scaleDrawer.in')}</Typography>
      </div>
      <Range
        domId={'scale'}
        label={'Scale'}
        min={0}
        max={200}
        step={1}
        tickCount={0}
        onChange={onChange}
        register={register(rangeName)}
      />
    </div>
  );
};
