import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../../context/analytics-context';
import { setIsImageEditDrawerOpen, setIsToasterOpen, useAppContext } from '../../../../context/app-context';
import { setSelectedPhotoZone, useCardContext } from '../../../../context/card-context';
import { useActiveCanvas } from '../../../../hooks';
import { useDatadog } from '../../../../hooks/useDatadog';
import { CanvasDataTypes, getCardFaceClipPath, getObjectsByType } from '../../../../utils';
import { addImageToCanvas, fillPhotoZone } from '../../../../utils/canvas-utils';
import { removeEditableAreaButtons } from '../../../card-editor/utils';

export const useImageUploadHandlers = () => {
  const { appDispatch } = useAppContext();
  const datadog = useDatadog();
  const { t } = useTranslation();
  const { firstElementSelected, updateEditFormats, isReplacingImage } = useAnalyticsContext();
  const { cardState, cardDispatch } = useCardContext();
  const { selectedPhotoZone, cardFacesList, activeCardIndex } = cardState;
  const canvas = useActiveCanvas();
  const currentCardFace = cardFacesList[`${activeCardIndex}`];
  const isFrontFace = currentCardFace?.type === 'front';

  const getNextCoverPhotoZone = () => {
    if (!canvas?.current) {
      return;
    }
    const photoZones = getObjectsByType(canvas.current as Canvas, CanvasDataTypes.PhotoZone);
    const slots = photoZones.filter((zone) => !zone.data?.hasContent);
    let nextSlot = slots[0];
    slots.forEach((slot) => {
      if (slot.data.order < nextSlot.data.order) {
        nextSlot = slot;
      }
    });

    return nextSlot;
  };

  const handleIconAdded = useCallback(
    (icon: fabric.Image) => {
      icon.on('mousedown', () => {
        setIsToasterOpen(appDispatch, {
          title: t('lowResolutionImage.title'),
          children: t('lowResolutionImage.message'),
          variant: ToastVariants.Warning,
        });
      });
    },
    [appDispatch, t],
  );

  const onImageUpload = useCallback(
    (image: fabric.Image) => {
      // todo: discuss with the team if this is really needed or used anywhere
      image.onSelect = () => {
        onImageSelect();
        return false;
      };
      if (image?.width === 0 && image?.height === 0) {
        throw new Error('The height and width of the image being attempted to upload is 0 (zero).');
      }

      const photo = {
        refId: image.data.refId,
        id: image.data.imageId,
        url: image.data.url,
        size: {
          width: image.width,
          height: image.height,
        },
      };

      if (isFrontFace) {
        datadog.addAction('add-photo-to-user-zone', {
          image: photo,
          zoneId: selectedPhotoZone.id,
          cardFace: currentCardFace?.type ?? null,
        });
        if (selectedPhotoZone.id) {
          fillPhotoZone(selectedPhotoZone.id, image, cardFacesList, handleIconAdded);
        } else {
          const nextCoverSlot = getNextCoverPhotoZone();
          if (nextCoverSlot) {
            fillPhotoZone(nextCoverSlot.data.id, image, cardFacesList, handleIconAdded);
          }
        }
      } else {
        datadog.addAction('add-photo-to-card-face', {
          image: photo,
          zoneId: selectedPhotoZone.id,
          cardFace: currentCardFace?.type ?? null,
        });
        // adds the image as usual.
        const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
        if (cardFaceClipPath) {
          //  Center image in cardFaceClipPath
          // We use '100' because these images are scaled to 200 width/height by addImageToCanvas
          image.set({
            top: cardFaceClipPath.top + cardFaceClipPath.height / 2 - 100,
            left: cardFaceClipPath.left + cardFaceClipPath.width / 2 - 100,
            clipPath: cardFaceClipPath,
          });
        }
        addImageToCanvas(image, canvas?.current as fabric.Canvas, handleIconAdded);
        removeEditableAreaButtons(canvas?.current as fabric.Canvas);
      }
      setSelectedPhotoZone(cardDispatch, null, null);
      //using setTimeout to avoid issues with the upload drawer closing.
      setTimeout(() => setIsImageEditDrawerOpen(appDispatch), 0);
    },
    [selectedPhotoZone, cardFacesList, cardDispatch, isFrontFace, currentCardFace, canvas, setIsImageEditDrawerOpen],
  );

  const onImageSelect = useCallback(() => {
    setIsImageEditDrawerOpen(appDispatch);
    !isReplacingImage.current && updateEditFormats({ eventAction: !firstElementSelected.current ? 'start' : 'add' });
  }, [appDispatch, firstElementSelected, isReplacingImage, updateEditFormats]);

  return { onImageUpload, onImageSelect };
};
