import React, { useEffect } from 'react';
import { EnvelopePreview } from '../../components/envelope-preview/envelope-preview';
import { Layout } from '../../components/layout';
import { SelectEnvelopeCard } from './components/select-envelope-card';
import { useEnvelopeSelection } from './hooks/use-envelope-selection';
import styles from './select-envelope.module.scss';

export const SelectEnvelopeView = () => {
  const {
    envelopeForm: { register, setValue },
    selectedEnvelope,
    handleBack,
    handleContinue,
  } = useEnvelopeSelection();

  useEffect(() => {
    if (selectedEnvelope) {
      setValue('envelopeOption', selectedEnvelope);
    }
  }, []);

  return (
    <Layout hideToolbar>
      <main className={styles['envelope-container']} data-testid={'envelope-container'}>
        <EnvelopePreview selectedEnvelope={selectedEnvelope} />
        <SelectEnvelopeCard register={register} handleBack={handleBack} handleContinue={handleContinue} />
      </main>
    </Layout>
  );
};
